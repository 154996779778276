import React, { Fragment } from 'react';
import { func, bool, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000 } from 'shared/constants/delays';
import { setNeedsTransport, setTransport } from 'actions/booking/chat-actions';
import { chatAvailableTransportsSelector } from 'selectors/booking/chat-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.scss';

const JobTransportSelectionStepInput = ({
  setDoesNeedTransport,
  setDoesntNeedTransport,
  setSelectedTransport,
  isAnimationFinished,
  question,
}) => (
  <Fragment>
    {question === 'needsTransport?' && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <Animation delay={DELAY_1000}>
          <div className={styles.container}>
            <Button
              isSecondary
              caption="No"
              className={styles.no}
              onClick={setDoesntNeedTransport}
            />
            <Button
              caption="Yes"
              className={styles.yes}
              onClick={() => {
                setDoesNeedTransport();
                setSelectedTransport('loaner');
              }}
            />
          </div>
        </Animation>
      </AnimationGroup>
    )}
  </Fragment>
);

JobTransportSelectionStepInput.propTypes = {
  setSelectedTransport: func.isRequired,
  setDoesntNeedTransport: func.isRequired,
  setDoesNeedTransport: func.isRequired,
  isAnimationFinished: bool,
  question: string,
};

JobTransportSelectionStepInput.defaultProps = {
  isAnimationFinished: false,
  question: 'needsTransport?',
};

const mapStateToProps = state => ({
  availableTransports: chatAvailableTransportsSelector(state),
});

const actions = {
  setDoesNeedTransport: () => setNeedsTransport(true),
  setDoesntNeedTransport: () => setNeedsTransport(false),
  setSelectedTransport: setTransport,
};

const JobTransportSelectionStepInputContainer =
  connect(mapStateToProps, actions)(JobTransportSelectionStepInput);

export default JobTransportSelectionStepInputContainer;
