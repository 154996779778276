import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const JobTransportSelectionStep = ({
  needsTransport,
  isAnimationFinished,
}) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Do you need an alternative transportation while the vehicle is being serviced?"
        source={SOURCE_CONCIERGE}
        isOpening
      />
    </AnimationGroup>
    {needsTransport !== null && (
      <Fragment>
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            message={needsTransport ? 'Yes.' : 'No.'}
            source={SOURCE_USER}
            isOpening
          />
        </AnimationGroup>
        {needsTransport && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Your loaner has been provisioned."
            source={SOURCE_CONCIERGE}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_1000}
            message="Our team will contact you regarding the next steps."
            source={SOURCE_CONCIERGE}
          />
        </AnimationGroup>
        )}
      </Fragment>
    )}
  </Fragment>
);

JobTransportSelectionStep.propTypes = {
  needsTransport: bool,
  isAnimationFinished: bool,
};

JobTransportSelectionStep.defaultProps = {
  needsTransport: null,
  isAnimationFinished: false,
};

export default JobTransportSelectionStep;
