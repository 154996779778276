export const WELCOME_STEP = 'WELCOME_STEP';
export const CONFIRM_IDENTITY_STEP = 'CONFIRM_IDENTITY_STEP';
export const CUSTOMER_IDENTIFICATION_STEP = 'CUSTOMER_IDENTIFICATION_STEP';
export const CUSTOMER_CREATION_STEP = 'CUSTOMER_CREATION_STEP';
export const CAR_SELECTION_STEP = 'CAR_SELECTION_STEP';
export const CAR_CREATION_STEP = 'CAR_CREATION_STEP';
export const JOB_CREATION_STEP = 'JOB_CREATION_STEP';
export const MILEAGE_STEP = 'MILEAGE_STEP';
export const SERVICE_SELECTION_STEP = 'SERVICE_SELECTION_STEP';
export const AVAILABLE_RECALLS_STEP = 'AVAILABLE_RECALLS_STEP';
export const ADVISOR_SELECTION_STEP = 'ADVISOR_SELECTION_STEP';
export const DATE_SELECTION_STEP = 'DATE_SELECTION_STEP';
export const TRANSPORT_SELECTION_STEP = 'TRANSPORT_SELECTION_STEP';
export const JOB_TRANSPORT_SELECTION_STEP = 'JOB_TRANSPORT_SELECTION_STEP';
export const BOOKING_SUMMARY_STEP = 'BOOKING_SUMMARY_STEP';
export const COMMUNICATION_STEP = 'COMMUNICATION_STEP';
export const DATE_RESELECTION_STEP = 'DATE_RESELECTION_STEP';
export const APPRAISAL_SELECTION_STEP = 'APPRAISAL_SELECTION_STEP';
export const FINAL_STEP = 'FINAL_STEP';
export const ERROR_STEP = 'ERROR_STEP';
export const REMOTE_SERVICE_STEP = 'REMOTE_SERVICE_STEP';
