import React, { useState } from 'react';
import { number, func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { chatLoadingSelector, chatVehicleMileageSelector } from 'selectors/booking/chat-selectors';
import { updateMileage } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.module.scss';

const INCREMENT_DECREMENT_STEP = 5000;

const MileageStepInput = ({
  currentMileage,
  onSubmit,
  isDisabled,
}) => {
  const [mileage, setCurrentMileage] = useState(parseInt(currentMileage, 10) || 0);

  const validateInput = value => /^([0-9]{0,6})$/.test(value);

  const incrementInSteps = () => {
    const sum = mileage + INCREMENT_DECREMENT_STEP;
    setCurrentMileage(validateInput(sum) ? sum : mileage);
  };

  const decrementInSteps = () => {
    const sum = mileage - INCREMENT_DECREMENT_STEP;
    setCurrentMileage(validateInput(sum) ? sum : 0);
  };

  const handleMileageChange = (event) => {
    if (validateInput(event.target.value)) {
      setCurrentMileage(event.target.value >= 0
        ? parseInt(event.target.value, 10)
        : mileage);
    }
  };

  const handleSubmit = () => onSubmit(mileage);

  return !isDisabled && (
    <div className={styles.container}>
      <div className={styles.inputsContainer}>
        <button
          className={styles.inputButton}
          onClick={decrementInSteps}
        >
          - 5 k
        </button>
        <input
          type="number"
          className={styles.inputField}
          min={0}
          value={mileage}
          onChange={handleMileageChange}
        />
        <button
          className={styles.inputButton}
          onClick={incrementInSteps}
        >
          + 5 k
        </button>
      </div>
      <Button
        className={styles.submit}
        caption="Confirm"
        disabled={!mileage}
        onClick={handleSubmit}
        isWide
      />
    </div>
  );
};

MileageStepInput.propTypes = {
  currentMileage: number,
  onSubmit: func.isRequired,
  isDisabled: bool,
};

MileageStepInput.defaultProps = {
  currentMileage: null,
  isDisabled: false,
};

const mapStateToProps = state => ({
  isDisabled: chatLoadingSelector(state),
  currentMileage: chatVehicleMileageSelector(state),
});

const actions = {
  onSubmit: updateMileage,
};

const MileageStepInputContainer = connect(mapStateToProps, actions)(MileageStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={props.mileage ? DELAY_1500 : DELAY_1000}>
      <MileageStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
