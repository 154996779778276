// eslint-disable-next-line consistent-return
export const getAddressComponentByType = (components, type) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < components.length; i++) {
    if (components[i].types.indexOf(type) > -1) {
      return components[i].long_name;
    }
  }
};

export const formattedAddressData = addressComponents => ({
  address_line1: getAddressComponentByType(addressComponents, 'street_number'),
  address_line2: getAddressComponentByType(addressComponents, 'route'),
  address_city: getAddressComponentByType(addressComponents, 'locality') || getAddressComponentByType(addressComponents, 'sublocality'),
  address_state: getAddressComponentByType(addressComponents, 'administrative_area_level_1'),
  address_zipcode: getAddressComponentByType(addressComponents, 'postal_code'),
});
