import React, { Component } from 'react';
import { number, func, string, arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { chatAvailableAdvisorsSelector, chatSelectedAdvisorSelector } from 'selectors/booking/chat-selectors';
import { selectAdvisor } from 'actions/booking/chat-actions';
import { imageUrl } from 'shared/utils';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import anyAdvisorIcon from 'assets/images/anyAdvisor.svg';
import anyAdvisorActiveIcon from 'assets/images/anyAdvisorActive.svg';
import Button from 'components/common/Button';
import AdvisorCarusel from '../AdvisorCarusel';

import styles from './styles.module.scss';

class AdvisorSelectionStepInput extends Component {
  constructor(props) {
    super(props);


    const { advisors } = this.props;
    let preparedAdvisors = [...advisors];
    let showFirstAvailable = false;

    const phantomAdvisors = advisors.filter(advisor => advisor.is_phantom);

    if (advisors.length > 1 || phantomAdvisors.length > 0) {
      const firstAvailable = {
        id: 'firstAvailable',
        name: 'First Available',
      };

      showFirstAvailable = true;

      if (phantomAdvisors.length > 0) {
        preparedAdvisors = [firstAvailable];
      } else {
        preparedAdvisors = [firstAvailable, ...advisors];
      }
    }

    this.state = {
      disabled: false,
      selectedAdvisor: this.props.selectedAdvisor || (
        this.props.advisors.length > 1 ?
          {
            id: 'firstAvailable',
            name: 'First Available',
          } :
          this.props.advisors[0]
      ),
      showFirstAvailable,
      preparedAdvisors,
    };
  }

  getImageUrl = (advisor) => {
    if (advisor.id === 'firstAvailable') {
      if (this.state.selectedAdvisor && (advisor.id === this.state.selectedAdvisor.id)) {
        return anyAdvisorActiveIcon;
      }

      return anyAdvisorIcon;
    }

    return imageUrl(advisor.photo);
  }

  handleSubmit = () => {
    let advisorIds = [];
    let phantomAdvisorIds = [];

    if (this.state.selectedAdvisor.id === 'firstAvailable') {
      advisorIds = this.props.advisors.map(advisor => advisor.id);
      phantomAdvisorIds = this.props.advisors
        .filter(advisor => advisor.is_phantom)
        .map(advisor => advisor.id);
    } else {
      advisorIds.push(this.state.selectedAdvisor.id);
    }

    this.props.onSelectAdvisor(this.state.selectedAdvisor, advisorIds, phantomAdvisorIds);
    this.setState({ disabled: true });
  }

  render() {
    const {
      disabled,
      selectedAdvisor,
      showFirstAvailable,
      preparedAdvisors,
    } = this.state;

    if (disabled) {
      return null;
    }

    return (
      <div className={styles.container}>
        <AdvisorCarusel
          serviceAdvisors={preparedAdvisors}
          selectedAdvisor={selectedAdvisor}
          showFirstAvailable={showFirstAvailable}
          onClickHandler={(serviceAdvisor) => {
            this.setState({ selectedAdvisor: serviceAdvisor });
          }}
        />
        {selectedAdvisor && (
          <Button
            className={styles.submit}
            caption="Confirm"
            onClick={this.handleSubmit}
            isWide
          />
        )}
      </div>
    );
  }
}

AdvisorSelectionStepInput.propTypes = {
  onSelectAdvisor: func.isRequired,
  advisors: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  })).isRequired,
  selectedAdvisor: shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  }).isRequired,
};

const mapStateToProps = state => ({
  advisors: chatAvailableAdvisorsSelector(state),
  selectedAdvisor: chatSelectedAdvisorSelector(state),
});

const actions = {
  onSelectAdvisor: selectAdvisor,
};

const AdvisorSelectionStepInputContainer =
  connect(mapStateToProps, actions)(AdvisorSelectionStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={DELAY_500}>
      <AdvisorSelectionStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
