import React, { Fragment } from 'react';
import { func, bool } from 'prop-types';

import { DELAY_500, DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { servicesPropType } from 'shared/constants/prop-types';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import RecallService from 'components/common/ExtensionService/RecallService';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import styles from './styles.scss';

const AvailableRecallsStep = ({
  isComplete,
  onToggle,
  availableRecalls,
  selectedRecalls,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="There is a safety recall available for your car."
        source={SOURCE_CONCIERGE}
        isOpening
      />
      <AnimatedTextMessage
        delay={DELAY_1000}
        message="Would you like to add this service to your booking?"
        source={SOURCE_CONCIERGE}
      />
      {!!availableRecalls.length && (
        <Animation delay={DELAY_1500}>
          <div className={styles.services}>
            <div className={styles.alert}>
              <p>Please note that recall services are conducted based on parts availability.</p>
              <p>Your advisor will update you upon arrival.</p>
            </div>
            {availableRecalls.map(service => (
              <RecallService
                key={service.id}
                service={service}
                onClick={isComplete ? () => null : () => onToggle(service)}
                isHighlighted={!!selectedRecalls.find(({ id }) => service.id === id)}
              />
            ))}
          </div>
        </Animation>
      )}
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={
            selectedRecalls.length
              ? `Let's add this to the list: ${selectedRecalls.map(s => s.name).join(', ')}`
              : 'No, thanks.'
            }
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AvailableRecallsStep.propTypes = {
  isComplete: bool,
  availableRecalls: servicesPropType,
  selectedRecalls: servicesPropType,
  onToggle: func.isRequired,
};

AvailableRecallsStep.defaultProps = {
  isComplete: false,
  availableRecalls: [],
  selectedRecalls: [],
};


export default AvailableRecallsStep;
