import React from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const InfoMessage = ({
  className,
  icon,
  label,
  content,
}) => (
  <div className={cx(styles.container, className)}>
    {label && (
      <div className={styles.header}>
        {icon && <img className={styles.icon} alt="" src={icon} />}
        <span className={styles.label}>{label}</span>
      </div>
    )}
    <p className={styles.content}>
      {content}
    </p>
  </div>
);

InfoMessage.propTypes = {
  className: string,
  icon: string,
  label: string,
  content: string,
};

InfoMessage.defaultProps = {
  className: '',
  icon: null,
  label: 'Good to know',
  content: '',
};

export default InfoMessage;
