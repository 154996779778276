import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_1500 } from 'shared/constants/delays';
import { extractPhoneNumberFromString } from 'shared/utils';
import { phoneNumberFormatValidator, phoneNumberLengthValidator } from 'shared/validators';
import { confirmNumber } from 'actions/booking/chat-actions';
import { appDealershipDmsTypeSelector } from 'selectors/booking/app-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import leftArrowIcon from 'assets/images/arrow-left.svg';
import sendIcon from 'assets/images/send.svg';
import Button from 'components/common/Button';

import styles from './styles.module.scss';

class CommunicationStepInput extends Component {
  state = {
    phoneNumber: '',
    valid: true,
    isEditing: false,
    isDisabled: false,
  }

  toggleEditing = () => this.setState({ isEditing: !this.state.isEditing });
  handleChange = event => this.setState({ phoneNumber: event.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    const numberString = this.state.phoneNumber;
    let phoneNumber = null;
    if (phoneNumberFormatValidator(numberString)) {
      phoneNumber = extractPhoneNumberFromString(numberString);
    }
    if (phoneNumber && phoneNumberLengthValidator(phoneNumber, this.props.dmsType)) {
      this.setState({ valid: true, isDisabled: true });
      this.props.onConfirm(phoneNumber);
    } else {
      this.setState({ valid: false });
    }
  }

  confirmPhoneNumber = () => {
    this.setState({ isDisabled: true });
    this.props.onConfirm();
  }

  renderForm = () => (
    <div className={styles.formContainer}>
      <button className={styles.back} onClick={this.toggleEditing}>
        <img alt="return icon" className={styles.icon} src={leftArrowIcon} />
      </button>
      <form onSubmit={this.handleSubmit}>
        <input
          autoFocus
          className={cx(styles.input, { [styles.invalid]: !this.state.valid })}
          value={this.state.phoneNumber}
          onChange={this.handleChange}
        />
      </form>
      <button className={styles.submitButton} onClick={this.handleSubmit}>
        <img alt="submit icon" className={styles.submitIcon} src={sendIcon} />
      </button>
    </div>
  )

  renderButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        className={styles.button}
        onClick={this.toggleEditing}
        caption="Change number"
        isWide
      />
      <Button
        className={styles.button}
        onClick={this.confirmPhoneNumber}
        caption="Confirm number"
        isWide
      />
    </div>
  )

  render() {
    const { isDisabled, isEditing } = this.state;

    if (!isDisabled) {
      return isEditing
        ? this.renderForm()
        : this.renderButtons();
    }
    return null;
  }
}

CommunicationStepInput.propTypes = {
  onConfirm: func.isRequired,
  dmsType: string.isRequired,
};

const mapStateToProps = state => ({
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onConfirm: confirmNumber,
};

const CommunicationStepInputContainer = connect(mapStateToProps, actions)(CommunicationStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={DELAY_1500}>
      <CommunicationStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
