import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { formatLabel } from 'shared/utils';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CarCreationStep = ({ selectedCarName, isComplete, isAnimationFinished }) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message="For which car would you like to book a visit?"
        source={SOURCE_CONCIERGE}
        isClosing
        timecodes={{ from: 46000, to: 50000 }}
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`For my ${formatLabel(selectedCarName)}.`}
          source={SOURCE_USER}
          isOpening
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CarCreationStep.propTypes = {
  selectedCarName: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

CarCreationStep.defaultProps = {
  selectedCarName: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default CarCreationStep;
