import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1500 } from 'shared/constants/delays';
import { requestAppraisal } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './../../TransportSelectionStep/Input/styles.scss';

const AppraisalSelectionStepInput = ({ onConfirm, onDeny, isAnimationFinished }) => (
  <AnimationGroup isComplete={isAnimationFinished}>
    <Animation delay={DELAY_1500}>
      <div className={styles.container}>
        <Button isSecondary caption="No" className={styles.no} onClick={onDeny} />
        <Button caption="Yes" className={styles.yes} onClick={onConfirm} />
      </div>
    </Animation>
  </AnimationGroup>
);

AppraisalSelectionStepInput.propTypes = {
  onConfirm: func.isRequired,
  onDeny: func.isRequired,
  isAnimationFinished: bool,
};

AppraisalSelectionStepInput.defaultProps = {
  isAnimationFinished: false,
};

const actions = {
  onConfirm: () => requestAppraisal(true),
  onDeny: () => requestAppraisal(false),
};

export default connect(null, actions)(AppraisalSelectionStepInput);
