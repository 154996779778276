import { lambdaCall } from 'shared/api';

export const SET_CONCIERGE_SPEAKING = 'SET_CONCIERGE_SPEAKING';
export const TRANSCRIPTION_RECEIVED = 'TRANSCRIPTION_RECEIVED';
export const CLASSIFICATION_SUCCESS = 'CLASSIFICATION_SUCCESS';
export const CLASSIFICATION_FAILURE = 'CLASSIFICATION_FAILURE';

export const speak = (message, delay) => () => {
  if (window.voiceInterface.queueMessage) {
    setTimeout(() => {
      window.voiceInterface.queueMessage(message);
    }, delay);
  }
};

export const stopSpeaking = () => {
  if (window.voiceInterface && window.voiceInterface.clearSpeechQueue) {
    window.voiceInterface.clearSpeechQueue();
  }
};

export const conciergeStartedSpeaking = () => ({
  type: SET_CONCIERGE_SPEAKING,
  payload: { value: true },
});

export const conciergeFinishedSpeaking = () => ({
  type: SET_CONCIERGE_SPEAKING,
  payload: { value: false },
});

export const userFinishedSpeaking = transcription => ({
  type: TRANSCRIPTION_RECEIVED,
  payload: { transcription },
});

export const classifyTranscription = params => (dispatch) => {
  const { modelName } = params;
  lambdaCall(params)
    .then(response => (
      dispatch({
        type: CLASSIFICATION_SUCCESS,
        payload: {
          classification: JSON.parse(response.Payload).body,
          modelName,
        },
      })
    ))
    .catch(error => dispatch({ type: CLASSIFICATION_FAILURE, payload: { error } }));
};

export const utteranceNotMatched = (message = "Sorry I didn't get that") => speak(message);
