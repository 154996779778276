import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import 'core-js';
import 'normalize.css';

const initApp = (View, reducer, shouldSubscribe, shouldCreateStore = true) => {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = shouldCreateStore
    ? createStore(
      reducer,
      composeEnhancers(applyMiddleware(thunk)),
    )
    : null;

  if (shouldCreateStore && shouldSubscribe) {
    store.subscribe(() => {
      localStorage.setItem('CONCIERGE_TOKEN', store.getState().session.token);
      localStorage.setItem('CONCIERGE_REFRESH_TOKEN', store.getState().session.refreshToken);
    });
  }

  const content = (Component) => {
    if (shouldCreateStore) {
      return (
        <AppContainer>
          <Provider store={store}>
            <Component />
          </Provider>
        </AppContainer>
      );
    }

    return <Component />;
  };

  function render(Component) {
    ReactDOM.render(
      content(Component),
      document.getElementById('concierge-root'),
    );
  }

  render(View);

  if (module.hot) {
    module.hot.accept(`./components/views/${View}`, () => {
      // eslint-disable-next-line import/no-dynamic-require
      const nextApp = require(`./components/views/${View}`).default; // eslint-disable-line global-require
      render(nextApp);
    });
  }
};

export default initApp;
