import AWS from 'aws-sdk/global';
import Lambda from 'aws-sdk/clients/lambda';

AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL,
});

export const lambda = new Lambda({
  region: process.env.REACT_APP_AWS_REGION,
  apiVersion: '2015-03-31',
});
