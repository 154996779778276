import React, { useEffect, useState } from 'react';
import { func, bool, string, number, shape } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { DELAY_1500 } from 'shared/constants/delays';
import {
  confirmAddress,
  confirmJobCreation,
  denyJobCreation,
  resetAddress,
  updateCurrentStep,
  setRemoteZone,
  setRemoteGeolocation,
  setSkipPickUp,
  setSkipMobileTechnician,
  setIsMobileTechnician,
  setIsPickUp,
} from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';
import { formattedAddressData } from 'shared/getAddressComponents';
import {
  chatCustomerAddressSelector,
  chatCustomerLastAddressSelector,
  chatIsMobileTechnician,
  chatSkipPickUpSelector,
  chatIsPickUp,
  chatPickUpAvailableSelector,
  chatMobileTechnicianAvailableSelector,
} from 'selectors/booking/chat-selectors';
import {
  appMobileTechnicianSegments,
  appDealershipPosition,
  appMobileTechnicianEnabled,
  appPickupEnabled,
  appPickupSegments,
} from 'selectors/booking/app-selectors';
import leftArrowIcon from 'assets/images/arrow-left.svg';
import sendIcon from 'assets/images/send.svg';

import styles from './styles.scss';

const zone = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const RemoteServiceSelectionStepInput = ({
  onConfirm,
  onDeny,
  onConfirmAddress,
  onResetAddress,
  onUpdateCurrentStep,
  customerAddress,
  errors,
  isConfirmed,
  mobileTechnicianSegments,
  pickupSegments,
  dealershipPosition,
  setZone,
  setGeolocation,
  skipPickUp,
  pickUpEnabled,
  mobileTechnicianEnabled,
  isPickUp,
  isMobileTechnician,
  onSkipPickUp,
  onSkipMobileTechnician,
  onSetIsMobileTechnician,
  onSetIsPickUp,
  pickUpAvailable,
  mobileTechnicianAvailable,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState(customerAddress);
  const [addressData, setAddressData] = useState(null);
  const [currentGeolocation, setCurrentGeolocation] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    onSkipPickUp(false);
    onSkipMobileTechnician(false);
    onSetIsMobileTechnician(false);
    onSetIsPickUp(false);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleSelect(customerAddress);
  }, [isPickUp, isMobileTechnician]);

  const toggleEditing = () => {
    onUpdateCurrentStep({ isProvidingNewAddress: !isEditing });
    setIsEditing(!isEditing);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirmAddress(address, addressData, currentGeolocation);
    setIsEditing(false);
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const geolocation = await getLatLng(results[0]);

    // eslint-disable-next-line new-cap
    let heading = window.google.maps.geometry.spherical.computeHeading(
      dealershipPosition,
      geolocation,
    );

    const segments = isMobileTechnician ? mobileTechnicianSegments : pickupSegments;
    heading = heading > 0 ? heading : heading + 360;
    const angle = 360 / segments;
    const index = Math.ceil(heading / angle) > 0 ? Math.ceil(heading / angle) - 1 : 0;

    setZone(zone[index]);

    const formattedAddress = results[0].formatted_address;
    const addressComponents = results[0].address_components;
    const data = formattedAddressData(addressComponents);

    setGeolocation(geolocation);
    setCurrentGeolocation(geolocation);
    setAddress(formattedAddress);
    setAddressData(data);

    if (data.address_line1) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleChange = (value) => {
    setAddress(value);
    setAddressData(null);
    setDisableButton(true);
  };

  const renderAddressForm = isReturnable => (
    <div className={styles.formContainer}>
      {isReturnable && (
        <button className={styles.back} onClick={toggleEditing}>
          <img alt="return icon" className={styles.icon} src={leftArrowIcon} />
        </button>
      )}
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ['address'],
          componentRestrictions: {
            country: ['us', 'ca'],
          },
        }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div className={styles.additionalInfoItemLocationWrapper}>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: styles.additionalInfoItemLocationInput,
              })}
            />
            {suggestions && suggestions.length > 0 && (
            <div className={styles.autocompleteDropdownContainer}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = cx(styles.suggestionItem, {
                  [styles.suggestionItemActive]: suggestion.active,
                });

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      <button
        className={styles.submitButton}
        onClick={handleSubmit}
        disabled={!address || disableButton}
      >
        <img alt="submit icon" className={styles.submitIcon} src={sendIcon} />
      </button>
    </div>
  );

  const renderAddressButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        caption="Change address"
        className={styles.no}
        onClick={toggleEditing}
      />
      <Button
        caption="Confirm address"
        className={styles.yes}
        onClick={handleSubmit}
      />
    </div>
  );

  const renderErrorButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        caption={
          ((skipPickUp || !pickUpAvailable) && mobileTechnicianEnabled && mobileTechnicianAvailable)
          || (!skipPickUp && !pickUpEnabled)
          ? 'Cancel Mobile Tecnician'
          : 'Cancel Pickup'
        }
        className={styles.no}
        onClick={() => onDeny({ isCanceled: true, isConfirmed: true })}
      />
      <Button
        caption="Different address"
        className={styles.yes}
        onClick={() => onResetAddress()}
      />
    </div>
  );

  const renderJobCreationButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        caption="No, no need."
        className={styles.no}
        onClick={() => onDeny({ isConfirmed: false })}
      />
      <Button
        caption="Yes, please."
        className={styles.yes}
        onClick={() => onConfirm()}
      />
    </div>
  );

  const content = () => {
    if (errors) {
      return renderErrorButtons();
    }
    if (!isConfirmed) {
      return renderJobCreationButtons();
    }
    // eslint-disable-next-line camelcase
    if (!address || !addressData?.address_line1) {
      return renderAddressForm(false);
    }
    if (isEditing) {
      return renderAddressForm(true);
    }

    return renderAddressButtons();
  };


  return (
    content()
  );
};

RemoteServiceSelectionStepInput.propTypes = {
  onConfirm: func.isRequired,
  onDeny: func.isRequired,
  isAnimationFinished: bool,
  onConfirmAddress: func.isRequired,
  onResetAddress: func.isRequired,
  onUpdateCurrentStep: func.isRequired,
  isConfirmed: bool,
  customerAddress: string,
  errors: string,
  mobileTechnicianSegments: number.isRequired,
  pickupSegments: number.isRequired,
  dealershipPosition: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  isMobileTechnician: bool.isRequired,
  skipPickUp: bool.isRequired,
  pickUpEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  isPickUp: bool.isRequired,
  onSkipPickUp: func.isRequired,
  onSkipMobileTechnician: func.isRequired,
  onSetIsMobileTechnician: func.isRequired,
  onSetIsPickUp: func.isRequired,
  setZone: func.isRequired,
  setGeolocation: func.isRequired,
  pickUpAvailable: bool.isRequired,
  mobileTechnicianAvailable: bool.isRequired,
};

RemoteServiceSelectionStepInput.defaultProps = {
  isAnimationFinished: false,
  isConfirmed: false,
  customerAddress: '',
  errors: '',
};

const actions = {
  onConfirmAddress: confirmAddress,
  onConfirm: confirmJobCreation,
  onDeny: denyJobCreation,
  onResetAddress: resetAddress,
  onUpdateCurrentStep: updateCurrentStep,
  setZone: setRemoteZone,
  setGeolocation: setRemoteGeolocation,
  onSkipPickUp: setSkipPickUp,
  onSkipMobileTechnician: setSkipMobileTechnician,
  onSetIsMobileTechnician: setIsMobileTechnician,
  onSetIsPickUp: setIsPickUp,
};

const mapStateToProps = state => ({
  customerAddress: chatCustomerLastAddressSelector(state) || chatCustomerAddressSelector(state),
  mobileTechnicianSegments: appMobileTechnicianSegments(state),
  pickupSegments: appPickupSegments(state),
  dealershipPosition: appDealershipPosition(state),
  isMobileTechnician: chatIsMobileTechnician(state),
  skipPickUp: chatSkipPickUpSelector(state),
  pickUpEnabled: appPickupEnabled(state),
  mobileTechnicianEnabled: appMobileTechnicianEnabled(state),
  isPickUp: chatIsPickUp(state),
  pickUpAvailable: chatPickUpAvailableSelector(state),
  mobileTechnicianAvailable: chatMobileTechnicianAvailableSelector(state),
});

const RemoteServiceSelectionStepInputContainer = connect(
  mapStateToProps,
  actions,
)(RemoteServiceSelectionStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={DELAY_1500}>
      <RemoteServiceSelectionStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
