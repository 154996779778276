import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const DateReselectionStep = ({
  selectedDate,
  selectedTime,
  isComplete,
  isAnimationFinished,
}) => (
  <Fragment>
    {(!isComplete || (isComplete && selectedDate)) && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          delay={DELAY_500}
          message="Sorry, it seems that dealership cannot service your car during the date / time window you selected."
          source={SOURCE_CONCIERGE}
          isOpening
        />
        <AnimatedTextMessage
          delay={DELAY_1000}
          message="Please choose a different time for your appointment:"
          source={SOURCE_CONCIERGE}
          isClosing
        />
      </AnimationGroup>
    )}
    {isComplete && selectedDate && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Book visit for ${selectedDate}, ${selectedTime}`}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

DateReselectionStep.propTypes = {
  selectedDate: string,
  selectedTime: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

DateReselectionStep.defaultProps = {
  selectedDate: '',
  selectedTime: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default DateReselectionStep;
