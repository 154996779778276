const CDK_PHONE_NUMBER_LENGTHS = [7, 10];
const DT_PHONE_NUMBER_LENGTH = [9, 10];

export const emailValidator = email => (
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)
);

export const plateNumberValidator = plateNumber => (
  /^[a-zA-Z0-9\s]{1,}$/.test((plateNumber || '').trim())
);

export const fullNameValidator = name => (
  /^\w+\s.*\w+$/.test(name.trim())
);

export const bookingNumberValidator = bookingNumber => (
  /^.+$/.test(bookingNumber.trim())
);

export const phoneNumberFormatValidator = phoneNumber => (
  /^[\d\-\s]{7,12}$/.test(phoneNumber.trim())
);

export const phoneNumberLengthValidator = (phoneNumber, dmsType) => {
  const lengthValues = dmsType === 'cdk' ? CDK_PHONE_NUMBER_LENGTHS : DT_PHONE_NUMBER_LENGTH;
  return lengthValues.includes(phoneNumber.length);
};
