import React from 'react';
import { connect } from 'react-redux';
import 'moment-timezone';

import {
  appDealershipTimeZoneSelector,
  appPhantomAdvisorAvailable,
} from 'selectors/booking/app-selectors';
import {
  availableDatesSelector,
  chatIsPickUp,
  chatIsMobileTechnician,
  chatSelectedAdvisorIds,
  chatAvailableAdvisorsSelector,
  chatSelectedDateTimeSelector,
  chatSelectedPhantomAdvisorIdsSelector,
  chatDriversSelector,
  chatTechniciansSelector,
  chatLoadingSelector,
  chatCustomerWaitingSelector,
} from 'selectors/booking/chat-selectors';
import { selectDate, initializeDateSelectionStep, setIsWaiting } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import DateSelection from 'components/common/DateSelection';

const mapStateToProps = state => ({
  timeZone: appDealershipTimeZoneSelector(state),
  availableDates: availableDatesSelector(state),
  isPickup: chatIsPickUp(state),
  isMobileTechnician: chatIsMobileTechnician(state),
  advisorIds: chatSelectedAdvisorIds(state),
  advisors: chatAvailableAdvisorsSelector(state),
  selectedDateTime: chatSelectedDateTimeSelector(state),
  phantomAdvisorIds: chatSelectedPhantomAdvisorIdsSelector(state),
  phantomAdvisorAvailable: appPhantomAdvisorAvailable(state),
  drivers: chatDriversSelector(state),
  technicians: chatTechniciansSelector(state),
  loadingState: chatLoadingSelector(state),
  isWaiting: chatCustomerWaitingSelector(state),
});

const actions = {
  onSubmit: selectDate,
  updateCalendarView: initializeDateSelectionStep,
  setWaiting: setIsWaiting,
};

const DateSelectionStepInput = connect(mapStateToProps, actions)(DateSelection);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation>
      <DateSelectionStepInput {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
