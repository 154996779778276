import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import InfoMessage from 'components/common/InfoMessage';

import bulbIcon from 'assets/images/bulb.svg';

const AppraisalSelectionStep = ({ isComplete, appraisalRequested, isAnimationFinished }) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <Animation delay={DELAY_500}>
        <InfoMessage
          icon={bulbIcon}
          content="We have exciting programs on new and Certified pre-owned vehicles.
            You may qualify for these offers."
        />
      </Animation>
      <AnimatedTextMessage
        delay={DELAY_1000}
        message="Would you like to have your vehicle appraised and view your options?"
        source={SOURCE_CONCIERGE}
        isOpening
        timecodes={{ from: 162000, to: 174000 }}
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={appraisalRequested ? 'Yes.' : 'No.'}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AppraisalSelectionStep.propTypes = {
  isComplete: bool,
  isAnimationFinished: bool,
  appraisalRequested: bool,
};

AppraisalSelectionStep.defaultProps = {
  isComplete: false,
  isAnimationFinished: false,
  appraisalRequested: false,
};

export default AppraisalSelectionStep;
