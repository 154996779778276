import React, { useState, useEffect } from 'react';
import { number, func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

import LanguageSelect from 'components/common/LanguageSelect';
import {
  chatProgressSelector,
  chatIsStepUndoableSelector,
  chatSelectedAdvisorSelector,
} from 'selectors/booking/chat-selectors';
import { appDealershipPhoneSelector, appLocalizationForCustomersEnabled, appDealershipIdSelector } from 'selectors/booking/app-selectors';
import { undoCurrentStep } from 'actions/booking/chat-actions';
import { imageUrl } from 'shared/utils';
import helpIcon from 'assets/images/help.svg';
import closeIcon from 'assets/images/cross.svg';
import undoIcon from 'assets/images/arrow-left-blue.svg';
import messageimg from 'assets/images/message.svg';
import phoneimg from 'assets/images/phone.svg';
import userImg from 'assets/images/user.png';


import styles from './styles.module.scss';

const options = [
  { label: 'English', tag: 'ENG', value: 'ru|en' },
  { label: 'Español', tag: 'ESP', value: 'ru|es' },
  { label: 'Français', tag: 'FRA', value: 'ru|fr' },
  { label: 'Polski', tag: 'POL', value: 'ru|pl' },
  { label: 'Português', tag: 'POR', value: 'ru|pt' },
  { label: '中文 (Chinese)', tag: 'CHN', value: 'ru|zh-CN' },
  { label: '한국어 (Korean)', tag: 'KOR', value: 'ru|ko' },
  { label: 'Tiếng việt (Vietnamese)', tag: 'VIE', value: 'ru|vi' },
  { label: 'Русский', tag: 'RUS', value: 'ru|ru' },
];

const HeaderBarMobile = ({
  isStepUndoable,
  progress,
  advisor,
  dealershipPhone,
  onUndo,
  localizationForCustomersEnabled,
  proactiveLocalizationEnabled,
  dealershipName,
  toggleVideo,
  dealershipId,
}) => {
  const [isHelpOpen, setIsHelpOpen] = useState();
  const [language, setLanguage] = useState({
    label: 'English',
    value: 'ru|en',
    tag: 'ENG',
  });

  const googleTranslateElementInit = () => {
    // eslint-disable-next-line no-new
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.SIMPLE,
      },
      'google_translate_element',
    );
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);


  return (
    <div>
      {isHelpOpen && (
        <div
          role="button"
          tabIndex="-1"
          className={styles.helpOverlay}
          onClick={() => setIsHelpOpen(false)}
        />
      )}
      <div className={styles.container}>
        {dealershipName && (
          <div className={styles.header}>
            <span className={styles.dealerName}>{dealershipName}</span>
          </div>
        )}
        <div className={styles.languageContainer}>
          {isStepUndoable && (
          <button onClick={() => onUndo()} className={styles.undo}>
            <img src={undoIcon} alt="undo" className={styles.icon} />
          </button>
          )}
          {!isStepUndoable && <div />}
          <div className={styles.buttons}>
            {(localizationForCustomersEnabled || proactiveLocalizationEnabled) &&
              <LanguageSelect
                menuPlacement="bottom"
                hideError
                value={{
                  label: language.tag,
                  value: language.value,
                }}
                options={options}
                className="notranslate"
                onChange={({ value }) => {
                  const newLanguage = options.filter(option => option.value === value)[0];
                  setLanguage(newLanguage);
                  // eslint-disable-next-line no-undef
                  doGTranslate(value);
                }}
              />
            }
            <div id="google_translate_element" className={styles.hiddenTranslate} />
            {dealershipId === 1 && process.env === 'staging' && (
              <button
                className={styles.button}
                onClick={toggleVideo}
              >
                A
              </button>
            )}
            <button
              className={styles.button}
              onClick={() => setIsHelpOpen(!isHelpOpen)}
            >
              <img
                src={helpIcon}
                alt="help"
                className={cx(styles.icon, {
                [styles.iconOpen]: isHelpOpen,
              })}
              />
            </button>
            {isMobile && (
              <button
                className={styles.button}
                onClick={() => {
                  window.ReactNativeWebView.postMessage('back');
                }}
              >
                <img src={closeIcon} alt="close" className={styles.icon} />
              </button>
            )}
          </div>
        </div>
        {isHelpOpen && (
        <div className={cx(styles.help, {
            [styles.mobileHelp]: !isMobile,
          })}
        >
          <div className={styles.helpTitle}>Description</div>
          <div className={styles.helpText}>
            {`Stuck or unsure of this process? No problem, we are here to help.
              Use the buttons below to contact a member of our team directly.`}
          </div>
          {dealershipPhone || advisor?.name ? (
            <div
              className={cx(styles.advisor, {
                  [styles.advisorActive]: !!advisor?.name,
                })}
            >
              {advisor && advisor.name && (
              <div className={styles.advisorInfo}>
                <img
                  className={styles.advisorImg}
                  alt=""
                  src={imageUrl(advisor?.photo) || userImg}
                />
                <div className={styles.advisorContent}>
                  <div className={styles.advisorTitle}>Advisor</div>
                  <div className={styles.advisorName}>{advisor.name}</div>
                </div>
              </div>
                )}
              {dealershipPhone && (
              <div className={styles.advisorBtns}>
                <a
                  href={`sms:+${dealershipPhone}`}
                  className={cx(styles.roundBtn, styles.roundBtnMint)}
                >
                  <img src={messageimg} alt="" />
                </a>
                <a
                  href={`tel:+${dealershipPhone}`}
                  className={cx(styles.roundBtn, styles.roundBtnPurple)}
                >
                  <img src={phoneimg} alt="" />
                </a>
              </div>
                )}
            </div>
            ) : null}
        </div>
        )}
        <div className={styles.progressContainer}>
          <div
            className={styles.progress}
            style={{
              width: `${progress}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

HeaderBarMobile.propTypes = {
  progress: number.isRequired,
  isStepUndoable: bool.isRequired,
  onUndo: func.isRequired,
  advisor: string,
  dealershipPhone: string,
  dealershipName: string,
  localizationForCustomersEnabled: bool,
  proactiveLocalizationEnabled: bool,
  toggleVideo: func,
  dealershipId: number,
};

HeaderBarMobile.defaultProps = {
  advisor: null,
  dealershipPhone: null,
  localizationForCustomersEnabled: false,
  proactiveLocalizationEnabled: false,
  dealershipName: null,
  toggleVideo: () => {},
  dealershipId: null,
};

const mapStateToProps = state => ({
  isStepUndoable: chatIsStepUndoableSelector(state),
  progress: chatProgressSelector(state),
  dealershipPhone: appDealershipPhoneSelector(state),
  advisor: chatSelectedAdvisorSelector(state),
  localizationForCustomersEnabled: appLocalizationForCustomersEnabled(state),
  dealershipId: appDealershipIdSelector(state),
});

const actions = {
  onUndo: undoCurrentStep,
};

export default connect(mapStateToProps, actions)(HeaderBarMobile);
