import {
  fetchProactiveAppointment,
  fetchResponsibleTeamTag,
  fetchTeamAdvisors,
  fetchAvailableAdvisors,
  fetchAppointmentSlots,
  addBookingAppointment,
  updateStatistics,
} from 'shared/api';
import {
  proactiveDealershipIdSelector,
  proactiveBookingIdSelector,
  proactiveBookingSelector,
  proactiveAdvisorsIdsSelector,
  proactiveVehicleSelector,
  proactiveCustomerSelector,
  proactivePhoneNumberSelector,
} from 'selectors/booking/proactive-selectors';
import { chatBookingSource, chatCustomerWaitingSelector } from 'selectors/booking/chat-selectors';

export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_CALENDAR_LOADING_STATE = 'SET_CALENDAR_LOADING_STATE';
export const FETCH_PROACTIVE_APPOINTMENT_SUCCESS = 'FETCH_PROACTIVE_APPOINTMENT_SUCCESS';
export const FETCH_PROACTIVE_APPOINTMENT_FAILURE = 'FETCH_PROACTIVE_APPOINTMENT_FAILURE';
export const FETCH_PROACTIVE_TEAM_TAG_SUCCESS = 'FETCH_PROACTIVE_TEAM_TAG_SUCCESS';
export const FETCH_PROACTIVE_TEAM_TAG_FAILURE = 'FETCH_PROACTIVE_TEAM_TAG_FAILURE';
export const FETCH_PROACTIVE_ADVISORS_SUCCESS = 'FETCH_PROACTIVE_ADVISORS_SUCCESS';
export const FETCH_PROACTIVE_ADVISORS_FAILURE = 'FETCH_PROACTIVE_ADVISORS_FAILURE';
export const FETCH_PROACTIVE_APPOINTMENT_SLOTS_SUCCESS = 'FETCH_PROACTIVE_APPOINTMENT_SLOTS_SUCCESS';
export const FETCH_PROACTIVE_APPOINTMENT_SLOTS_FAILURE = 'FETCH_PROACTIVE_APPOINTMENT_SLOTS_FAILURE';
export const CREATE_PROACTIVE_APPOINTMENT_SUCCESS = 'CREATE_PROACTIVE_APPOINTMENT_SUCCESS';
export const CREATE_PROACTIVE_APPOINTMENT_FAILURE = 'CREATE_PROACTIVE_APPOINTMENT_FAILURE';
export const SET_PROACTIVE_DATETIME = 'SET_PROACTIVE_DATETIME';

const setLoadingState = () => ({
  type: SET_LOADING_STATE,
  payload: {},
});

export const getProactiveAppointment = guid => (dispatch) => {
  dispatch(setLoadingState());

  return fetchProactiveAppointment(guid)
    .then(response => dispatch({
      type: FETCH_PROACTIVE_APPOINTMENT_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: FETCH_PROACTIVE_APPOINTMENT_FAILURE,
      payload: { error },
    }));
};

export const fetchBookingSlots = (
  advisorIds = [],
  year = new Date().getFullYear() + 1,
  month = new Date().getMonth() + 1,
) => (dispatch, getState) => {
  const bookingId = proactiveBookingIdSelector(getState());
  const dealershipId = proactiveDealershipIdSelector(getState());
  const vehicleId = proactiveVehicleSelector(getState())?.id;
  const menuItemIds = proactiveBookingSelector(getState()).services.map(({ id }) => id);

  dispatch({
    type: SET_CALENDAR_LOADING_STATE,
    payload: {},
  });

  fetchAppointmentSlots(
    bookingId,
    {
      service_advisors_ids: advisorIds,
      dealership_id: dealershipId,
      vehicle_id: vehicleId,
      menu_items_ids: menuItemIds,
      year,
      month,
    },
  )
    .then((response) => {
      dispatch({
        type: FETCH_PROACTIVE_APPOINTMENT_SLOTS_SUCCESS,
        payload: response,
      });
    })
    .catch(error => dispatch({
      type: FETCH_PROACTIVE_APPOINTMENT_SLOTS_FAILURE,
      payload: { error },
    }));
};

export const setAdvisors = () => (dispatch, getState) => {
  const dealershipId = proactiveDealershipIdSelector(getState());
  const bookingId = proactiveBookingIdSelector(getState());
  const vehicleId = proactiveVehicleSelector(getState())?.id;
  const menuItemIds = proactiveBookingSelector(getState()).services.map(({ id }) => id);

  const payload = {
    vehicle_id: vehicleId,
    menu_items_ids: menuItemIds,
  };

  fetchResponsibleTeamTag(bookingId, payload)
    .then((res) => {
      dispatch({ type: FETCH_PROACTIVE_TEAM_TAG_SUCCESS, payload: res });

      fetchTeamAdvisors(bookingId, res.id)
        .then(response => response
          .map(({
            service_advisor_profile: serviceAdvisorProfile,
          }) => serviceAdvisorProfile).filter(v => v))
        .then((teamAdvisors) => {
          if (!teamAdvisors.length) {
            return fetchAvailableAdvisors(bookingId, dealershipId);
          }
          return teamAdvisors;
        })
        .then((response) => {
          dispatch({ type: FETCH_PROACTIVE_ADVISORS_SUCCESS, payload: { advisors: response } });
        })
        .then(() => {
          const advisorIds = proactiveAdvisorsIdsSelector(getState());
          dispatch(fetchBookingSlots(advisorIds));
        })
        .catch((error) => {
          dispatch({
            type: FETCH_PROACTIVE_ADVISORS_FAILURE,
            payload: { error },
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROACTIVE_TEAM_TAG_FAILURE,
        payload: { error },
      });
    });
};

export const createAppointment = (
  timeZoneDateTime,
  advisor,
) => (dispatch, getState) => {
  const bookingId = proactiveBookingIdSelector(getState());
  const dealershipId = proactiveDealershipIdSelector(getState());
  const booking = proactiveBookingSelector(getState());
  const vehicle = proactiveVehicleSelector(getState());
  const customer = proactiveCustomerSelector(getState());
  const menuItemIds = proactiveBookingSelector(getState()).services.map(({ id }) => id);
  const phoneNumber = proactivePhoneNumberSelector(getState());
  const source = chatBookingSource(getState());
  const clientWaiting = chatCustomerWaitingSelector(getState());

  dispatch({
    type: SET_PROACTIVE_DATETIME,
    payload: timeZoneDateTime,
  });

  const details = {
    preferredPhoneNumber: phoneNumber,
    currentVehicle: vehicle,
    currentCustomer: customer,
    selectedAdvisor: advisor,
    firstAvailable: true,
    selectedDateTime: timeZoneDateTime,
    menuItemIds,
    clientWaiting,
    sendSms: true,
    appraisalRequested: false,
    selectedServices: booking.services,
  };

  dispatch(setLoadingState());

  addBookingAppointment(
    source,
    bookingId,
    dealershipId,
    details,
    'inhouse',
  ).then((response) => {
    dispatch({
      type: CREATE_PROACTIVE_APPOINTMENT_SUCCESS,
      payload: response,
    });
  })
    .catch(error => dispatch({
      type: CREATE_PROACTIVE_APPOINTMENT_FAILURE,
      payload: { error },
    }));
};

export const initializeProactiveBooking = guid => (dispatch) => {
  dispatch(getProactiveAppointment(guid))
    .then(() => dispatch(setAdvisors()))
    .catch(error => dispatch({
      type: FETCH_PROACTIVE_APPOINTMENT_FAILURE,
      payload: error.response.data.errors,
    }));
};

export const updateBookingStatistics = params => (dispatch, getState) => {
  const bookingId = proactiveBookingIdSelector(getState());
  const customer = proactiveCustomerSelector(getState());

  if (customer?.id) {
    updateStatistics(
      bookingId,
      {
        ...params,
        customer_id: customer.id,
      },
    );
  }
};
