import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { exit } from 'actions/booking/app-actions';
import { replayLastRequest } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.scss';

const ErrorStepInput = ({
  canRetry,
  onRetry,
  onExit,
  isAnimationFinished,
}) => (
  canRetry && (
    <AnimationGroup isComplete={isAnimationFinished}>
      <Animation delay={DELAY_500}>
        <div className={styles.container}>
          <Button
            caption="Exit"
            isSecondary
            className={styles.no}
            onClick={onExit}
          />
          <Button
            caption="Try Again"
            className={styles.yes}
            onClick={onRetry}
          />
        </div>
      </Animation>
    </AnimationGroup>
  )
);

ErrorStepInput.propTypes = {
  canRetry: bool,
  onExit: func.isRequired,
  onRetry: func.isRequired,
  isAnimationFinished: bool,
};

ErrorStepInput.defaultProps = {
  canRetry: false,
  isAnimationFinished: false,
};

const actions = {
  onExit: exit,
  onRetry: replayLastRequest,
};

const ErrorStepInputContainer = connect(null, actions)(ErrorStepInput);

export default ErrorStepInputContainer;
