import React, { useEffect, useState } from 'react';
import { number, string, func, arrayOf, shape, bool } from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import InlineSVG from 'react-inlinesvg';
import PackageItems from 'components/common/PackageItems';
import selectedIcon from 'assets/images/services/service-checked.svg';
import unselectedIcon from 'assets/images/services/service-plus.svg';
import disabledIcon from 'assets/images/services/service-plus-disabled.svg';
import mobileTech from 'assets/images/services/mobile-technician.svg';

import { remove, makeFloat } from 'shared/utils';
import Button from 'components/common/Button';
import { setTimecodes } from '../../../../../../actions/booking/chat-actions';

import styles from './styles.module.scss';

const ServiceSelector = ({
  servicesPriceVisible,
  mobileTechnicianEnabled,
  icon,
  label,
  availableServices,
  onSubmit,
  selectedServices,
  onSetTimecodes,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [chosenServices, setChosenServices] = useState(selectedServices);
  const [disabledPackageItems, setDisabledPackageItems] = useState((
    [].concat(...chosenServices.map(s => s.package_items))
  ));

  useEffect(() => {
    onSetTimecodes(label === 'Concern'
      ? { from: 194000, to: 197000 }
      : { from: 59000, to: 70000 });
  }, []);

  const handleSubmit = () => {
    setIsExpanded(false);
    onSubmit(chosenServices);
  };

  const includesService = (collection, service) => (
    collection.map(item => item.id).includes(service.id)
  );

  const isServiceDisabled = service => includesService(disabledPackageItems || [], service);

  const toggleService = (service) => {
    if (chosenServices.find(s => s.id === service.id)) {
      setChosenServices(chosenServices.filter(s => s.id !== service.id));
      setDisabledPackageItems(remove(disabledPackageItems, service.package_items));
    } else {
      const filteredServices = chosenServices.filter(s =>
        !includesService(service.package_items, s)).concat(service);
      setChosenServices(filteredServices);
      setDisabledPackageItems((disabledPackageItems || []).concat(service.package_items));
    }
  };

  const renderIcon = (className, src, isDisabled) => (
    <div className={className}>
      <InlineSVG src={isDisabled ? disabledIcon : src} />
    </div>
  );

  const renderService = (service, isSelected, isDisabled) => {
    const {
      package_items: packageItems, name, extras, saving, fee, price,
      premium_extras: premiumExtras,
      team_tags: teamTags,
    } = service;

    const isMobileTech = (
      mobileTechnicianEnabled
      && teamTags
      && teamTags.filter(team => team.mobile).length > 0
    );

    return (
      <div className={styles.serviceWrapOuter} key={service.id}>
        <div className={cx(styles.serviceWrapInner, {
          [styles.highlighted]: isSelected,
          [styles.disabled]: isDisabled,
          })}
        >
          <button
            key={service.id}
            className={styles.service}
            onClick={() => toggleService(service)}
            disabled={isDisabled}
          >
            <div className={styles.serviceContainer}>
              <div className={styles.serviceLabel}>
                <span className={styles.serviceName}>{name}</span>
                {isMobileTech &&
                  <InlineSVG
                    className={styles.mobileTechIcon}
                    src={mobileTech}
                  />
                }
              </div>
              <div className={styles.info}>
                {isSelected
                  ? renderIcon(styles.selectedIcon, selectedIcon, isDisabled)
                  : renderIcon(styles.unselectedIcon, unselectedIcon, isDisabled)
                }
              </div>
            </div>
            <div className={styles.servicePriceWrap}>
              {servicesPriceVisible && (
                <div className={styles.servicePriceTag}>${makeFloat(fee || price || 0)}</div>
              )}
              {packageItems.length > 0 && saving > 0 && (
                <div className={styles.serviceSaveTag}>
                  Includes package savings of ${makeFloat(saving)}
                </div>
              )}
            </div>
            {isSelected && !service.decision_tree && service.kind === 'concern' &&
              <div className={styles.serviceInputWrap}>
                <input
                  placeholder="Please describe your concern"
                  type="text"
                  value={service.additionalInfo}
                  onClick={event => event.stopPropagation(event)}
                  onKeyUp={event => event.preventDefault()}
                  onChange={(event) => {
                    const newState = chosenServices.map((s) => {
                      if (s.id === service.id) {
                        return { ...s, additionalInfo: event.target.value };
                      }

                      return s;
                    });

                    setChosenServices(newState);
                  }}
                  maxLength={200}
                />
                <p className={styles.serviceInputAdditionalInfo}>
                  {`${(chosenServices.find(s => s.id === service.id).additionalInfo || '').length}/200`}
                </p>
              </div>
            }
          </button>
          {packageItems.length > 0 && (
            <PackageItems
              items={packageItems}
              extras={extras}
              premium_extras={premiumExtras}
              isSelected={isSelected}
              disabled={isServiceDisabled(service)}
            />
          )}
        </div>
      </div>
    );
  };

  if (isExpanded) {
    return (
      <div className={styles.container}>
        <div className={styles.services}>
          {label === 'Concern' &&
            <div className={styles.alert}>
              <p className={styles.label}>
                Diagnostic fee may be waived if your vehicle is still under warranty.
              </p>
            </div>
          }
          {availableServices.map(service => (
            renderService(
              service,
              !!chosenServices.find(s => s.id === service.id),
              isServiceDisabled(service),
            )
          ))}
        </div>
        <div className={styles.submitContainer}>
          <Button
            className={styles.submit}
            caption={chosenServices.length > 0 ? 'Done' : 'Back'}
            onClick={handleSubmit}
            isWide
          />
        </div>
      </div>
    );
  }
  return (
    <button
      className={cx(styles.button, { [styles.highlighted]: !!chosenServices.length })}
      onClick={() => setIsExpanded(true)}
    >
      {!!chosenServices.length && (
        <div className={styles.counter}>
          {chosenServices.length}
        </div>
      )}
      <img alt={label} src={icon} />
      <span className={styles.caption}>{label}</span>
    </button>
  );
};

ServiceSelector.propTypes = {
  icon: string.isRequired,
  label: string.isRequired,
  servicesPriceVisible: bool.isRequired,
  availableServices: arrayOf(shape({
    id: number,
    name: string,
    package_items: arrayOf(shape({ id: number, name: string })),
  })).isRequired,
  selectedServices: arrayOf(shape({
    id: number,
    name: string,
    package_items: arrayOf(shape({ id: number, name: string })),
  })).isRequired,
  onSubmit: func.isRequired,
  onSetTimecodes: func.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
};

const actions = {
  onSetTimecodes: setTimecodes,
};

export default connect(null, actions)(ServiceSelector);
