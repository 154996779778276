import { Component } from 'react';
import { func, string, bool } from 'prop-types';
import Cable from 'actioncable';

class ActionCable extends Component {
  state = {
    cable: null,
    subscription: null,
  }

  componentDidMount() {
    if (!this.props.isTokenNeeded) {
      const cable = Cable.createConsumer(process.env.REACT_APP_CABLE_URL);
      this.setState({ cable }); // eslint-disable-line react/no-did-mount-set-state
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.authToken !== prevProps.authToken) {
      this.initializeCustomer(prevProps);
    } else {
      this.handleSubscriptions(prevProps);
    }
  }

  componentWillUnmount() {
    const { cable, subscription } = this.state;

    if (subscription) {
      cable.subscriptions.remove(subscription);
    }
  }

  handleReceive = data => this.props.onReceive(data);

  initializeCustomer(prevProps) {
    const actionCableUrl = `${process.env.REACT_APP_CABLE_URL}?access_token=${this.props.authToken}`;
    const cable = Cable.createConsumer(actionCableUrl);
    this.setState({
      cable,
    }, () => this.handleSubscriptions(prevProps));
  }

  handleSubscriptions(prevProps) {
    const { cable, subscription } = this.state;

    if (this.props.channelKey !== prevProps.channelKey) {
      if (subscription) {
        cable.subscriptions.remove(subscription);
      }
      const newSubscription = cable.subscriptions.create({
        channel: this.props.channel,
        channel_key: this.props.channelKey,
      }, {
        received: this.handleReceive,
      });
      this.setState({ subscription: newSubscription });
    }
  }

  render() { return null; }
}

ActionCable.propTypes = {
  channel: string,
  channelKey: string,
  authToken: string,
  onReceive: func.isRequired,
  isTokenNeeded: bool,
};


ActionCable.defaultProps = {
  channel: null,
  channelKey: null,
  authToken: null,
  isTokenNeeded: false,
};

export default ActionCable;
