import { connect } from 'react-redux';

import {
  chatLoadingSelector,
  chatAvailableServicesSelector,
  chatSelectedServicesSelector,
} from 'selectors/booking/chat-selectors';
import { appServicesPriceVisibleSelector, appMobileTechnicianEnabled } from 'selectors/booking/app-selectors';
import { confirmBooking, editBookingServices } from 'actions/booking/chat-actions';

import BookingSummaryStepInput from './BookingSummaryStepInput';

const mapStateToProps = state => ({
  isDisabled: chatLoadingSelector(state),
  selectedServices: chatSelectedServicesSelector(state),
  services: chatAvailableServicesSelector(state),
  servicesPriceVisible: appServicesPriceVisibleSelector(state),
  mobileTechnicianEnabled: appMobileTechnicianEnabled(state),
});

const actions = {
  onConfirm: confirmBooking,
  onSubmitServices: editBookingServices,
};

const BookingSummaryStepInputContainer =
  connect(mapStateToProps, actions)(BookingSummaryStepInput);

export default BookingSummaryStepInputContainer;
