import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import { chatPhoneNumberSelector } from 'selectors/booking/chat-selectors';

const CommunicationStep = ({
  phoneNumber, preferredPhoneNumber, isComplete, isAnimationFinished,
}) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Great! Is this the best number to send you SMS with booking confirmation?"
        source={SOURCE_CONCIERGE}
        isOpening
      />
      <AnimatedTextMessage
        delay={DELAY_1000}
        message={phoneNumber}
        source={SOURCE_CONCIERGE}
        isClosing
        timecodes={{ from: 197000, to: 202000 }}
      />
    </AnimationGroup>
    {isComplete && preferredPhoneNumber && (
      <AnimationGroup isComplete={isAnimationFinished}>
        {preferredPhoneNumber === phoneNumber
          ? <AnimatedTextMessage message="Yes." source={SOURCE_USER} />
          : <AnimatedTextMessage message={`No, use this one: ${preferredPhoneNumber}.`} source={SOURCE_USER} />
        }
      </AnimationGroup>
    )}
    {isComplete && !preferredPhoneNumber && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message="I don't want to receive SMS notifications."
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CommunicationStep.propTypes = {
  phoneNumber: string.isRequired,
  preferredPhoneNumber: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

CommunicationStep.defaultProps = {
  preferredPhoneNumber: '',
  isComplete: false,
  isAnimationFinished: false,
};

const mapStateToProps = state => ({
  phoneNumber: chatPhoneNumberSelector(state),
});

const CommunicationStepContainer = connect(mapStateToProps)(CommunicationStep);

export default CommunicationStepContainer;
