import React from 'react';
import cx from 'classnames';
import { number } from 'prop-types';

import maintenanceIcon from 'assets/images/maintenance.svg';
import concernIcon from 'assets/images/concern.svg';

import styles from './styles.scss';

const SelectedServicesMessage = ({ maintenanceCount, concernCount }) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <div className={cx(styles.button, { [styles.highlighted]: !!maintenanceCount })}>
        {!!maintenanceCount && (
          <div className={styles.counter}>
            {maintenanceCount}
          </div>
        )}
        <img alt="maintenance icon" src={maintenanceIcon} />
        <span className={styles.caption}>Maintenance</span>
      </div>
    </div>
    <div className={styles.wrapper}>
      <div className={cx(styles.button, { [styles.highlighted]: !!concernCount })}>
        {!!concernCount && (
          <div className={styles.counter}>
            {concernCount}
          </div>
        )}
        <img alt="concern icon" src={concernIcon} />
        <span className={styles.caption}>Concern</span>
      </div>
    </div>
  </div>
);

SelectedServicesMessage.propTypes = {
  maintenanceCount: number,
  concernCount: number,
};

SelectedServicesMessage.defaultProps = {
  maintenanceCount: 0,
  concernCount: 0,
};

export default SelectedServicesMessage;
