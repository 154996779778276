import React, { useEffect } from 'react';
import { func, number, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation, { SLIDE_UP } from 'components/common/Animation';
import { setTimecodes } from '../../../actions/booking/chat-actions';

import styles from './styles.scss';

const LoadingStateText = ({ delay, text, onSetTimecodes }) => {
  useEffect(() => {
    onSetTimecodes({ from: 0, to: 1000 });
  }, []);

  return (
    <AnimationGroup>
      <Animation type={SLIDE_UP} delay={delay}>
        <div className={styles.container}>
          <span>{text}</span>
        </div>
      </Animation>
    </AnimationGroup>
  );
};

LoadingStateText.propTypes = {
  delay: number,
  text: string,
  onSetTimecodes: func,
};

LoadingStateText.defaultProps = {
  delay: DELAY_500,
  text: '',
  onSetTimecodes: () => {},
};

const actions = {
  onSetTimecodes: setTimecodes,
};

export default connect(null, actions)(LoadingStateText);
