import * as moment from 'moment-timezone';
import { format } from 'date-fns';
import { percentageDiscount, sum, makeFloat, buildUrl } from 'shared/utils';

export const proactiveDealershipIdSelector = state => state.proactive.booking?.dealership?.id;
export const proactiveBookingIdSelector = state => state.proactive.booking.id;
export const proactiveIsAppointmentCreatedSelector = state => state.proactive.appointmentCreated;
export const proactiveCustomerSelector = state => state.proactive.customer;
export const proactiveVehicleSelector = state => state.proactive.vehicle;
export const proactiveDatetimeSelector = state => state.proactive.proactiveTime;
export const proactiveDealershipTimeZoneSelector = state => state.proactive.dealership.time_zone;
export const proactiveDealershipLocationSelector = state => ({
  lat: state.proactive.dealership.latitude,
  lng: state.proactive.dealership.longitude,
});
export const proactiveDealershipAddressSelector = state => state.proactive.dealership.address;
export const proactiveLoadingSelector = state => state.proactive.isLoading;
export const proactiveBookingSelector = (state) => {
  const services = state.proactive.booking.bookings_menu_items || [];
  const totalHours = services
    .reduce((total, service) => (total + +(service.allocated_labor_time || 0)), 0)
    .toFixed(1);
  const packageServices = (services || []).filter(s => s.package_items?.length > 0);
  const totalPriceBeforeDiscount = makeFloat(sum((services || []), 'price') + sum((packageServices || []), 'saving'));
  const totalPrice = makeFloat(sum((services || []), 'price'));
  const discount = percentageDiscount(totalPriceBeforeDiscount, totalPrice);
  const { first_name: name, last_name: lastName } = state.proactive.customer;
  const vehicle = state.proactive.currentVehicle;
  const vehicleYear = state.proactive.modelYear;
  const splitingDateTime = () => {
    const dateItems = state.proactive.proactiveTime.match(/\d+/g);
    const selectedDate = `${dateItems[0]}-${dateItems[1]}-${dateItems[2]}`;
    const selectedQuarterSlot = `${dateItems[3]}:${dateItems[4]}`;
    return `${selectedDate} ${selectedQuarterSlot}`;
  };
  const selectedTime = state.proactive.proactiveTime ? format(splitingDateTime(), 'dddd, MMMM D, YYYY, hh:mm A') : '';
  const tz = proactiveDealershipTimeZoneSelector(state);
  const momentDate = moment.tz(selectedTime, 'dddd, MMMM DD YYYY, h:mm A', tz);
  const momentUTCDate = moment.parseZone(momentDate.format()).utc();
  const dateIn = momentUTCDate.format('YYYYMMDD[T]HHmm00[Z]');
  const dateOut = momentUTCDate
    .add(totalHours, 'h')
    .format('YYYYMMDD[T]HHmm00[Z]');
  const { lat, lng } = proactiveDealershipLocationSelector(state);
  const address = proactiveDealershipAddressSelector(state);
  const location = `https://maps.google.com/?q=${lat},${lng}`;
  const calendarTitle = `Service reservation for ${vehicle} ${vehicleYear}`;
  const calendarText = `
  Time: ${selectedTime}
  Location: ${location}`;

  const calendarLink = buildUrl(
    dateIn,
    dateOut,
    calendarTitle,
    calendarText,
    address,
  );

  return {
    ...state.proactive.booking,
    services: (services || []).map(s => ({
      id: s.menu_item.id,
      name: s.name,
      price: s.price,
      package_items: s.package_items,
      extras: s.extras,
      premium_extras: s.premium_extras,
      saving: s.saving,
      kind: s.kind,
    })),
    totalPrice,
    totalHours,
    totalPriceBeforeDiscount,
    discount,
    customer: `${name || ''} ${lastName}`,
    vehicle: `${vehicle}, ${vehicleYear}`,
    selectedTime,
    calendarLink,
  };
};
export const proactivePhoneNumberSelector = state => state.proactive.phoneNumber;
export const proactiveResponsibleTeamTagSelector = state => (
  state.proactive.responsibleTeamTag
);
export const proactiveSelectedAdvisorSelector = state => (
  state.proactive.selectedAdvisor
);
export const proactiveAdvisorsSelector = state => (
  state.proactive.advisors
);
export const proactiveAdvisorsIdsSelector = state => (
  state.proactive.advisorsIds
);
export const proactiveCalendarLoadingSelector = state => (
  state.proactive.isCalendarLoading
);
export const proactiveErrorSelector = state => state.proactive.error;
export const proactiveDealershipNameSelector = state => state.proactive.dealership.name;
export const proactiveDealershipPhoneSelector = state => state.proactive.dealership.phone;
export const proactiveLocalizationEnabledSelector = state =>
  state.proactive.dealership.localization_for_customers_enabled;
export const proactiveServicesPriceVisibleSelector = state =>
  state.proactive.dealership.services_price_visible;
export const proactiveAvailableDatesSelector = (state) => {
  const availableDates = state.proactive.appointmentSlots;
  return (availableDates || []).map((slot, index) => ({
    id: index,
    shortDayOfTheWeek: slot.short_day_name,
    dayOfTheWeek: slot.day_name,
    dayOfTheMonth: slot.day_of_month,
    fullDate: slot.full_date,
    appointmentSlots: slot.slots,
  }));
};
export const proactiveAvailableDatesErrorSelector = state => state.proactive.appointmentSlotsError;
