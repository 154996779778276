import React, { useState, Fragment } from 'react';
import { number, bool, string } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { connect } from 'react-redux';
import {
  chatVehicleMileageSelector,
  chatCustomerNameSelector,
  chatSelectedSkipStepsToMilageSelector,
  makeModelYear,
} from 'selectors/booking/chat-selectors';
import { appDealershipNameSelector } from 'selectors/booking/app-selectors';
import { formatLabel } from 'shared/utils';

const MileageStep = ({
  name,
  carInformation,
  dealershipName,
  newMileage,
  previousMileage,
  isComplete,
  isAnimationFinished,
  skipStepsToMilage,
}) => {
  const [customerName] = useState(name);
  return (
    <Fragment>
      {skipStepsToMilage &&
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Hello ${customerName}! Welcome to ${dealershipName}. My name is Carmen!`}
          source={SOURCE_CONCIERGE}
        />
      </AnimationGroup>
    }
      {previousMileage ? (
        <AnimationGroup isComplete={isAnimationFinished}>
          {!isComplete && (
          <AnimatedTextMessage
            delay={DELAY_500}
            message={`On your last visit your car mileage was ${previousMileage}`}
            source={SOURCE_CONCIERGE}
            isOpening
          />
        )}
          <AnimatedTextMessage
            delay={DELAY_1000}
            message={`What is the approximate mileage right now for ${carInformation}?`}
            source={SOURCE_CONCIERGE}
            isClosing
            timecodes={{ from: 50000, to: 54000 }}
          />
        </AnimationGroup>
    ) : (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`What is the approximate mileage of your car ${formatLabel(carInformation)} right now?`}
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 50000, to: 54000 }}
        />
      </AnimationGroup>
    )}
      {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Current car mileage is ${newMileage}`}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  previousMileage: chatVehicleMileageSelector(state),
  name: chatCustomerNameSelector(state),
  dealershipName: appDealershipNameSelector(state),
  skipStepsToMilage: chatSelectedSkipStepsToMilageSelector(state),
  carInformation: makeModelYear(state),
});

MileageStep.propTypes = {
  name: string,
  carInformation: string,
  dealershipName: string.isRequired,
  previousMileage: number,
  newMileage: number,
  isComplete: bool,
  isAnimationFinished: bool,
  skipStepsToMilage: bool.isRequired,
};

MileageStep.defaultProps = {
  name: null,
  carInformation: null,
  previousMileage: null,
  newMileage: null,
  isComplete: false,
  isAnimationFinished: false,
};

export default connect(mapStateToProps)(MileageStep);
