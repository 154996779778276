import React, { Fragment } from 'react';
import { string, bool, oneOf } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { ID_METHOD_EMAIL, ID_METHOD_PHONE_NUMBER } from 'shared/constants';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CustomerIdentificationStep = ({
  previousMethod, email, phoneNumber, isComplete, isAnimationFinished,
}) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message={`Sorry, we couldn't find any accounts matching this ${previousMethod}.`}
        source={SOURCE_CONCIERGE}
        isOpening
        timecodes={{ from: 24000, to: 38000 }}
      />
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Is there any other way we could identify you? Or would you like to create a new account?"
        source={SOURCE_CONCIERGE}
        isClosing
        timecodes={{ from: 24000, to: 38000 }}
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Let's try ${phoneNumber || email}.`}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CustomerIdentificationStep.propTypes = {
  previousMethod: oneOf([ID_METHOD_EMAIL, ID_METHOD_PHONE_NUMBER]).isRequired,
  phoneNumber: string,
  email: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

CustomerIdentificationStep.defaultProps = {
  phoneNumber: '',
  email: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default CustomerIdentificationStep;
