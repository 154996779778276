import React from 'react';
import cx from 'classnames';
import { arrayOf, shape, func, string, number, bool } from 'prop-types';
import { imageUrl } from 'shared/utils';
import firstAvailableAdvisorIcon from 'assets/images/anyAdvisor.svg';
import firstAvailableAdvisorActiveIcon from 'assets/images/anyAdvisorActive.svg';

import checked from 'assets/images/services/service-checked.svg';

import styles from './styles.scss';

const AdvisorCarusel = ({
  serviceAdvisors,
  selectedAdvisor,
  showFirstAvailable,
  onClickHandler,
}) => (
  <div className={styles.advisorListContainer}>
    {showFirstAvailable && (
      <div
        className={cx(
          styles.advisorCard,
          styles.advisorCardWithSeparator,
          { [styles.advisorCardIsActive]: selectedAdvisor.id === 'firstAvailable' },
        )}
        role="button"
        tabIndex={0}
        onClick={() => onClickHandler(serviceAdvisors[0])}
      >
        <div className={styles.advisorCardImageContainer}>
          <img
            src={selectedAdvisor.id === 'firstAvailable'
              ? firstAvailableAdvisorActiveIcon
              : firstAvailableAdvisorIcon}
            alt=""
            className={styles.advisorCardIcon}
          />
          {selectedAdvisor.id !== 'firstAvailable' && <span className={styles.advisorCardStatus} />}
          {selectedAdvisor.id === 'firstAvailable' && (
            <img
              src={checked}
              alt=""
              className={styles.advisorCardStatusIsChecked}
            />
          )}
        </div>
        <span className={styles.advisorName}>First Available</span>
      </div>
    )}
    <div className={styles.advisorListItemsContainer}>
      {serviceAdvisors.map((serviceAdvisor) => {
        if (serviceAdvisor.id !== 'firstAvailable') {
          return (
            <div
              className={cx(styles.advisorCard, {
                [styles.advisorCardIsActive]: selectedAdvisor.id === serviceAdvisor.id,
              })}
              role="button"
              tabIndex={0}
              onClick={() => onClickHandler(serviceAdvisor)}
            >
              <div className={styles.advisorCardImageContainer}>
                {serviceAdvisor?.photo?.url &&
                  <img
                    src={imageUrl(serviceAdvisor.photo)}
                    alt=""
                    className={styles.advisorCardImage}
                  />
                }
                {selectedAdvisor.id !== serviceAdvisor.id &&
                  <span className={styles.advisorCardStatus} />
                }
                {selectedAdvisor.id === serviceAdvisor.id && (
                  <img
                    src={checked}
                    alt=""
                    className={styles.advisorCardStatusIsChecked}
                  />
                )}
              </div>
              <span className={styles.advisorName}>{serviceAdvisor.name}</span>
            </div>
          );
        }
        return null;
      })}
    </div>
  </div>
);

AdvisorCarusel.propTypes = {
  serviceAdvisors: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  })),
  selectedAdvisor: shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  }).isRequired,
  onClickHandler: func.isRequired,
  showFirstAvailable: bool,
};

AdvisorCarusel.defaultProps = {
  serviceAdvisors: [],
  showFirstAvailable: false,
};

export default AdvisorCarusel;
