import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { shape } from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { chatTimecodesSelector } from '../../../selectors/booking/chat-selectors';

import styles from './styles.scss';

const VideoPlayer = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      playerRef.current = videojs(videoElement, {
        ...props,
        controls: false,
        muted: false,
      });

      playerRef.current.ready(() => {
        playerRef.current.currentTime(props.timecodes.from / 1000);
        playerRef.current.play();

        const checkTimecode = () => {
          if (playerRef.current && playerRef.current.currentTime() >= props.timecodes.to / 1000) {
            playerRef.current.pause();
            playerRef.current.off('timeupdate', checkTimecode);
          }
        };

        playerRef.current.on('timeupdate', checkTimecode);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [videoRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentTime(props.timecodes.from / 1000);
      playerRef.current.play();

      const checkTimecode = () => {
        if (playerRef.current && playerRef.current.currentTime() >= props.timecodes.to / 1000) {
          playerRef.current.pause();
          if (playerRef.current) {
            playerRef.current.off('timeupdate', checkTimecode);
          }
        }
      };

      playerRef.current.on('timeupdate', checkTimecode);

      return () => {
        if (playerRef.current) {
          playerRef.current.off('timeupdate', checkTimecode);
        }
      };
    }
  }, [props.timecodes]);

  return (
    <div data-vjs-player className={styles.container}>
      <video
        ref={videoRef}
        className={`video-js vjs-fluid ${styles.video}`}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  timecodes: shape,
};

VideoPlayer.defaultProps = {
  timecodes: {
    from: 202000,
    to: 204000,
  },
};

const mapStateToProps = state => ({
  timecodes: chatTimecodesSelector(state),
});

export default connect(mapStateToProps, null)(VideoPlayer);
