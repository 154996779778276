import registerServiceWorker from 'utils/registerServiceWorker';
import MobileView from 'components/views/MobileView';

import bookingReducer from 'reducers/booking';

import initApp from './index';

initApp(MobileView, bookingReducer);

registerServiceWorker();
