import React, { useEffect, useRef } from 'react';
import { number, oneOf, string, bool, shape, func } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import Animation from 'components/common/Animation';
import TextMessage, { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import { setTimecodes } from '../../../actions/booking/chat-actions';

const AnimatedTextMessage = ({
  delay,
  source,
  message,
  isOpening,
  isClosing,
  isBig,
  isSpeaking,
  timecodes,
  onSetTimecodes,
  ...animationProps
}) => {
  const prevTimecodesRef = useRef();

  useEffect(() => {
    if (
      !prevTimecodesRef.current ||
      prevTimecodesRef.current.from !== timecodes.from ||
      prevTimecodesRef.current.to !== timecodes.to
    ) {
      onSetTimecodes(timecodes);
    }
    prevTimecodesRef.current = timecodes;
  }, [timecodes]);

  return (
    <Animation delay={delay} {...animationProps}>
      <TextMessage
        source={source}
        message={message}
        isOpening={isOpening}
        isClosing={isClosing}
        isBig={isBig}
        isSpeaking={isSpeaking}
        speechDelay={delay + DELAY_500} // DELAY_500 is the duration of most animations
      />
    </Animation>
  );
};

AnimatedTextMessage.propTypes = {
  delay: number,
  source: oneOf([SOURCE_CONCIERGE, SOURCE_USER]).isRequired,
  message: string.isRequired,
  isOpening: bool,
  isClosing: bool,
  isBig: bool,
  isSpeaking: bool,
  timecodes: shape,
  onSetTimecodes: func.isRequired,
};

AnimatedTextMessage.defaultProps = {
  delay: 0,
  isOpening: false,
  isClosing: false,
  isBig: false,
  isSpeaking: false,
  timecodes: {
    from: 202000,
    to: 204000,
  },
};

const actions = {
  onSetTimecodes: setTimecodes,
};

export default connect(null, actions)(AnimatedTextMessage);
