import { combineReducers } from 'redux';

import app from './app-reducer';
import chat from './chat-reducer';
import proactive from './proactive-reducer';

const reducers = combineReducers({
  app,
  chat,
  proactive,
});

export default reducers;
