import React, { useState } from 'react';
import { arrayOf, shape, number, string, bool } from 'prop-types';
import cx from 'classnames';
import { generate } from 'shortid';
import upArrow from 'assets/images/arrow/up.svg';
import downArrow from 'assets/images/arrow/down.svg';

import styles from './styles.module.scss';

const PackageItem = ({
  items,
  extras,
  isSelected,
  premium_extras: premiumExtras,
  disabled,
}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={cx(styles.container, {
        [styles.isSelected]: isSelected,
      })}
    >
      <div
        className={styles.header}
        onClick={() => {
          if (!disabled) {
            setIsShown(!isShown);
          }
        }}
        role="button"
        tabIndex="0"
      >
        <span> See operations included</span>
        <img
          className={cx(styles.arrow, {
            [styles.arrowDisabled]: disabled,
          })}
          src={isShown ? upArrow : downArrow}
          alt=""
        />
      </div>
      <div
        className={cx(styles.packageItems, {
          [styles.listIsShown]: isShown,
        })}
      >
        <div className={styles.body}>
          <div className={styles.list}>
            {premiumExtras.length > 0 && (
              <div className={styles.listTitle}>
                <span>
                  Premium services included:
                </span>
              </div>
            )}
            {premiumExtras.map(item => (
              <div className={styles.premiumItem} key={generate()}>
                <span>{item}</span>
              </div>
            ))}
            <div className={styles.listTitle}>
              <span>
                Services included:
              </span>
            </div>
            {items.map(item => (
              <div className={styles.item} key={item.id}>
                {item.name}
              </div>
            ))}
            {extras.map(item => (
              <div className={styles.item} key={generate()}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PackageItem.propTypes = {
  items: arrayOf(shape({ id: number, name: string, fee: number })).isRequired,
  extras: arrayOf(string),
  isSelected: bool,
  disabled: bool,
  premium_extras: arrayOf(string),
};

PackageItem.defaultProps = {
  extras: [],
  isSelected: false,
  disabled: false,
  premium_extras: [],
};

export default PackageItem;
