import { connect } from 'react-redux';

import { chatBookingSelector } from 'selectors/booking/chat-selectors';
import { updateBookingStatistics } from 'actions/booking/chat-actions';

import BookingSummaryStep from './BookingSummaryStep';

const mapStateToProps = state => ({
  booking: chatBookingSelector(state),
});

const actions = {
  updateStatistics: updateBookingStatistics,
};


const BookingSummaryStepContainer = connect(mapStateToProps, actions)(BookingSummaryStep);

export default BookingSummaryStepContainer;
