/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import cx from 'classnames';
import { ErrorBoundary, Provider } from '@rollbar/react';

import { BOOKING_CHANNEL } from 'shared/constants/action-cable';
import { initializeBooking, setBookingsSource } from 'actions/booking/chat-actions';
import { actionCableListener } from 'actions/booking/app-actions';
import { appCableChannelKeySelector } from 'selectors/booking/app-selectors';
import { dealershipStylesheetUrl } from 'shared/api';
import { isDevelopmentEnv } from 'shared/utils';

import ActionCable from 'components/common/ActionCable';
import ChatPanel from 'components/common/ChatPanel';
import HeaderBarMobile from 'components/common/HeaderBarMobile';
import InputBar from 'components/common/InputBar';

import videoFile from '../../../assets/video/avatar-stream.mp4';

import styles from './styles.scss';
import ProactiveView from './ProactiveView';
import VideoPlayer from '../../common/VideoPlayer';

const rollbarConfig = {
  accessToken: `${process.env.REACT_APP_ROLLBAR_TOKEN}`,
  environment: `${process.env.REACT_APP_ENV}`,
};

if (isDevelopmentEnv) {
  rollbarConfig.enabled = false;
}

class MobileView extends Component {
  state = {
    dealershipId: null,
    make: '',
    guid: null,
    videoShown: false,
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);

    const dealershipId = params.get('dealershipId');
    const phone = params.get('phone');
    const vehicleId = params.get('id');
    const customerApp = params.get('customerApp');
    const guid = params.get('guid');

    if (guid) {
      this.props.setSource('customer_proactive');
    }

    if (!guid) {
      this.props.setSource(customerApp ? 'customer_app' : 'customer');
      this.props.startBookingChat(dealershipId, false, phone, vehicleId);
    }

    const make = (params.get('make') || '').toLowerCase();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ dealershipId, make, guid });

    const existingGoogleMapsScript = document.querySelector('#googleMapsScript');

    if (!existingGoogleMapsScript) {
      const gmapScriptEl = document.createElement('script');
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,geometry&callback=initMap`;
      gmapScriptEl.id = 'googleMapsScript';
      document.querySelector('body').appendChild(gmapScriptEl);
    }

    const googleTranslateScript = document.createElement('script');

    // GOOGLE auto translation script
    googleTranslateScript.innerHTML = `
    \/* <![CDATA[ *\/\r\n      
    eval(function (p, a, c, k, e, r) {\r\n          
      e = function (c) {\r\n              
        return (c < a ? \'\' : e(parseInt(c \/ a))) + ((c = c % a) > 35 ? String.fromCharCode(c + 29) : c.toString(36))\r\n          
      };\r\n          
      if (!\'\'.replace(\/^\/, String)) {\r\n              
        while (c--) r[e(c)] = k[c] || e(c);\r\n              
        k = [function (e) {\r\n                  
          return r[e]\r\n              
        }];\r\n              
        e = function () {\r\n                  
          return \'\\\\w+\'\r\n              
        };\r\n              
        c = 1\r\n          
      }\r\n          
      while (c--) if (k[c]) p = p.replace(new RegExp(\'\\\\b\' + e(c) + \'\\\\b\', \'g\'), k[c]);\r\n          
      return p\r\n     
    }(\'6 7(a,b){n{4(2.9){3 c=2.9(\"o\");c.p(b,f,f);a.q(c)}g{3 c=2.r();a.s(\\\'t\\\'+b,c)}}u(e){}}6 h(a){4(a.8)a=a.8;4(a==\\\'\\\')v;3 b=a.w(\\\'|\\\')[1];3 c;3 d=2.x(\\\'y\\\');z(3 i=0;i<d.5;i++)4(d[i].A==\\\'B-C-D\\\')c=d[i];4(2.j(\\\'k\\\')==E||2.j(\\\'k\\\').l.5==0||c.5==0||c.l.5==0){F(6(){h(a)},G)}g{c.8=b;7(c,\\\'m\\\');7(c,\\\'m\\\')}}\', 43, 43, \'||document|var|if|length|function|GTranslateFireEvent|value|createEvent||||||true|else|doGTranslate||getElementById|google_translate_element|innerHTML|change|try|HTMLEvents|initEvent|dispatchEvent|createEventObject|fireEvent|on|catch|return|split|getElementsByTagName|select|for|className|goog|te|combo|null|setTimeout|500\'.split(\'|\'), 0, {}))\r\n      \/* ]]> *\/
    `;

    document.querySelector('body').appendChild(googleTranslateScript);

    const css = `
      body > .skiptranslate {
        display: none !important;
      }
      body > div#goog-gt-tt + script + div,
      body > .skiptranslate + script + div {
          display: none !important;
      }
    `;

    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }

  render() {
    const { actionCableReceiver, channelKey } = this.props;
    const { dealershipId, make } = this.state;

    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          {!this.state.guid && (
            <div
              id="mobile"
              className={cx(
              styles.container,
              'dealership-color-scheme-container',
              {
                'make-mazda': make === 'mazda',
              },
            )}
            >
              {dealershipId && (
              <link rel="stylesheet" type="text/css" href={dealershipStylesheetUrl(dealershipId)} />
              )}
              <Fragment>
                <HeaderBarMobile
                  toggleVideo={() => this.setState({ videoShown: !this.state.videoShown })}
                />
                <ChatPanel env="mobile" avatar={this.state.videoShown} />
                <InputBar />
                <ActionCable
                  onReceive={actionCableReceiver}
                  channel={BOOKING_CHANNEL}
                  channelKey={channelKey}
                />
              </Fragment>
              {this.state.videoShown && (
                <div className={styles.videoContainer}>
                  <VideoPlayer
                    sources={[
                      {
                        src: videoFile,
                        type: 'video/mp4',
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          )}
          {this.state.guid && (
            <ProactiveView
              guid={this.state.guid}
            />
          )}
        </ErrorBoundary>
      </Provider>
    );
  }
}

MobileView.propTypes = {
  startBookingChat: func.isRequired,
  actionCableReceiver: func.isRequired,
  setSource: func.isRequired,
  channelKey: string,
};

MobileView.defaultProps = {
  channelKey: null,
};

const actions = {
  startBookingChat: initializeBooking,
  actionCableReceiver: actionCableListener,
  setSource: setBookingsSource,
};

const mapStateToProps = state => ({
  channelKey: appCableChannelKeySelector(state),
});

const MobileViewContainer = connect(mapStateToProps, actions)(MobileView);

export default MobileViewContainer;
