import React, { useState, Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';
import {
  chatCustomerNameSelector,
  chatSelectedSkipStepsToCarSelectionSelector,
} from 'selectors/booking/chat-selectors';
import { appDealershipNameSelector } from 'selectors/booking/app-selectors';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { DELAY_500 } from 'shared/constants/delays';

const CarSelectionStep = ({
  name,
  dealershipName,
  selectedCarName,
  isComplete,
  isAnimationFinished,
  skipStepsToCarSelection,
}) => {
  const [customerName] = useState(name);

  return (
    <Fragment>
      {skipStepsToCarSelection &&
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Hello ${customerName}! Welcome to ${dealershipName}. My name is Carmen!`}
          source={SOURCE_CONCIERGE}
          timecodes={{ from: 12000, to: 19000 }}
        />
      </AnimationGroup>
    }
      {(!isComplete || (isComplete && selectedCarName)) && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          delay={DELAY_500}
          message="For which car would you like to book a visit?"
          source={SOURCE_CONCIERGE}
          isClosing
          timecodes={{ from: 12000, to: 19000 }}
        />
      </AnimationGroup>
    )}
      { isComplete && selectedCarName && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`For ${selectedCarName}.`}
          source={SOURCE_USER}
          isOpening
        />
      </AnimationGroup>
    )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  name: chatCustomerNameSelector(state),
  dealershipName: appDealershipNameSelector(state),
  skipStepsToCarSelection: chatSelectedSkipStepsToCarSelectionSelector(state),
});

CarSelectionStep.propTypes = {
  name: string,
  dealershipName: string.isRequired,
  selectedCarName: string,
  isComplete: bool,
  isAnimationFinished: bool,
  skipStepsToCarSelection: bool.isRequired,
};

CarSelectionStep.defaultProps = {
  name: null,
  selectedCarName: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default connect(mapStateToProps)(CarSelectionStep);
