import React, { Component } from 'react';
import cx from 'classnames';
import { func, string, bool } from 'prop-types';

import { extractPhoneNumberFromString } from 'shared/utils';
import {
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
  emailValidator,
} from 'shared/validators';
import sendIcon from 'assets/images/send.svg';
import Button from 'components/common/Button';

import styles from './styles.module.scss';

const MODE_OPTIONS = 'MODE_OPTIONS';
const MODE_PHONE_NUMBER = 'MODE_PHONE_NUMBER';
const MODE_EMAIL = 'MODE_EMAIL';

class CustomerIdentificationStepInput extends Component {
  state = {
    mode: MODE_OPTIONS,
    phoneNumber: '',
    email: '',
    valid: true,
  };

  resetState = () =>
    this.setState({
      mode: MODE_OPTIONS,
      phoneNumber: '',
      email: '',
      valid: true,
    });

  handleEmailChange = event => this.setState({ email: event.target.value });
  handlePhoneNumberChange = event =>
    this.setState({ phoneNumber: event.target.value });

  handlePhoneNumberSubmit = (event) => {
    event.preventDefault();
    const numberString = this.state.phoneNumber;
    let phoneNumber = null;
    if (phoneNumberFormatValidator(numberString)) {
      phoneNumber = extractPhoneNumberFromString(numberString);
    }
    if (
      phoneNumber &&
      phoneNumberLengthValidator(phoneNumber, this.props.dmsType)
    ) {
      this.resetState();
      this.props.onSubmit({ phoneNumber });
    } else {
      this.setState({ valid: false });
    }
  };

  handleEmailSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    if (email && emailValidator(email)) {
      this.resetState();
      this.props.onSubmit({ email });
    } else {
      this.setState({ valid: false });
    }
  };

  renderOptions = () => (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <div className={styles.searchType}>
          <Button
            isSecondary
            className={styles.smallButton}
            caption="Phone"
            onClick={() => this.setState({ mode: MODE_PHONE_NUMBER })}
          />
        </div>
        <div className={styles.searchType}>
          <Button
            isSecondary
            className={styles.smallButton}
            caption="Email"
            onClick={() => this.setState({ mode: MODE_EMAIL })}
          />
        </div>
      </div>
      <Button
        className={styles.smallButton}
        caption="New account"
        onClick={() =>
          this.props.onCreateAccount({
            phoneNumber: this.props.previousPhoneNumber,
          })
        }
      />
    </div>
  );

  renderPhoneNumberForm = () => {
    const { phoneNumber, valid } = this.state;

    return (
      <form
        className={styles.container}
        onSubmit={this.handlePhoneNumberSubmit}
      >
        <div className={styles.inputContainer}>
          <input
            autoFocus
            className={cx(styles.input, { [styles.invalid]: !valid })}
            value={phoneNumber}
            onChange={this.handlePhoneNumberChange}
          />
          <button className={styles.submit} onClick={this.handleSubmit}>
            <img alt="send icon" className={styles.icon} src={sendIcon} />
          </button>
        </div>
      </form>
    );
  };

  renderEmailForm = () => {
    const { email, valid } = this.state;

    return (
      <form className={styles.container} onSubmit={this.handleEmailSubmit}>
        <div className={styles.inputContainer}>
          <input
            autoFocus
            className={cx(styles.input, { [styles.invalid]: !valid })}
            value={email}
            onChange={this.handleEmailChange}
          />
          <button className={styles.submit} onClick={this.handleSubmit}>
            <img alt="send icon" className={styles.icon} src={sendIcon} />
          </button>
        </div>
      </form>
    );
  };

  render() {
    if (this.props.isComplete) return null;

    switch (this.state.mode) {
      case MODE_OPTIONS:
        return this.renderOptions();
      case MODE_PHONE_NUMBER:
        return this.renderPhoneNumberForm();
      case MODE_EMAIL:
        return this.renderEmailForm();
      default:
        return null;
    }
  }
}

CustomerIdentificationStepInput.propTypes = {
  isComplete: bool,
  onSubmit: func.isRequired,
  onCreateAccount: func.isRequired,
  dmsType: string.isRequired,
  previousPhoneNumber: string,
};

CustomerIdentificationStepInput.defaultProps = {
  isComplete: false,
  previousPhoneNumber: '',
};

export default CustomerIdentificationStepInput;
