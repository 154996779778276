import React, { Component } from 'react';
import { number, func, string, arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import InlineSVG from 'react-inlinesvg';
import Carousel from 'nuka-carousel';

import { DELAY_500 } from 'shared/constants/delays';
import { chatVehiclesSelector } from 'selectors/booking/chat-selectors';
import { selectCar, selectNewCar } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import leftIcon from 'assets/images/left.svg';
import rightIcon from 'assets/images/right.svg';
import Button from 'components/common/Button';
import VehicleButton from './VehicleButton';

import styles from './styles.module.scss';

class CarSelectionStepInput extends Component {
  state = {
    carouselIndex: 0,
    disabled: false,
    selectedVehicle: null,
    isNewVehicleSelected: false,
  }

  handleSubmit = () => {
    const { selectedVehicle, isNewVehicleSelected } = this.state;
    const { onSelectNewVehicle, onSelectExistingVehicle } = this.props;
    if (isNewVehicleSelected) {
      onSelectNewVehicle();
    } else {
      onSelectExistingVehicle(selectedVehicle);
    }
    this.setState({ disabled: true });
  }

  canSlideLeft = () => this.state.carouselIndex > 0;
  canSlideRight = () => this.state.carouselIndex < this.props.vehicles.length;

  slideLeft = () => {
    if (this.canSlideLeft()) {
      this.setState({
        carouselIndex: this.state.carouselIndex - 1,
      });
    }
  }

  slideRight = () => {
    if (this.canSlideRight()) {
      this.setState({
        carouselIndex: this.state.carouselIndex + 1,
      });
    }
  }

  selectExistingVehicle = selectedVehicle => () => (
    this.setState({
      isNewVehicleSelected: false,
      selectedVehicle,
    })
  );

  selectNewVehicle = () => (
    this.setState({
      isNewVehicleSelected: true,
      selectedVehicle: null,
    })
  );

  render() {
    const {
      disabled,
      carouselIndex,
      selectedVehicle,
      isNewVehicleSelected,
    } = this.state;

    if (disabled) { return null; }
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button
            onClick={this.slideLeft}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !this.canSlideLeft(),
            })}
          >
            <InlineSVG src={leftIcon} />
          </button>
          <button
            onClick={this.slideRight}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !this.canSlideRight(),
            })}
          >
            <InlineSVG src={rightIcon} />
          </button>
        </div>
        <Carousel
          slideIndex={carouselIndex}
          afterSlide={newIndex => this.setState({ carouselIndex: newIndex })}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={null}
          slideWidth="160px"
          framePadding="12px 0 0"
          cellSpacing={16}
        >
          {this.props.vehicles.map(vehicle => (
            <VehicleButton
              vehicle={vehicle}
              selectedVehicle={selectedVehicle}
              onClickHandler={this.selectExistingVehicle}
            />
          ))}
          <button
            className={cx(styles.newCarSlide, { [styles.selected]: isNewVehicleSelected })}
            onClick={this.selectNewVehicle}
          >
            + Add new car
          </button>
        </Carousel>
        {(selectedVehicle || isNewVehicleSelected) && (
          <Button
            className={styles.submit}
            caption="Confirm"
            onClick={this.handleSubmit}
            isWide
          />
        )}
      </div>
    );
  }
}

CarSelectionStepInput.propTypes = {
  onSelectExistingVehicle: func.isRequired,
  onSelectNewVehicle: func.isRequired,
  vehicles: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    year: number,
  })).isRequired,
};

const mapStateToProps = state => ({
  vehicles: chatVehiclesSelector(state),
});

const actions = {
  onSelectExistingVehicle: selectCar,
  onSelectNewVehicle: selectNewCar,
};

const CarSelectionStepInputContainer = connect(mapStateToProps, actions)(CarSelectionStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={DELAY_500}>
      <CarSelectionStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
