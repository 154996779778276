import {
  FETCH_PROACTIVE_APPOINTMENT_FAILURE,
  FETCH_PROACTIVE_APPOINTMENT_SUCCESS,
  SET_LOADING_STATE,
  SET_CALENDAR_LOADING_STATE,
  FETCH_PROACTIVE_TEAM_TAG_SUCCESS,
  FETCH_PROACTIVE_ADVISORS_SUCCESS,
  FETCH_PROACTIVE_ADVISORS_FAILURE,
  FETCH_PROACTIVE_APPOINTMENT_SLOTS_SUCCESS,
  FETCH_PROACTIVE_APPOINTMENT_SLOTS_FAILURE,
  CREATE_PROACTIVE_APPOINTMENT_SUCCESS,
  CREATE_PROACTIVE_APPOINTMENT_FAILURE,
  FETCH_PROACTIVE_TEAM_TAG_FAILURE,
  SET_PROACTIVE_DATETIME,
} from 'actions/booking/proactive-actions';

const initialState = {
  booking: {
    booking_menu_items: [],
  },
  customer: {},
  phoneNumber: null,
  vehicle: {},
  timeZone: null,
  servicesPriceVisible: false,
  error: null,
  isLoading: false,
  isCalendarLoading: true,
  responsibleTeamTag: [],
  advisors: [],
  advisorsIds: [],
  isAppointmentCreated: false,
  dealership: {
    id: 1,
    dmsType: 'dealer_track',
    latitude: null,
    localization_for_customers_enabled: false,
    longitude: null,
    name: null,
    phone: null,
    services_price_visible: false,
    time_zone: null,
  },
};

const proactive = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SET_CALENDAR_LOADING_STATE:
      return {
        ...state,
        isCalendarLoading: true,
        error: null,
      };
    case FETCH_PROACTIVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        booking: payload,
        customer: payload.customer,
        vehicle: payload.vehicle,
        currentVehicle: `${payload.vehicle.vehicle_set.make} ${payload.vehicle.vehicle_set.model}`,
        modelYear: payload.vehicle.vehicle_set.model_year,
        dealership: payload.dealership,
        appointmentCreated: payload.appointment_created,
        error: null,
        proactiveTime: payload.appointment_datetime,
      };
    case FETCH_PROACTIVE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error.message,
      };
    case FETCH_PROACTIVE_TEAM_TAG_SUCCESS:
      return {
        ...state,
        responsibleTeamTag: payload,
      };
    case FETCH_PROACTIVE_TEAM_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error.response.data.errors,
      };
    case FETCH_PROACTIVE_ADVISORS_SUCCESS:
      return {
        ...state,
        advisors: payload.advisors,
        advisorsIds: payload.advisors.map(advisor => advisor.id),
        error: null,
        isLoading: false,
      };
    case FETCH_PROACTIVE_ADVISORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error.response.data.errors,
      };
    case FETCH_PROACTIVE_APPOINTMENT_SLOTS_SUCCESS:
      return {
        ...state,
        appointmentSlots: payload,
        isCalendarLoading: false,
        error: null,
      };
    case FETCH_PROACTIVE_APPOINTMENT_SLOTS_FAILURE:
      return {
        ...state,
        isCalendarLoading: false,
        error: payload.error.response.data.errors,
      };
    case CREATE_PROACTIVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        appointmentCreated: true,
        error: null,
      };
    case CREATE_PROACTIVE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        appointmentCreated: false,
        error: payload.error.response.data.errors,
      };
    case SET_PROACTIVE_DATETIME:
      return {
        ...state,
        proactiveTime: payload,
      };
    default:
      return state;
  }
};

export default proactive;
