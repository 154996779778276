import React, { Component } from 'react';
import { connect } from 'react-redux';
import { oneOf, bool, string, number, func } from 'prop-types';
import cx from 'classnames';

import { speak } from 'actions/visit/voice-actions';

import styles from './styles.scss';

export const SOURCE_CONCIERGE = 'SOURCE_CONCIERGE';
export const SOURCE_USER = 'SOURCE_USER';

const renderMessage = (message, { isOpening, isClosing, isBig } = {}) => (
  <div
    className={cx(isBig ? styles.bigMessage : styles.message, {
      [styles.opening]: isOpening,
      [styles.closing]: isClosing,
    })}
  >
    {message}
  </div>
);

class TextMessage extends Component {
  componentDidMount() {
    const {
      isSpeaking, onSpeak, message, speechDelay,
    } = this.props;
    if (isSpeaking) { onSpeak(message, speechDelay); }
  }

  render() {
    const {
      source,
      message,
      isOpening,
      isClosing,
      isBig,
    } = this.props;

    return (
      source === SOURCE_CONCIERGE
        ? (
          <div className={styles.container}>
            {renderMessage(message, { isOpening, isClosing, isBig })}
          </div>
        ) : (
          <div className={cx(styles.container, styles.user)}>
            {renderMessage(message, { isBig })}
          </div>
        )
    );
  }
}

TextMessage.propTypes = {
  source: oneOf([SOURCE_CONCIERGE, SOURCE_USER]).isRequired,
  message: string.isRequired,
  isOpening: bool,
  isClosing: bool,
  isBig: bool,
  isSpeaking: bool,
  speechDelay: number,
  onSpeak: func.isRequired,
};

TextMessage.defaultProps = {
  isOpening: false,
  isClosing: false,
  isBig: false,
  speechDelay: 0,
  isSpeaking: false,
};

const actions = {
  onSpeak: speak,
};

const TextMessageContainer = connect(null, actions)(TextMessage);

export default TextMessageContainer;
