import React from 'react';
import { connect } from 'react-redux';

import { DELAY_1000 } from 'shared/constants/delays';
import { createCustomer } from 'actions/booking/chat-actions';
import { appDealershipDmsTypeSelector } from 'selectors/booking/app-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';

import CustomerCreationStepInput from './CustomerCreationStepInput';

const mapStateToProps = state => ({
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onSubmit: createCustomer,
};

const CustomerCreationStepInputContainer =
  connect(mapStateToProps, actions)(CustomerCreationStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup isComplete={props.isAnimationFinished}>
    <Animation delay={DELAY_1000}>
      <CustomerCreationStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
