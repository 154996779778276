import React, { Fragment } from 'react';
import { string, bool, number, arrayOf, shape } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import SelectedServicesMessage from 'components/common/SelectedServicesMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const ServiceSelectionStep = ({ selectedServices, isComplete, isAnimationFinished }) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message="What would you like to book your car for?"
        source={SOURCE_CONCIERGE}
        isOpening
        timecodes={{ from: 54000, to: 59000 }}
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <Animation>
          <SelectedServicesMessage
            maintenanceCount={selectedServices.maintenance.length}
            concernCount={selectedServices.concern.length}
          />
        </Animation>
        <AnimatedTextMessage
          delay={DELAY_500}
          message="I'd like the above services."
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

ServiceSelectionStep.propTypes = {
  selectedServices: shape({
    maintenance: arrayOf(shape({ id: number, name: string })),
    concern: arrayOf(shape({ id: number, name: string })),
  }),
  isComplete: bool,
  isAnimationFinished: bool,
};

ServiceSelectionStep.defaultProps = {
  selectedServices: {
    maintenance: [],
    concern: [],
  },
  isComplete: false,
  isAnimationFinished: false,
};

export default ServiceSelectionStep;
