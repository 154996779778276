import React from 'react';
import cx from 'classnames';
import { func, arrayOf, string, bool, number } from 'prop-types';
import { timeInUSFormat } from 'shared/timeSlotUtils';

import './styles.module.scss';

const TimePicker = ({
  slots, timeArr, time, setTime, isHoursOnly, height,
}) => (
  <div
    className={cx('timePicker', { isEmpty: slots.length === 0 })}
    style={{ height: `${height}px` }}
  >
    <div className="timePickerView">
      {slots.length === 0 && (
        <div className="textContainer">
          <p>Please select a date</p>
        </div>
      )}
      {slots.map((timeSlot) => {
        if (isHoursOnly && +timeSlot.slice(-2) !== 0) {
          return null;
        }
        return (
          <button
            type="button"
            key={timeSlot}
            className={cx('timePickerViewBtn', 'btn', 'notranslate', {
              active: timeSlot === time,
            })}
            onClick={() => {
              setTime(timeSlot);
            }}
            disabled={!timeArr.find(t => t === timeSlot)}
          >
            {timeInUSFormat(timeSlot)}
          </button>
        );
      })}
    </div>
  </div>
);

TimePicker.propTypes = {
  slots: arrayOf(string).isRequired,
  timeArr: arrayOf(string).isRequired,
  time: string,
  setTime: func.isRequired,
  isHoursOnly: bool,
  height: number.isRequired,
};

TimePicker.defaultProps = {
  time: null,
  isHoursOnly: false,
};

export default TimePicker;
