import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  arrayOf,
  bool, func,
  number,
  shape,
  string,
} from 'prop-types';
import { appointmentSlotPropType } from 'shared/constants/prop-types';
import {
  initializeProactiveBooking,
  fetchBookingSlots,
  createAppointment,
  updateBookingStatistics,
} from 'actions/booking/proactive-actions';
import { setIsWaiting } from 'actions/booking/chat-actions';
import {
  proactiveAvailableDatesSelector,
  proactiveErrorSelector,
  proactiveLoadingSelector,
  proactiveBookingSelector,
  proactiveServicesPriceVisibleSelector,
  proactiveDealershipTimeZoneSelector,
  proactiveCalendarLoadingSelector,
  proactiveAdvisorsSelector,
  proactiveAdvisorsIdsSelector,
  proactiveDealershipNameSelector,
  proactiveDealershipPhoneSelector,
  proactiveIsAppointmentCreatedSelector,
  proactiveLocalizationEnabledSelector,
} from 'selectors/booking/proactive-selectors';
import spinnerIcon from 'assets/images/spinner.svg';
import HeaderBarMobile from 'components/common/HeaderBarMobile';
import DateSelection from 'components/common/DateSelection';
import BookingSummary from 'components/common/BookingSummary';

import styles from './styles.module.scss';

const ProactiveView = ({
  error,
  initializeBooking,
  onSubmit,
  isLoading,
  booking,
  isServicesPriceVisible,
  guid,
  timeZone,
  isCalendarLoading,
  advisors,
  advisorIds,
  updateCalendarView,
  availableDates,
  dealershipName,
  dealershipPhone,
  isAppointmentCreated,
  updateStatistics,
  setWaiting,
  localizationEnabled,
}) => {
  useEffect(() => {
    initializeBooking(guid);
  }, []);

  return (
    <div className={styles.page}>
      <HeaderBarMobile
        proactiveLocalizationEnabled={localizationEnabled}
        dealershipPhone={dealershipPhone}
        dealershipName={dealershipName}
      />
      {error && !isLoading && (
        <div className={styles.alert}>
          <p>{error}</p>
        </div>
      )}
      {isAppointmentCreated && (
        <div className={cx(styles.alert, styles.alertSuccess)}>
          <p>The appointment was successfully created</p>
        </div>
      )}
      {!error && !isLoading && (
        <div>
          <BookingSummary
            isProactive
            isServicesPriceVisible={isServicesPriceVisible}
            booking={booking}
            updateStatistics={updateStatistics}
          />
          {!isAppointmentCreated && (
            <DateSelection
              isProactive
              availableDates={availableDates}
              advisors={advisors}
              advisorIds={advisorIds}
              phantomAdvisorAvailable={false}
              phantomAdvisorIds={[]}
              timeZone={timeZone}
              onSubmit={onSubmit}
              updateCalendarView={updateCalendarView}
              loadingState={isCalendarLoading}
              updateStatistics={updateStatistics}
              setWaiting={setWaiting}
            />
          )}
        </div>
      )}
      {isLoading && (
        <div className={styles.loading}>
          <img alt="loading" className={styles.loadingIcon} src={spinnerIcon} />
        </div>
      )}
    </div>
  );
};

ProactiveView.propTypes = {
  error: string,
  initializeBooking: func.isRequired,
  updateCalendarView: func.isRequired,
  updateStatistics: func.isRequired,
  onSubmit: func.isRequired,
  setWaiting: func.isRequired,
  isLoading: bool.isRequired,
  localizationEnabled: bool.isRequired,
  isCalendarLoading: bool.isRequired,
  isServicesPriceVisible: bool.isRequired,
  isAppointmentCreated: bool,
  booking: shape.isRequired,
  guid: string.isRequired,
  dealershipName: string,
  dealershipPhone: string,
  timeZone: string.isRequired,
  advisors: arrayOf(shape).isRequired,
  advisorIds: arrayOf[number],
  availableDates: arrayOf(appointmentSlotPropType).isRequired,
};

ProactiveView.defaultProps = {
  error: null,
  advisorIds: [],
  dealershipName: '',
  dealershipPhone: '',
  isAppointmentCreated: false,
};

const mapStateToProps = state => ({
  error: proactiveErrorSelector(state),
  isLoading: proactiveLoadingSelector(state),
  booking: proactiveBookingSelector(state),
  isServicesPriceVisible: proactiveServicesPriceVisibleSelector(state),
  timeZone: proactiveDealershipTimeZoneSelector(state),
  availableDates: proactiveAvailableDatesSelector(state),
  advisorIds: proactiveAdvisorsIdsSelector(state),
  advisors: proactiveAdvisorsSelector(state),
  isCalendarLoading: proactiveCalendarLoadingSelector(state),
  dealershipName: proactiveDealershipNameSelector(state),
  dealershipPhone: proactiveDealershipPhoneSelector(state),
  isAppointmentCreated: proactiveIsAppointmentCreatedSelector(state),
  localizationEnabled: proactiveLocalizationEnabledSelector(state),
});

const actions = {
  initializeBooking: initializeProactiveBooking,
  onSubmit: createAppointment,
  updateCalendarView: fetchBookingSlots,
  updateStatistics: updateBookingStatistics,
  setWaiting: setIsWaiting,
};

export default connect(mapStateToProps, actions)(ProactiveView);

