import { connect } from 'react-redux';
import { chatAvailableServicesSelector } from 'selectors/booking/chat-selectors';
import { appServicesPriceVisibleSelector, appMobileTechnicianEnabled } from 'selectors/booking/app-selectors';
import { selectServices, updateBookingStatistics } from 'actions/booking/chat-actions';

import ServiceSelectionStepInput from './ServiceSelectionStepInput';

const mapStateToProps = state => ({
  services: chatAvailableServicesSelector(state),
  servicesPriceVisible: appServicesPriceVisibleSelector(state),
  mobileTechnicianEnabled: appMobileTechnicianEnabled(state),
});

const actions = {
  onSubmit: selectServices,
  updateStatistics: updateBookingStatistics,
};

const ServiceSelectionStepInputContainer =
  connect(mapStateToProps, actions)(ServiceSelectionStepInput);

export default ServiceSelectionStepInputContainer;
