const DEVELOPMENT = 'development';
const LINE_LENGTH = 36;
const US_PHONE_NUMBER_REGEX = /(\d{3})(\d{3})(\d{4})/;
const PL_PHONE_NUMBER_REGEX = /(\d{3})(\d{3})(\d{3})/;
const US_PHONE_LENGTH = 10;
const PHONE_NUMBER_FORMAT = '$1-$2-$3';

export const minutesFromTimeRange = (timeRange) => {
  if (timeRange && timeRange.length) {
    return timeRange[0] === timeRange[1] ? timeRange[0] : `${timeRange[0]} - ${timeRange[1]}`;
  }
  return 2;
};

export const toFixedPrecision = (number, precision) =>
  Math.round(number * (10 ** precision)) / (10 ** precision);

export const isNotEmpty = object =>
  Object.keys(object).length > 0;

export const isDevelopmentEnv = process.env.REACT_APP_ENV === DEVELOPMENT;

export const shortenString = (str, maxLen = LINE_LENGTH, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const remove = (array, arrayToRemove) => {
  (arrayToRemove || []).map((item) => {
    const index = array.indexOf(item);
    if (index !== -1) { array.splice(index, 1); }
    return array;
  });

  return array;
};

export const percentageDiscount = (beforePrice, afterPrice) =>
  (beforePrice !== 0 ? (((beforePrice - afterPrice) / beforePrice) * 100).toFixed(0) : 0);

export const sum = (array, attribute) =>
  +array.reduce((total, item) => Number(total) + Number(item[attribute] || 0), 0).toFixed(2);

export const makeFloat = n => parseFloat(n).toFixed(2);

export const formatPhoneNumber = (phoneNumber) => {
  const phoneString = phoneNumber.toString();
  const usedRegex =
    phoneString.length === US_PHONE_LENGTH ? US_PHONE_NUMBER_REGEX : PL_PHONE_NUMBER_REGEX;

  return phoneString.replace(usedRegex, PHONE_NUMBER_FORMAT);
};

export const extractPhoneNumberFromString = phoneNumberString => (
  [...phoneNumberString.match(/\d+/g)].join('')
);

export const imageUrl = (image) => {
  if (!image || !image?.url) {
    return '';
  }

  if (image.url.startsWith('http')) {
    return image.url;
  }

  return `${process.env.REACT_APP_ROOT_URL}${image.url}`;
};

export const toStructuredForSelect = arr => arr.map(item => (
  { label: String(item).replace(/\b\w/g, l => l.toUpperCase()), value: item }
));

export const formatLabel = value => (
  value ? String(value).replace(/\b\w/g, l => l.toUpperCase()) : null
);

export const getMostSeniourTeamTag = (team) => {
  if (!team.length) return null;
  return team.sort((a, b) => a.seniority - b.seniority)[0];
};
export const isMobile = () => {
  let mobile = false;

  /*eslint-disable*/
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      mobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);

  /* eslint-enable */
  return mobile;
};

export const isIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const buildUrl = (dateIn, dateOut, title, description, location) => {
  let calendarUrl = '';

  if (isMobile() && isIos()) {
    calendarUrl = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`,
      `DTSTART:${dateIn}`,
      `DTEND:${dateOut}`,
      `SUMMARY:${title}`,
      `DESCRIPTION:${description}`,
      `LOCATION:${location}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    calendarUrl = encodeURI(`data:text/calendar;charset=utf8,${calendarUrl}`);
  } else {
    calendarUrl = 'https://calendar.google.com/calendar/render';
    calendarUrl += '?action=TEMPLATE';
    calendarUrl += `&dates=${dateIn}`;
    calendarUrl += `/${dateOut}`;
    calendarUrl += `&location=${encodeURIComponent(location)}`;
    calendarUrl += `&text=${encodeURIComponent(title)}`;
    calendarUrl += `&details=${encodeURIComponent(description)}`;
  }

  return calendarUrl;
};
