import React, { useEffect } from 'react';
import { bool, number, string, arrayOf, shape, func } from 'prop-types';
import cx from 'classnames';
import { formatLabel, makeFloat, imageUrl, isMobile } from 'shared/utils';
import PackageItems from 'components/common/PackageItems';
import calendar from 'assets/images/calendar.png';

import styles from './styles.module.scss';

const containsPackageAndSavings = services => services.some((s =>
  s.package_items?.length !== 0 && s.saving > 0
));

const BookingSummary = ({
  booking,
  dealershipId,
  isServicesPriceVisible,
  isPickup,
  isMobileTechnician,
  employee,
  isProactive,
  updateStatistics,
}) => {
  useEffect(() => {
    updateStatistics({
      confirmation_shown: true,
    });
  }, []);

  const employeeLabel = () => {
    if (isPickup) {
      return 'Your driver for pick-up:';
    }

    if (isMobileTechnician) {
      return 'Your mobile technician:';
    }

    return 'Your service consultant:';
  };

  const employeeName = () => {
    if (isPickup || isMobileTechnician) {
      return employee?.name;
    }

    return booking?.advisor?.name;
  };

  const employeePhoto = () => {
    if (employee && (isPickup || isMobileTechnician)) {
      return imageUrl(employee?.photo);
    }

    return imageUrl(booking?.advisor?.photo);
  };

  const handleCalendarOpen = () => {
    if (
      !isMobile() &&
      (booking.calendarLink.startsWith('data') || booking.calendarLink.startsWith('BEGIN'))
    ) {
      const filename = 'download.ics';
      const blob = new Blob([booking.calendarLink], {
        type: 'text/calendar;charset=utf-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(booking.calendarLink, '_blank');
    }
  };

  return (
    <div className={styles.container} >
      {!isProactive && (
        <div className={styles.header}>Booking summary</div>
      )}
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <span className={styles.customer}>{booking.customer}</span>
          <span className={styles.vehicle}>{formatLabel(booking.vehicle)}</span>
          <span className={styles.vehicle}>{booking.selectedTime}</span>
        </div>
        {isProactive && (booking.selectedTime || booking.appointment_datetime) && (
          <div
            className={styles.calendar}
            onClick={() => handleCalendarOpen()}
            role="button"
            tabIndex={0}
          >
            <img
              className={styles.icon}
              src={calendar}
              alt=""
            />
            <div className={styles.caption}>Add to Calendar</div>
          </div>
        )}
      </div>
      {!isProactive && (
        <div className={styles.advisorContainer}>
          <div className={styles.advisorPhotoContainer}>
            {booking?.advisor?.photo && (
              <img
                alt=""
                src={employeePhoto()}
                className={styles.advisorPhoto}
              />
            )}
          </div>
          <div className={styles.advisorInfo}>
            <span className={styles.advisorText}>{employeeLabel()}</span>
            <span className={styles.advisorName}>{employeeName()}</span>
          </div>
        </div>
      )}
      {isProactive && (
        <div className={styles.header}>Offered services</div>
      )}
      {booking.services.map(({
        id,
        name,
        extras,
        price,
        saving,
        package_items: packageItems,
        premium_extras: premiumExtras,
        }) => {
          const hasPackageItems = packageItems && packageItems.length > 0;
          return (
            <div key={id} className={styles.service}>
              <div className={styles.serviceWrap}>
                <div className={styles.serviceBody}>
                  {hasPackageItems && saving > 0 && isServicesPriceVisible && (
                  <div className={styles.serviceRow}>
                    <div className={styles.serviceSavetag}>
                      Includes package savings of ${saving}
                    </div>
                  </div>)}
                  <div className={styles.serviceRow}>
                    <span className={styles.serviceName}>{name}</span>
                    <div className={styles.priceContainer}>
                      {hasPackageItems && saving > 0 && isServicesPriceVisible && (
                      <span className={styles.packageItemsPrice}>
                        ${makeFloat(Number(price) + Number(saving))}
                      </span>)}
                      {isServicesPriceVisible && (
                        <span className={styles.price}>${makeFloat(price)}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {hasPackageItems &&
                <PackageItems
                  items={packageItems}
                  extras={extras}
                  premium_extras={premiumExtras}
                />
              }
            </div>
          );
        })}
      <div className={styles.summary}>
        {isServicesPriceVisible && (
          <div>
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>
              <div className={styles.totalPriceContainer}>
                <div>
                  {containsPackageAndSavings(booking.services) && (
                    <span className={cx(styles.totalValue, styles.totalPackageItemsValue)}>
                      ${makeFloat(booking.totalPriceBeforeDiscount)}
                    </span>
                  )}
                  <span className={styles.totalValue}>${makeFloat(booking.totalPrice)}</span>
                </div>
              </div>
            </div>
            <div className={styles.summaryBlock}>
              <span className={styles.taxInfo}>Plus applicable fees and taxes</span>
            </div>
            {(dealershipId === 57 || dealershipId === 58) &&
              <div className={styles.summaryBlock}>
                <span className={styles.disclaimerInfo}>
                  (price may vary based on engine size)
                </span>
              </div>
            }
          </div>
        )}
        {booking.services.some(s => s.kind === 'concern') && (
          <div className={styles.summaryBlock}>
            <span className={styles.taxInfo}>
              Diagnostic fee may be waived if your vehicle is still under warranty
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

BookingSummary.propTypes = {
  dealershipId: number.isRequired,
  booking: shape({
    customer: string.isRequired,
    vehicle: string.isRequired,
    services: arrayOf(shape({
      id: number.isRequired,
      name: string.isRequired,
      price: number.isRequired,
      package_items: arrayOf(shape({ id: number, name: string, fee: number })),
    })),
    advisor: shape({
      id: number.isRequired,
      name: string.isRequired,
      photo: shape({
        url: string.isRequired,
      }),
    }).isRequired,
    totalPrice: string.isRequired,
    totalPriceBeforeDiscount: string.isRequired,
    discount: string.isRequired,
    totalTime: string.isRequired,
  }).isRequired,
  isServicesPriceVisible: bool.isRequired,
  isPickup: bool,
  isMobileTechnician: bool,
  employee: shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: shape({
      url: string.isRequired,
    }),
  }),
  isProactive: bool,
  updateStatistics: func.isRequired,
};

BookingSummary.defaultProps = {
  employee: null,
  isPickup: false,
  isMobileTechnician: false,
  isProactive: false,
};

export default BookingSummary;
