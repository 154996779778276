import { format, isAfter } from 'date-fns';
import { percentageDiscount, sum, makeFloat } from 'shared/utils';
import { FINAL_STEP } from 'shared/constants/booking-steps';

export const chatHistorySelector = state => state.chat.history;
export const chatStepHistorySelector = state => state.chat.stepHistory;
export const chatIsStepUndoableSelector = (state) => {
  const { stepHistory } = state.chat;
  return stepHistory.length > 1 && stepHistory[stepHistory.length - 1] !== FINAL_STEP;
};

export const chatLoadingSelector = state => state.chat.loadingState;
export const chatInputSelector = state => state.chat.currentInput;
export const chatVehiclesSelector = state => state.chat.vehicles;
export const chatResponsibleTeamTagSelector = state => state.chat.responsibleTeamTag;
export const chatMakeModelYearMapSelector = state => state.chat.makeModelYearMap;
export const chatAvailableServicesSelector = state => state.chat.availableServices;
export const chatSelectedServicesSelector = state => state.chat.selectedServices;
export const chatAvailableRecallsSelector = state => state.chat.availableRecalls;
export const chatAvailableTransportsSelector = state => state.chat.availableTransports;
export const chatSelectedTransportSelector = state => state.chat.selectedTransport;
export const chatIsPickUp = state => state.chat.isPickUp;
export const chatIsDropOff = state => state.chat.isDropOff;
export const chatIsMobileTechnician = state => state.chat.isMobileTechnician;
export const chatSelectedRecallsSelector = state => state.chat.selectedRecalls;
export const chatAvailableAdvisorsSelector = state => state.chat.availableAdvisors
  .sort((a, b) => a.name.localeCompare(b.name));
export const chatSelectedAdvisorIds = state => state.chat.selectedAdvisorIds;
export const chatProgressSelector = state => state.chat.progress;
export const chatIdentificationMethodSelector = state => state.chat.identificationMethod;
export const chatPhoneNumberSelector = state => state.chat.currentCustomer.phoneNumber;
export const chatCustomerAddressSelector = state => state.chat.currentCustomer.address;
export const chatCustomerAddressDataSelector = ({
  chat: {
    currentCustomer: {
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZipcode,
    },
  },
}) => ({
  address_line1: addressLine1,
  address_line2: addressLine2,
  address_city: addressCity,
  address_state: addressState,
  address_zipcode: addressZipcode,
});
export const chatCustomerLastAddressSelector = (state) => {
  if (!state.chat.currentCustomer.lastAddress) return null;
  const {
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_city: addressCity,
    address_state: addressState,
    address_zipcode: addressZipcode,
  } = state.chat.currentCustomer.lastAddress;

  return [addressLine1, addressLine2, addressCity, addressZipcode, addressState].filter(v => v).join(' ');
};

export const chatCustomerLastAddressDataSelector = ({
  chat: {
    currentCustomer: {
      lastAddress,
    },
  },
}) => {
  if (!lastAddress || !lastAddress.address_line1) return null;
  return lastAddress;
};

export const chatSelectedAllCustomersSelector = state => state.chat.allCustomers;
export const chatSelectedOtherCustomersSelector = state => state.chat.otherCustomers;
export const chatCurrentCustomerSelector = state => state.chat.currentCustomer;
export const chatCustomerNameSelector = state => state.chat.currentCustomer.name;
export const chatCustomerTypeSelector = state => state.chat.currentCustomer.customerType;
export const chatCurrentVehicleIdSelector = state => state.chat.currentVehicle.id;
export const chatCurrentVehicleSelector = state => state.chat.currentVehicle;
export const chatVehicleMileageSelector = state => state.chat.currentVehicle.mileage;
export const chatLoadingStateDelaySelector = state => state.chat.loadingStateDelay;
export const chatLastRequestSelector = state => state.chat.lastRequest;
export const chatPreferredAddressSelector = state => state.chat.preferredAddress;
export const chatPreferredAddressDataSelector = state => state.chat.preferredAddressData;
export const chatPreferredPhoneNumberSelector = state => state.chat.preferredPhoneNumber;
export const chatJobTypeSelector = state => state.chat.jobType;
export const chatTimecodesSelector = state => state.chat.timeCodes;
export const chatJobNotesSelector = state => state.chat.jobNotes;
export const chatSelectedJobDateTimeSelector = state => state.chat.selectedJobDateTime;
export const chatCreatedJobsSelector = state => state.chat.createdJobs;
export const chatSelectedDateTimeSelector = state => state.chat.selectedDateTime;
export const chatAllSelectedServicesSelector = (state) => {
  const { maintenance, concern } = state.chat.selectedServices;
  return maintenance.concat(concern);
};

export const chatBookingSelector = (state) => {
  const { name, lastName } = state.chat.currentCustomer;
  const { make, model, year } = state.chat.currentVehicle;
  const { maintenance, concern } = state.chat.selectedServices;
  const { selectedRecalls } = state.chat;
  const services = maintenance.concat(concern).concat(selectedRecalls || []);
  const totalHours = services
    .reduce((total, service) => (total + +(service.allocated_labor_time || 0)), 0)
    .toFixed(1);
  const timeLabel = totalHours > 1 ? 'hours' : 'hour';
  const packageServices = services.filter(s => s.package_items.length > 0);
  const totalPriceBeforeDiscount = makeFloat(sum(services, 'fee') + sum(packageServices, 'saving'));
  const totalPrice = makeFloat(sum(services, 'fee'));
  const discount = percentageDiscount(totalPriceBeforeDiscount, totalPrice);
  const advisor = state.chat.selectedAdvisor;
  const transport = state.chat.selectedTransport;
  const splitingDateTime = () => {
    const dateItems = state.chat.selectedDateTime.match(/\d+/g);
    const selectedDate = `${dateItems[0]}-${dateItems[1]}-${dateItems[2]}`;
    const selectedQuarterSlot = `${dateItems[3]}:${dateItems[4]}`;
    return `${selectedDate} ${selectedQuarterSlot}`;
  };
  const selectedTime = state.chat.selectedDateTime ? format(splitingDateTime(), 'dddd, MMMM D, YYYY, hh:mm A') : '';

  return {
    customer: `${name || ''} ${lastName}`,
    vehicle: `${make} ${model}, ${year}`,
    services: services.map(s => ({
      id: s.id,
      name: s.name,
      price: s.fee,
      package_items: s.package_items,
      extras: s.extras,
      premium_extras: s.premium_extras,
      saving: s.saving,
      kind: s.kind,
    })),
    advisor,
    transport,
    totalPrice,
    totalTime: `${totalHours} ${timeLabel}`,
    totalHours,
    totalPriceBeforeDiscount,
    discount,
    selectedTime,
  };
};

export const makeModelYear = (state) => {
  const { make, model, year } = state.chat.currentVehicle;
  return `${make} ${model}, ${year}`;
};

export const availableDatesSelector = (state) => {
  if (
    chatIsPickUp(state)
    || chatIsMobileTechnician(state)
  ) {
    const { jobSlots } = state.chat;
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 3);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < jobSlots.length; i++) {
      const relatedJobSlots = jobSlots[i].slots;
      const slots = {};
      const employeeJobSlots = {};

      Object.keys(relatedJobSlots).forEach((key) => {
        if (relatedJobSlots[key].length > 0) {
          slots[key] = [...chatAvailableAdvisorsSelector(state).map(advisor => advisor.id)];
          employeeJobSlots[key] = relatedJobSlots[key];
        }
      });

      jobSlots[i].slots = slots;
      jobSlots[i].employeeJobSlots = employeeJobSlots;
    }

    return jobSlots.map((slot, index) => ({
      id: index,
      shortDayOfTheWeek: slot.short_day_name,
      dayOfTheWeek: slot.day_name,
      dayOfTheMonth: slot.day_of_month,
      fullDate: slot.full_date,
      appointmentSlots: !isAfter(minDate, slot.full_date) ? slot.slots : {},
      employeeJobSlots: !isAfter(minDate, slot.full_date) ? slot.employeeJobSlots : {},
    }));
  }

  return state.chat.appointmentSlots.map((slot, index) => ({
    id: index,
    shortDayOfTheWeek: slot.short_day_name,
    dayOfTheWeek: slot.day_name,
    dayOfTheMonth: slot.day_of_month,
    fullDate: slot.full_date,
    appointmentSlots: slot.slots,
  }));
};


export const availableJobDatesSelector = state =>
  state.chat.jobSlots.map((slot, index) => ({
    id: index,
    shortDayOfTheWeek: slot.short_day_name,
    dayOfTheWeek: slot.day_name,
    dayOfTheMonth: slot.day_of_month,
    fullDate: slot.full_date,
    appointmentSlots: slot.slots,
  }));

export const chatSelectedAdvisorSelector = state => state.chat.selectedAdvisor;
export const chatSelectedPhantomAdvisorIdsSelector = state => state.chat.phantomAdvisorIds;
export const chatSelectedAdvisorIdSelector = state => state.chat.selectedAdvisor.id;
export const chatCustomerWaitingSelector = state => state.chat.clientWaiting;
export const chatSelectedSkipStepsSelector = state => state.chat.skipSteps;
export const chatSelectedSkipStepsToMilageSelector = state => state.chat.skipStepsToMilage;
export const chatSelectedSkipStepsToCarSelectionSelector = state =>
  (state.chat.skipStepsToCarSelection);
export const chatSelectedskipStepToConfirmIdentitynSelector = state =>
  (state.chat.skipStepToConfirmIdentity);
export const chatSelectedVehicleIdFromParamsSelector = state => state.chat.vehicleIdFromParams;
export const chatBookingSource = state => state.chat.bookingSource;
export const chatDriversSelector = state => state.chat.drivers;
export const chatTechniciansSelector = state => state.chat.technicians;
export const chatEmployeeSelector = state => state.chat.employee;
export const chatRemoteZoneSelector = state => state.chat.remoteZone;
export const chatRemoteGeolocationSelector = state => state.chat.geolocation;
export const chatSkipPickUpSelector = state => state.chat.skipPickUp;
export const chatSkipMobileTechnician = state => state.chat.skipMobileTechnician;
export const chatPickUpAvailableSelector = state => state.chat.pickUpAvailable;
export const chatMobileTechnicianAvailableSelector = state => state.chat.mobileTechnicianAvailable;
