import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, string } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import InfoMessage from 'components/common/InfoMessage';
import {
  chatAvailableTransportsSelector,
  chatIsPickUp,
  chatSelectedTransportSelector,
} from 'selectors/booking/chat-selectors';

import bulbIcon from 'assets/images/bulb.svg';

const TransportSelectionStep = ({
  needsTransport,
  selectedTransport,
  isAnimationFinished,
  availableTransports,
  isPickUp,
}) => {
  const pickedTransport = () => availableTransports
    .find(transport => transport.kind === selectedTransport);
  const shouldDisplayDisclaimer = () => {
    if (pickedTransport() && !!pickedTransport().additional_information_for_customers) {
      return true;
    }
    return false;
  };
  const transportList = () => {
    const transports = availableTransports.map(transport => transport.kind);
    const isLoanerAvailable = transports.includes('loaner');

    if (isPickUp && isLoanerAvailable) {
      return ['Loaner'];
    }
    return transports.map(transport => transport.replace(/./, c => c.toUpperCase())).join(', ').replace(/, (\w+)$/, ' and $1');
  };

  return (
    <Fragment>
      {transportList().length > 0 && (
      <AnimationGroup isComplete={isAnimationFinished}>
        {availableTransports.length > 0 && (
          <Animation delay={DELAY_500}>
            <InfoMessage
              icon={bulbIcon}
              content={
                isPickUp
                  ? `We know your time is valuable! Did you know we offer ${transportList()}?`
                  : `We know your time is valuable! Did you know we offer rides
                    to and from the dealership through ${transportList()}?`
              }
            />
          </Animation>
        )}
        <AnimatedTextMessage
          delay={DELAY_1000}
          message="Do you need an alternative transport?"
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 137000, to: 141000 }}
        />
      </AnimationGroup>
        )}
      {needsTransport !== null && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            message={needsTransport ? 'Yes.' : 'No.'}
            source={SOURCE_USER}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message={needsTransport ? 'Which option do you prefer?' : "Ok! We'll be waiting for you."}
            source={SOURCE_CONCIERGE}
            isOpening
          />
        </AnimationGroup>
      )}

      {selectedTransport === ['uber', 'lyft'].find(t => t === selectedTransport) && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            message={selectedTransport === 'uber' ? `An ${selectedTransport} would be great.` : `A ${selectedTransport} would be great.`}
            source={SOURCE_USER}
            isOpening
          />
          {(shouldDisplayDisclaimer() === true) && (
            <AnimatedTextMessage
              delay={DELAY_500}
              message={pickedTransport().additional_information_for_customers}
              source={SOURCE_CONCIERGE}
              isOpening
            />
          )}
        </AnimationGroup>
      )}
      {selectedTransport === 'loaner' && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            message="A loaner would be great."
            source={SOURCE_USER}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Your loaner has been provisioned."
            source={SOURCE_CONCIERGE}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_1000}
            message="Our team will contact you regarding the next steps."
            source={SOURCE_CONCIERGE}
          />
        </AnimationGroup>
      )}
      {selectedTransport === 'shuttle' && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            message="A shuttle would be great."
            source={SOURCE_USER}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Please contact the shuttle driver upon your arrival at the dealership."
            source={SOURCE_CONCIERGE}
            isOpening
            timecodes={{ from: 141000, to: 150000 }}
          />
          <AnimatedTextMessage
            delay={DELAY_1000}
            message="The shuttle leaves every 30 minutes."
            source={SOURCE_CONCIERGE}
          />
        </AnimationGroup>
      )}
    </Fragment>
  );
};


TransportSelectionStep.propTypes = {
  needsTransport: bool,
  selectedTransport: string,
  availableTransports: arrayOf(string),
  isAnimationFinished: bool,
  isPickUp: bool.isRequired,
};

TransportSelectionStep.defaultProps = {
  needsTransport: null,
  selectedTransport: null,
  availableTransports: null,
  isAnimationFinished: false,
};

const mapStateToProps = state => ({
  availableTransports: chatAvailableTransportsSelector(state),
  selectedTransport: chatSelectedTransportSelector(state),
  isPickUp: chatIsPickUp(state),
});

export default connect(mapStateToProps)(TransportSelectionStep);
