import { connect } from 'react-redux';

import { chatMakeModelYearMapSelector } from 'selectors/booking/chat-selectors';
import { appDealershipDefaultMakeSelector } from 'selectors/booking/app-selectors';
import { createCar } from 'actions/booking/chat-actions';

import CarCreationStepInput from './CarCreationStepInput';

const mapStateToProps = state => ({
  makeModelYearMap: chatMakeModelYearMapSelector(state),
  defaultMake: appDealershipDefaultMakeSelector(state),
});

const actions = {
  onSubmit: createCar,
};

const CarCreationStepInputContainer = connect(mapStateToProps, actions)(CarCreationStepInput);

export default CarCreationStepInputContainer;
