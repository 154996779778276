import axios from 'axios';
import { lambda } from './aws';

const GET = 'get';
const POST = 'post';
const PATCH = 'patch';

const FETCH_PAYMENT_TOKEN_PATH = '/visits/square/authorize';
const BOOKINGS_PATH = '/bookings';
const SESSION_TOKEN_PATH = '/oauth/token';
const VISITS_PATH = '/visits';
const DECISION_TREES_PATH = '/decision_trees';
const VEHICLE_SETS_PATH = '/vehicle_sets';

const fetchProactiveAppointmentPath = guid => `/bookings/${guid}`;
const fetchCustomerInWidgetPath = bookingId => `/bookings/${bookingId}/customers/search`;
const addCustomerPath = bookingId => `/bookings/${bookingId}/customers/add_command`;
const addVehicleInWidgetPath = bookingId => `/bookings/${bookingId}/vehicles/add_command`;
const updateVehicleInWidgetPath = bookingId => `/bookings/${bookingId}/vehicles/update_command`;
const addAppointmentInWidgetPath = bookingId => `/bookings/${bookingId}/appointments/add_command`;
const fetchDriversPath = bookingId => `/bookings/${bookingId}/drivers`;
const fetchTechniciansPath = bookingId => `/bookings/${bookingId}/technicians`;
const addPickupJobPath = bookingId => `/bookings/${bookingId}/jobs/driver_jobs`;
const addTechnicianJobPath = bookingId => `/bookings/${bookingId}/jobs/technician_jobs`;
const fetchTechnicianJobSlotsPath = bookingId => `/bookings/${bookingId}/jobs/technician_jobs/available_slots`;
const fetchDriverJobSlotsPath = bookingId => `/bookings/${bookingId}/jobs/driver_jobs/available_slots`;
const fetchAppointmentSlotsPath = bookingId => `/bookings/${bookingId}/appointment_slots`;
const fetchTeamTagsPath = bookingId => `/bookings/${bookingId}/dealerships/team_tags`;
const fetchTeamTagPath = bookingId => `/bookings/${bookingId}/dealerships/team_tags/find`;
const fetchTeamAdvisorsPath = (bookingId, teamTagId) => `/bookings/${bookingId}/dealerships/team_tags/${teamTagId}/service_advisors`;
const fetchServiceAdvisorsPath = bookingId => `/bookings/${bookingId}/service_advisors`;
const fetchAvailableTransportsInWidgetPath = bookingId => `/bookings/${bookingId}/transports`;
const fetchJobsEnabledPath = bookingId => `/bookings/${bookingId}/jobs/driver_jobs/available_for_appointment`;
const fetchJobsIsReachablePath = bookingId => `/bookings/${bookingId}/jobs/is_reachable`;
const fetchBookingServicesPath = (bookingId, dealershipId) => `/bookings/${bookingId}/dealerships/${dealershipId}/menu_items`;
const fetchCustomerInKioskPath = visitId => `/visits/${visitId}/customers/find_command`;
const fetchAppointmentPath = visitId => `/visits/${visitId}/appointments/find_command`;
const recognizePlatePath = visitId => `/visits/${visitId}/vehicles/recognize_command`;
const addVehicleInKioskPath = visitId => `/visits/${visitId}/vehicles/add_command`;
const updateVehicleInKioskPath = visitId => `/visits/${visitId}/vehicles/update_command`;
const updateAppointmentPath = (visitId, appointmentId) =>
  `visits/${visitId}/appointments/${appointmentId}/update_command`;
const sendSignaturePath = appointmentId => `/appointments/${appointmentId}/signature`;
const fetchUberRidePath = visitId => `visits/${visitId}/transports/uber/trip`;
const decisionTreeResultsPath = appointmentId => `/appointments/${appointmentId}/decision_tree_results`;
const convertAppointmentToROPath = visitId => `visits/${visitId}/repair_orders/add_command`;
const fetchVisitServicesPath = (visitId, dealershipId) => `/visits/${visitId}/dealerships/${dealershipId}/menu_items`;
const fetchBookingByNumberPath = visitId => `visits/${visitId}/appointments/find_command`;
const addVisitCustomerPath = visitId => `/visits/${visitId}/customers/add_command`;
const addTransportPath = visitId => `/visits/${visitId}/transports`;
const addAppointmentInKioskPath = visitId => `/visits/${visitId}/appointments/add_command`;
const syncVehiclePath = visitId => `/visits/${visitId}/vehicles/sync_command`;
const importAppointmentsOfCustomerPath = visitId => `/visits/${visitId}/customers/appointments_command`;
const fetchVehiclesInKioskPath = visitId => `/visits/${visitId}/customers/vehicles_command`;
const connectionTokenPath = visitId => `/visits/${visitId}/stripe/terminal/connect`;
const paymentIntentPath = visitId => `/visits/${visitId}/stripe/payments`;
const createPaymentPath = visitId => `${paymentIntentPath(visitId)}/create`;
const capturePaymentPath = visitId => `${paymentIntentPath(visitId)}/capture`;
const cancelPaymentPath = visitId => `${paymentIntentPath(visitId)}/cancel`;
const authorizationHeader = token => (token ? { Authorization: `Bearer ${token}` } : {});
const bookingRecallsPath = bookingId => `/bookings/${bookingId}/recalls`;
const visitRecallsPath = visitId => `/visits/${visitId}/recalls`;
const fetchAvailableTransportsInKioskPath = visitId => `/visits/${visitId}/transports`;
const updateBookingStatisticsPath = bookingId => `/bookings/${bookingId}`;

export const dealershipStylesheetUrl = dealershipId =>
  `${process.env.REACT_APP_ROOT_URL}/stylesheets/${dealershipId}`;

export const lambdaCall = (params) => {
  const lambdaParams = {
    FunctionName: 'classifyTranscription',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify(params),
  };
  return new Promise((resolve, reject) => {
    lambda.invoke(lambdaParams, (err, data) => {
      if (err) reject(err);
      resolve(data);
    });
  });
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
  method: POST,
  url: SESSION_TOKEN_PATH,
});

export const fetchAccessToken = (username, password) => (
  axiosAuthInstance.request({
    data: {
      username,
      password,
      grant_type: 'password',
    },
  }).then(response => response.data)
);

export const refreshAccessToken = (token, refreshToken) => (
  axiosAuthInstance.request({
    headers: authorizationHeader(token),
    data: {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    },
  }).then(response => response.data)
);

const apiCall = (path, params = {}, method = GET, token) => (
  axiosInstance.request({
    method,
    headers: authorizationHeader(token),
    url: path,
    params: method === GET ? params : undefined,
    data: method === POST || PATCH ? params : undefined,
  }).then(response => response.data));

export const fetchPaymentToken = token =>
  apiCall(FETCH_PAYMENT_TOKEN_PATH, { }, POST, token);

export const startBooking = (params = {}) =>
  apiCall(BOOKINGS_PATH, params, POST);

export const updateStatistics = (bookingId, params) =>
  apiCall(updateBookingStatisticsPath(bookingId), params, PATCH);

export const startVisit = token => apiCall(VISITS_PATH, {}, POST, token);

export const fetchTeamTags = bookingId =>
  apiCall(fetchTeamTagsPath(bookingId), {}, GET);

export const fetchDrivers = bookingId =>
  apiCall(fetchDriversPath(bookingId), {}, GET);

export const fetchTechnicians = bookingId =>
  apiCall(fetchTechniciansPath(bookingId), {}, GET);

export const importAppointmentsOfCustomer = (visitId, token, phoneNumber) =>
  apiCall(importAppointmentsOfCustomerPath(visitId), { phone_number: phoneNumber }, GET, token);

export const fetchBookingCustomer = (bookingId, searchPhrase, searchType) =>
  apiCall(
    fetchCustomerInWidgetPath(bookingId),
    {
      search_phrase: searchPhrase,
      search_type: searchType,
    },
  );

export const addCustomer = (bookingId, customer) =>
  apiCall(addCustomerPath(bookingId), customer, POST);

export const addVisitCustomer = (visitId, customer, token) =>
  apiCall(addVisitCustomerPath(visitId), {
    full_name: customer.name,
    phone_number: customer.phone,
    email: customer.email,
  }, POST, token);

export const fetchMakeModelYearMap = params =>
  apiCall(VEHICLE_SETS_PATH, params);

export const addBookingVehicle = (bookingId, vehicle, customer) =>
  apiCall(addVehicleInWidgetPath(bookingId), {
    vehicle_set: {
      make: vehicle.make,
      model: vehicle.model,
      model_year: vehicle.year,
    },
    vehicle: {
      mileage: vehicle.mileage,
      customer_id: customer.id,
      customer_number: customer.customerNumber,
    },
  }, POST);

export const updateBookingVehicle = (bookingId, vehicle) =>
  apiCall(updateVehicleInWidgetPath(bookingId), { mileage: vehicle.mileage, id: vehicle.id }, POST);

export const fetchVisitServices = (visitId, dealershipId, vehicle) =>
  apiCall(fetchVisitServicesPath(visitId, dealershipId), {
    'vehicle_set[make]': vehicle.make,
    'vehicle_set[model]': vehicle.model,
    'vehicle_set[model_year]': vehicle.year,
    mileage: vehicle.mileage || 0,
  });

export const fetchBookingServices = (bookingId, dealershipId, vehicle) =>
  apiCall(fetchBookingServicesPath(bookingId, dealershipId), {
    'vehicle_set[make]': vehicle.make,
    'vehicle_set[model]': vehicle.model,
    'vehicle_set[model_year]': vehicle.year,
    mileage: vehicle.mileage || 0,
  });

export const addBookingAppointment = (
  source,
  bookingId,
  dealershipId,
  details,
  serviceType,
  addressData,
  jobIds = [],
) =>
  apiCall(addAppointmentInWidgetPath(bookingId), {
    dealership_id: dealershipId,
    client_waiting: details.clientWaiting,
    send_sms: details.sendSms,
    phone_number: details.preferredPhoneNumber,
    vehicle_id: details.currentVehicle.id,
    customer_id: details.currentCustomer.id,
    service_advisor_id: details.selectedAdvisor.id,
    first_available_advisor: details.firstAvailable,
    appointment_datetime: details.selectedDateTime,
    job_ids: jobIds,
    transport_kind: details.selectedTransport,
    appraisal_requested: details.appraisalRequested,
    recall_ids: (details.selectedRecalls || []).map(r => r.id),
    menu_item_ids: details.menuItemIds || details.selectedServices.maintenance.map(s => s.id)
      .concat(details.selectedServices.concern.map(s => s.id)),
    menu_item_comments: details.menuItemIds
      ? details.selectedServices
        .filter(s => s.additionalInfo).map((s => ({ id: s.id, comment: s.additionalInfo })))
      : details.selectedServices.concern
        .filter(s => s.additionalInfo).map((s => ({ id: s.id, comment: s.additionalInfo }))),
    source,
    service_type: serviceType,
    remote_address_data: addressData,
  }, POST);

export const addPickupJob = (
  bookingId,
  dealershipId,
  locationData,
  collectionTime,
  jobType,
  notes,
  appointmentId,
  geolocation,
  locationArea,
  mainDriverId,
  coDriverId = null,
) =>
  apiCall(addPickupJobPath(bookingId), {
    booking_id: bookingId,
    dealership_id: dealershipId,
    collection_time: collectionTime,
    job_type: jobType,
    notes,
    appointment_id: appointmentId,
    main_driver_id: mainDriverId,
    co_driver_id: coDriverId,
    geolocation,
    location_area: locationArea,
    ...locationData,
  }, POST);

export const addTechnicianJob = (
  bookingId,
  dealershipId,
  locationData,
  collectionTime,
  jobType,
  notes,
  appointmentId,
  geolocation,
  technicianId,
  locationArea,
) =>
  apiCall(addTechnicianJobPath(bookingId), {
    booking_id: bookingId,
    dealership_id: dealershipId,
    collection_time: collectionTime,
    job_type: jobType,
    notes,
    appointment_id: appointmentId,
    technician_id: technicianId,
    location_area: locationArea,
    geolocation,
    ...locationData,
  }, POST);

export const recognizePlate = (visitId, token, picture) => {
  apiCall(
    recognizePlatePath(visitId), {
      image: picture,
    },
    POST,
    token,
  );
};

export const fetchVisitCustomer = (visitId, token, params = {}) =>
  apiCall(fetchCustomerInKioskPath(visitId), params, GET, token);

export const fetchVisitVehicles = (visitId, params, token) =>
  apiCall(fetchVehiclesInKioskPath(visitId), params, GET, token);

export const fetchAppointment = (visitId, params, token) => {
  apiCall(fetchAppointmentPath(visitId), params, GET, token);
};

export const syncVehicle = (visitId, order, plateDetails, token) =>
  apiCall(syncVehiclePath(visitId), {
    id: order.vehicle.id,
    appointment_id: order.appointment.id,
    vehicle_set: {
      make: order.vehicle.make,
      model: order.vehicle.model,
      model_year: order.vehicle.year,
    },
    vehicle: {
      plate_number: plateDetails.plateNumber,
      region: plateDetails.state,
      vin: plateDetails.vin,
      mileage: order.vehicle.mileage,
    },
  }, POST, token);

export const addVisitVehicle = (visitId, vehicle, customer, token) =>
  apiCall(addVehicleInKioskPath(visitId), {
    vehicle_set: {
      make: vehicle.make,
      model: vehicle.model,
      model_year: vehicle.year,
    },
    vehicle: {
      plate_number: vehicle.plateNumber,
      region: vehicle.region,
      mileage: vehicle.mileage,
      customer_id: customer.id,
      customer_number: customer.customerNumber,
    },
  }, POST, token);

export const updateVisitVehicle = (visitId, vehicle, token) =>
  apiCall(
    updateVehicleInKioskPath(visitId),
    { id: vehicle.id, mileage: vehicle.mileage },
    POST,
    token,
  );

export const fetchDecisionTrees = (ids, token) =>
  apiCall(DECISION_TREES_PATH, { menu_item_ids: ids }, GET, token);

export const sendDecisionTreeResult = (appointmentId, decisionTreeResult, token) =>
  apiCall(decisionTreeResultsPath(appointmentId), {
    menu_item_id: decisionTreeResult.serviceId,
    details: decisionTreeResult.conversation,
    additional_note: decisionTreeResult.description || '',
  }, POST, token);

export const fetchAdditionalServices = (visitId, dealershipId, vehicle, token) =>
  apiCall(fetchVisitServicesPath(visitId, dealershipId), {
    'vehicle_set[make]': vehicle.make,
    'vehicle_set[model]': vehicle.model,
    'vehicle_set[model_year]': vehicle.year,
    mileage: vehicle.mileage,
    only_extension: true,
  }, GET, token);

export const fetchRecallsForBooking = (bookingId, vin) => (
  apiCall(bookingRecallsPath(bookingId), { vin })
);

export const fetchRecallsForVisit = (visitId, vin, token) => (
  apiCall(visitRecallsPath(visitId), { vin }, GET, token)
);

export const sendSignature = (appointmentId, signature, token) =>
  apiCall(sendSignaturePath(appointmentId), { signature }, PATCH, token);

export const updateAppointment = (visitId, dealershipId, order, allServiceIds, token) =>
  apiCall(updateAppointmentPath(visitId, order.appointment.id), {
    dealership_id: dealershipId,
    client_waiting: !!order.clientWaiting,
    phone_number: order.preferredPhoneNumber,
    vehicle_id: order.vehicle.id,
    customer_id: order.customer.id,
    appointment_datetime: order.date,
    menu_item_ids: allServiceIds,
    line_item_ids: order.lineItems.map(li => li.id),
    recall_ids: (order.selectedRecalls || []).map(r => r.id),
    general_additional_note: order.additionalNotes || '',
    appraisal_requested: !!order.appraisalRequested,
  }, PATCH, token);

export const convertAppointmentToRO = (appointmentId, visitId, token) =>
  apiCall(convertAppointmentToROPath(visitId), { appointment_id: appointmentId }, POST, token);

export const fetchAvailableTransportsInWidget = (
  bookingId,
  moneySpent,
  appointmentDuration,
  appointmentDatetime,
  token,
) => (
  apiCall(fetchAvailableTransportsInWidgetPath(bookingId), {
    appointment_datetime: appointmentDatetime,
    money_spent: moneySpent,
    appointment_duration: appointmentDuration,
  }, GET, token)
);

export const fetchAvailableTransportsInKiosk = (
  bookingId,
  moneySpent,
  appointmentDuration,
  appointmentDatetime,
  token,
) => (
  apiCall(fetchAvailableTransportsInKioskPath(bookingId), {
    appointment_datetime: appointmentDatetime,
    money_spent: moneySpent,
    appointment_duration: appointmentDuration,
  }, GET, token)
);

export const fetchJobsEnabledMoneyDurationValidation = (
  bookingId,
  moneySpent,
  appointmentDuration,
  token,
) => apiCall(fetchJobsEnabledPath(bookingId), {
  money_spent: moneySpent,
  appointment_duration: appointmentDuration,
}, GET, token);

export const fetchJobsEnabledDistanceValidation = (
  bookingId,
  location,
  geolocation,
  jobType,
) => apiCall(fetchJobsIsReachablePath(bookingId), {
  bookingId,
  ...location,
  lat: geolocation.lat,
  lng: geolocation.lng,
  job_type: jobType,
});

export const addTransport = (visitId, appointmentId, kind, token) =>
  apiCall(addTransportPath(visitId), {
    appointment_id: appointmentId,
    kind,
  }, POST, token);

export const fetchUberRide = (visitId, appointmentId, params, token) =>
  apiCall(fetchUberRidePath(visitId), {
    appointment_id: appointmentId,
    end_latitude: params.lat,
    end_longitude: params.lng,
  }, POST, token);

export const fetchBookingByNumber = (visitId, appointmentNumber, token) =>
  apiCall(fetchBookingByNumberPath(visitId), { appointment_number: appointmentNumber }, GET, token);

export const fetchBookingByGuid = (visitId, guid, token) =>
  apiCall(fetchBookingByNumberPath(visitId), { guid }, GET, token);

export const addVisitAppointment = (visitId, dealershipId, details, token) =>
  apiCall(addAppointmentInKioskPath(visitId), {
    dealership_id: dealershipId,
    client_waiting: false,
    send_sms: true,
    phone_number: details.customer.phoneNumber,
    vehicle_id: details.vehicle.id,
    customer_id: details.customer.id,
    appointment_datetime: new Date(),
    menu_item_ids: (details.services || []).map(s => s.id),
    recalls: (details.recalls || []).map(r => r.id),
    appraisal_requested: false,
  }, POST, token);

export const fetchConnectionToken = (visitId, dealershipId, token) =>
  apiCall(connectionTokenPath(visitId), { dealership_id: dealershipId }, POST, token)
    .then(res => res.secret);

export const createPaymentIntent = (visitId, params, token) =>
  apiCall(createPaymentPath(visitId), params, POST, token);

export const capturePaymentIntent = (visitId, params, token) =>
  apiCall(capturePaymentPath(visitId), params, POST, token);

export const cancelPaymentIntent = (visitId, params, token) =>
  apiCall(cancelPaymentPath(visitId), params, POST, token);

export const fetchDriverJobSlots = (
  bookingId,
  vehicleId,
  menuItemsIds,
  recallsIds,
  month,
  year,
  workingArea,
) =>
  apiCall(fetchDriverJobSlotsPath(bookingId), {
    vehicle_id: vehicleId,
    booking_id: bookingId,
    menu_items_ids: menuItemsIds,
    recalls_ids: recallsIds,
    month,
    year,
    working_area: workingArea,
  }, GET);

export const fetchTechnicianJobSlots = (
  bookingId,
  vehicleId,
  menuItemsIds,
  recallsIds,
  month,
  year,
  workingArea,
) =>
  apiCall(fetchTechnicianJobSlotsPath(bookingId), {
    vehicle_id: vehicleId,
    booking_id: bookingId,
    menu_items_ids: menuItemsIds,
    recalls_ids: recallsIds,
    month,
    year,
    working_area: workingArea,
  }, GET);


export const fetchAppointmentSlots = (bookingId, params) =>
  apiCall(fetchAppointmentSlotsPath(bookingId), params, GET);

export const fetchAvailableAdvisors = (bookingId, dealershipId) =>
  apiCall(fetchServiceAdvisorsPath(bookingId), { dealership_id: dealershipId }, GET);

export const fetchTeamAdvisors = (bookingId, teamTagId) =>
  apiCall(fetchTeamAdvisorsPath(bookingId, teamTagId), { id: teamTagId }, GET);

export const fetchResponsibleTeamTag = (bookingId, payload) =>
  apiCall(fetchTeamTagPath(bookingId), payload, GET);

export const fetchProactiveAppointment = (guid, payload) =>
  apiCall(fetchProactiveAppointmentPath(guid), payload, GET);
