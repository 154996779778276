import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { string, bool, func } from 'prop-types';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { chatIsPickUp, chatIsMobileTechnician } from 'selectors/booking/chat-selectors';
import { appMobileTechnicianTimeVariance, appPickupTimeVariance } from 'selectors/booking/app-selectors';
import { updateBookingStatistics } from 'actions/booking/chat-actions';

const DateSelectionStep = ({
  selectedDate,
  selectedTime,
  isComplete,
  isAnimationFinished,
  isPickup,
  isMobileTechnician,
  mobileTechnicianTimeVariance,
  pickupTimeVariance,
  updateStatistics,
}) => {
  useEffect(() => {
    if (isPickup) {
      updateStatistics({
        drivers_calendar_shown: true,
      });
    } else if (isMobileTechnician) {
      updateStatistics({
        technicians_calendar_shown: true,
      });
    } else {
      updateStatistics({
        advisors_calendar_shown: true,
      });
    }
  }, []);

  const initialMessage = () => {
    let message = 'Ok! 👍 Here are the closest available dates for your';

    if (isPickup) {
      message += ' vehicle Pick-up.';
      if (pickupTimeVariance !== null && pickupTimeVariance !== 0) {
        message += ` Please note that collection time may vary by approximately ${pickupTimeVariance} minutes.`;
      }
    } else if (isMobileTechnician) {
      message += ' vehicle Mobile Technician.';
      if (mobileTechnicianTimeVariance !== null && mobileTechnicianTimeVariance !== 0) {
        message += ` Please note that service time may vary by approximately ${mobileTechnicianTimeVariance} minutes.`;
      }
    } else {
      message += ' vehicle visit';
    }

    return message;
  };

  const completeMessage = () => {
    let message = 'Book';

    if (isPickup) {
      message += ' vehicle Pick-up';
    } else if (isMobileTechnician) {
      message += ' vehicle Mobile Technician';
    } else {
      message += ' vehicle visit';
    }

    message += ` for ${selectedDate}, ${selectedTime}`;

    return message;
  };

  return (
    <Fragment>
      {(!isComplete || (isComplete && selectedDate)) && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={initialMessage()}
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 125000, to: !isMobileTechnician && !isPickup ? 137000 : 132000 }}
        />
      </AnimationGroup>
    )}
      {isComplete && selectedDate && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={completeMessage()}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
    </Fragment>
  );
};

DateSelectionStep.propTypes = {
  selectedDate: string,
  selectedTime: string,
  isComplete: bool,
  isAnimationFinished: bool,
  isPickup: bool,
  isMobileTechnician: bool,
  mobileTechnicianTimeVariance: bool.isRequired,
  pickupTimeVariance: bool.isRequired,
  updateStatistics: func.isRequired,
};

DateSelectionStep.defaultProps = {
  selectedDate: '',
  selectedTime: '',
  isComplete: false,
  isAnimationFinished: false,
  isPickup: false,
  isMobileTechnician: false,
};

const mapStateToProps = state => ({
  isPickup: chatIsPickUp(state),
  isMobileTechnician: chatIsMobileTechnician(state),
  mobileTechnicianTimeVariance: appMobileTechnicianTimeVariance(state),
  pickupTimeVariance: appPickupTimeVariance(state),
});

const actions = {
  updateStatistics: updateBookingStatistics,
};


export default connect(mapStateToProps, actions)(DateSelectionStep);
