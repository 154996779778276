import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const FinalStep = ({ showMessage, isAnimationFinished }) => (
  <Fragment>
    {showMessage && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          delay={DELAY_500}
          message="Thank you! Your booking has been successfully created."
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 174000, to: 187000 }}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

FinalStep.propTypes = {
  isAnimationFinished: bool,
  showMessage: bool,
};

FinalStep.defaultProps = {
  isAnimationFinished: false,
  showMessage: false,
};

export default FinalStep;
