import React from 'react';
import { func, bool, objectOf, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { chatLoadingSelector, chatCurrentCustomerSelector } from 'selectors/booking/chat-selectors';
import { confirmIdentity, denyIdentity, updateBookingStatistics } from 'actions/booking/chat-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.scss';

const ConfirmIdentityStepInput = ({
  onConfirm, onDeny, isDisabled, isAnimationFinished, updateStatistics, customer,
}) => (
  !isDisabled && (
    <AnimationGroup isComplete={isAnimationFinished}>
      <Animation delay={DELAY_500}>
        <div className={styles.container}>
          <Button
            caption="No"
            isSecondary
            className={styles.no}
            onClick={onDeny}
          />
          <Button
            caption="Yes"
            className={styles.yes}
            onClick={() => {
              updateStatistics({
                customer_id: customer.id,
              });
              onConfirm();
            }}
          />
        </div>
      </Animation>
    </AnimationGroup>
  )
);

ConfirmIdentityStepInput.propTypes = {
  onConfirm: func.isRequired,
  onDeny: func.isRequired,
  isDisabled: bool,
  isAnimationFinished: bool,
  updateStatistics: func.isRequired,
  customer: objectOf(string).isRequired,
};

ConfirmIdentityStepInput.defaultProps = {
  isDisabled: false,
  isAnimationFinished: false,
};

const mapStateToProps = state => ({
  isDisabled: chatLoadingSelector(state),
  customer: chatCurrentCustomerSelector(state),
});

const actions = {
  onConfirm: confirmIdentity,
  onDeny: denyIdentity,
  updateStatistics: updateBookingStatistics,
};

const ConfirmIdentityStepInputContainer =
  connect(mapStateToProps, actions)(ConfirmIdentityStepInput);

export default ConfirmIdentityStepInputContainer;
