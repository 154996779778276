import React, { Fragment, Component } from 'react';
import { func, bool, number, objectOf, arrayOf, string } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import StyledSelect from 'components/common/StyledSelect';
import { formatLabel, toStructuredForSelect } from 'shared/utils';
import { uniq } from 'ramda';
import Button from 'components/common/Button';

import styles from './styles.module.scss';

class CarCreationStepInput extends Component {
  state = {
    make: this.props.defaultMake || null,
    model: null,
    year: null,
  }

  handleMakeChange = make => this.setState({ make, year: null, model: null })
  handleModelChange = model => this.setState({ model })
  handleYearChange = year => this.setState({ year })

  handleSubmit = (event) => {
    const { make, model, year } = this.state;

    event.preventDefault();
    this.props.onSubmit({ make, model, year });
  }

  valuesForMake = () => Object.keys(this.props.makeModelYearMap)
  valuesForModel = (make, year) => {
    const { makeModelYearMap } = this.props;
    if (!make) return [];
    if (make && year) {
      return Object.keys(makeModelYearMap[make] || {})
        .filter(model => makeModelYearMap[make][model].includes(year));
    }
    return Object.keys(makeModelYearMap[make]);
  }
  valuesForYear = (make, model) => {
    const { makeModelYearMap } = this.props;
    if (!make && !model) return [];
    if (!model) {
      const allYears = Object.keys(makeModelYearMap[make]).reduce(
        (yearList, modelItem) => [
          ...yearList,
          ...makeModelYearMap[make][modelItem],
        ],
        [],
      );
      return uniq(allYears).sort().reverse();
    }
    return makeModelYearMap[make][model];
  }

  renderInput = (label, currentValue, possibleValues, onChange) => (
    <Fragment>
      <div
        id={label}
        className={styles.label}
      >
        {label}
        <StyledSelect
          isSearchable
          menuPlacement="top"
          value={{
            label: formatLabel(currentValue),
            value: currentValue,
          }}
          options={toStructuredForSelect(possibleValues)}
          disabled={!toStructuredForSelect(possibleValues).length > 0}
          onChange={({ value }) => onChange(value)}
        />
      </div>
    </Fragment>
  )

  render() {
    const {
      make, model, year,
    } = this.state;

    return (
      <AnimationGroup isComplete={this.props.isAnimationFinished}>
        <Animation delay={DELAY_500}>
          <form className={styles.container} onSubmit={this.handleSubmit}>
            {this.renderInput('Make', make, this.valuesForMake(), this.handleMakeChange)}
            {this.renderInput('Year', year, this.valuesForYear(make, model), this.handleYearChange)}
            {this.renderInput('Model', model, this.valuesForModel(make, year), this.handleModelChange)}
            {make && year && model && (
              <Button
                className={styles.submit}
                caption="Submit"
                onClick={this.handleSubmit}
                isWide
              />
            )}
          </form>
        </Animation>
      </AnimationGroup>
    );
  }
}

CarCreationStepInput.propTypes = {
  makeModelYearMap: objectOf(objectOf(arrayOf(number))).isRequired,
  defaultMake: string,
  onSubmit: func.isRequired,
  isAnimationFinished: bool,
};

CarCreationStepInput.defaultProps = {
  isAnimationFinished: false,
  defaultMake: null,
};

export default CarCreationStepInput;
