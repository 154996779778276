import React from 'react';
import cx from 'classnames';
import { func } from 'prop-types';
import { vehiclePropType } from 'shared/constants/prop-types';
import { imageUrl } from 'shared/utils';

import styles from './styles.module.scss';

const VehicleButton = ({ vehicle, selectedVehicle, onClickHandler }) => (
  <button
    key={vehicle.id}
    className={cx(styles.slide, {
      [styles.selected]: selectedVehicle && (vehicle.id === selectedVehicle.id),
    })}
    onClick={onClickHandler(vehicle)}
  >
    <span className={`${styles.vehicleName} notranslate`}>{vehicle.name}</span>
    <div className={styles.vehicleYearAndImageContainer}>
      <span className={styles.vehicleYear}>{vehicle.year}</span>
      {vehicle?.image?.url ? (
        <img alt="" className={styles.vehicleImage} src={imageUrl(vehicle.image)} />
      ) : null}
    </div>
  </button>
);

VehicleButton.propTypes = {
  vehicle: vehiclePropType.isRequired,
  selectedVehicle: vehiclePropType,
  onClickHandler: func.isRequired,
};

VehicleButton.defaultProps = {
  selectedVehicle: null,
};

export default VehicleButton;
