import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const ErrorStep = ({ error, canRetry, isAnimationFinished }) => (
  <AnimationGroup isComplete={isAnimationFinished}>
    {canRetry ? (
      <Fragment>
        <AnimatedTextMessage
          message={error
            ? `Sorry, our application servers responded with an error: "${error}"`
            : 'Sorry, our application servers responded with an error.'
          }
          source={SOURCE_CONCIERGE}
          isOpening
        />
        <AnimatedTextMessage
          message="You can come back later or retry the failed request now."
          source={SOURCE_CONCIERGE}
          isClosing
        />
      </Fragment>
    ) : (
      <AnimatedTextMessage
        message={error || 'An application error has occured. Please try again later.'}
        source={SOURCE_CONCIERGE}
        isClosing
      />
    )}
  </AnimationGroup>
);

ErrorStep.propTypes = {
  error: string,
  canRetry: bool,
  isAnimationFinished: bool,
};

ErrorStep.defaultProps = {
  error: '',
  canRetry: false,
  isAnimationFinished: false,
};

export default ErrorStep;
