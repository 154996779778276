import {
  WELCOME_STEP,
  CONFIRM_IDENTITY_STEP,
  CUSTOMER_IDENTIFICATION_STEP,
  CUSTOMER_CREATION_STEP,
  CAR_SELECTION_STEP,
  CAR_CREATION_STEP,
  MILEAGE_STEP,
  SERVICE_SELECTION_STEP,
  AVAILABLE_RECALLS_STEP,
  ADVISOR_SELECTION_STEP,
  DATE_SELECTION_STEP,
  TRANSPORT_SELECTION_STEP,
  JOB_TRANSPORT_SELECTION_STEP,
  BOOKING_SUMMARY_STEP,
  COMMUNICATION_STEP,
  APPRAISAL_SELECTION_STEP,
  DATE_RESELECTION_STEP,
  FINAL_STEP,
  ERROR_STEP,
  REMOTE_SERVICE_STEP,
} from 'shared/constants/booking-steps';

import WelcomeStep from './WelcomeStep';
import WelcomeStepInput from './WelcomeStep/Input';
import ConfirmIdentityStep from './ConfirmIdentityStep';
import ConfirmIdentityStepInput from './ConfirmIdentityStep/Input';
import CustomerIdentificationStep from './CustomerIdentificationStep';
import CustomerIdentificationStepInput from './CustomerIdentificationStep/Input';
import CustomerCreationStep from './CustomerCreationStep';
import CustomerCreationStepInput from './CustomerCreationStep/Input';
import CarSelectionStep from './CarSelectionStep';
import CarSelectionStepInput from './CarSelectionStep/Input';
import CarCreationStep from './CarCreationStep';
import CarCreationStepInput from './CarCreationStep/Input';
import MileageStep from './MileageStep';
import MileageStepInput from './MileageStep/Input';
import ServiceSelectionStep from './ServiceSelectionStep';
import ServiceSelectionStepInput from './ServiceSelectionStep/Input';
import AvailableRecallsStep from './AvailableRecallsStep';
import AvailableRecallsStepInput from './AvailableRecallsStep/Input';
import AdvisorSelectionStep from './AdvisorSelectionStep';
import AdvisorSelectionStepInput from './AdvisorSelectionStep/Input';
import DateSelectionStep from './DateSelectionStep';
import DateSelectionStepInput from './DateSelectionStep/DateSelectionStepInput';
import TransportSelectionStep from './TransportSelectionStep';
import TransportSelectionStepInput from './TransportSelectionStep/Input';
import JobTransportSelectionStep from './JobTransportSelectionStep';
import JobTransportSelectionStepInput from './JobTransportSelectionStep/Input';
import BookingSummaryStep from './BookingSummaryStep';
import BookingSummaryStepInput from './BookingSummaryStep/Input';
import CommunicationStep from './CommunicationStep';
import CommunicationStepInput from './CommunicationStep/Input';
import DateReselectionStep from './DateReselectionStep';
import AppraisalSelectionStep from './AppraisalSelectionStep';
import AppraisalSelectionStepInput from './AppraisalSelectionStep/Input';
import FinalStep from './FinalStep';
import ErrorStep from './ErrorStep';
import ErrorStepInput from './ErrorStep/Input';
import RemoteServiceSelectionStep from './RemoteServiceSelectionStep';
import RemoteServiceSelectionStepInput from './RemoteServiceSelectionStep/Input';

const STEPS = {
  [WELCOME_STEP]: {
    step: WelcomeStep,
    input: WelcomeStepInput,
    progress: 0,
  },
  [CONFIRM_IDENTITY_STEP]: {
    step: ConfirmIdentityStep,
    input: ConfirmIdentityStepInput,
    progress: 1,
  },
  [CUSTOMER_IDENTIFICATION_STEP]: {
    step: CustomerIdentificationStep,
    input: CustomerIdentificationStepInput,
    progress: 1,
  },
  [CUSTOMER_CREATION_STEP]: {
    step: CustomerCreationStep,
    input: CustomerCreationStepInput,
    progress: 1,
  },
  [CAR_SELECTION_STEP]: {
    step: CarSelectionStep,
    input: CarSelectionStepInput,
    progress: 2,
  },
  [CAR_CREATION_STEP]: {
    step: CarCreationStep,
    input: CarCreationStepInput,
    progress: 2,
  },
  [MILEAGE_STEP]: {
    step: MileageStep,
    input: MileageStepInput,
    progress: 3,
  },
  [SERVICE_SELECTION_STEP]: {
    step: ServiceSelectionStep,
    input: ServiceSelectionStepInput,
    progress: 4,
  },
  [AVAILABLE_RECALLS_STEP]: {
    step: AvailableRecallsStep,
    input: AvailableRecallsStepInput,
    progress: 4,
  },
  [REMOTE_SERVICE_STEP]: {
    step: RemoteServiceSelectionStep,
    input: RemoteServiceSelectionStepInput,
    progress: 4,
  },
  [ADVISOR_SELECTION_STEP]: {
    step: AdvisorSelectionStep,
    input: AdvisorSelectionStepInput,
    progress: 6,
  },
  [DATE_SELECTION_STEP]: {
    step: DateSelectionStep,
    input: DateSelectionStepInput,
    progress: 8,
  },
  [JOB_TRANSPORT_SELECTION_STEP]: {
    step: JobTransportSelectionStep,
    input: JobTransportSelectionStepInput,
    progress: 9,
  },
  [TRANSPORT_SELECTION_STEP]: {
    step: TransportSelectionStep,
    input: TransportSelectionStepInput,
    progress: 10,
  },
  [BOOKING_SUMMARY_STEP]: {
    step: BookingSummaryStep,
    input: BookingSummaryStepInput,
    progress: 11,
  },
  [COMMUNICATION_STEP]: {
    step: CommunicationStep,
    input: CommunicationStepInput,
    progress: 12,
  },
  [APPRAISAL_SELECTION_STEP]: {
    step: AppraisalSelectionStep,
    input: AppraisalSelectionStepInput,
    progress: 13,
  },
  [DATE_RESELECTION_STEP]: {
    step: DateReselectionStep,
    input: DateSelectionStepInput,
    progress: 14,
  },
  [FINAL_STEP]: {
    step: FinalStep,
    progress: 15,
  },
  [ERROR_STEP]: {
    step: ErrorStep,
    input: ErrorStepInput,
    progress: 16,
  },
};

export const MAX_PROGRESS = Math.max(...Object.values(STEPS).map(step => step.progress));

export default STEPS;
