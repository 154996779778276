import { connect } from 'react-redux';
import {
  chatAvailableRecallsSelector,
  chatSelectedRecallsSelector,
} from 'selectors/booking/chat-selectors';
import { toggleRecall } from 'actions/booking/chat-actions';
import AvailableRecallsStep from './AvailableRecallsStep';

const mapStateToProps = state => ({
  availableRecalls: chatAvailableRecallsSelector(state),
  selectedRecalls: chatSelectedRecallsSelector(state),
});

const actions = {
  onToggle: toggleRecall,
};

const AvailableRecallsStepContainer = connect(mapStateToProps, actions)(AvailableRecallsStep);

export default AvailableRecallsStepContainer;
