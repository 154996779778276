import React, { Fragment } from 'react';
import { string, bool, objectOf } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { appDealershipNameSelector } from 'selectors/booking/app-selectors';
import {
  chatCurrentCustomerSelector,
  chatSelectedskipStepToConfirmIdentitynSelector,
} from 'selectors/booking/chat-selectors';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const ConfirmIdentityStep = ({
  customer,
  isComplete,
  isConfirmed,
  isAnimationFinished,
  dealershipName,
  skipStepToConfirmIdentity,
}) => (
  <Fragment>
    {skipStepToConfirmIdentity &&
      <AnimatedTextMessage
        message="Hello, there!"
        source={SOURCE_CONCIERGE}
        isOpening
      />
    }
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message={`Am I speaking with ${customer.name || customer.lastName}?`}
        source={SOURCE_CONCIERGE}
        isOpening
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={isConfirmed ? "Yes, that's right." : "No, it's someone else."}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
    {isComplete && isConfirmed && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`Hello, ${customer.name || customer.lastName}. Welcome to ${dealershipName}. My name is Carmen!`}
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 12000, to: 19000 }}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

const mapStateToProps = state => ({
  dealershipName: appDealershipNameSelector(state),
  customer: chatCurrentCustomerSelector(state),
  skipStepToConfirmIdentity: chatSelectedskipStepToConfirmIdentitynSelector(state),
});

ConfirmIdentityStep.propTypes = {
  customer: objectOf(string).isRequired,
  isComplete: bool,
  isConfirmed: bool,
  isAnimationFinished: bool,
  dealershipName: string.isRequired,
  skipStepToConfirmIdentity: bool.isRequired,
};

ConfirmIdentityStep.defaultProps = {
  isComplete: false,
  isConfirmed: false,
  isAnimationFinished: false,
};

export default connect(mapStateToProps)(ConfirmIdentityStep);
