import React, { Component } from 'react';
import { func, number, string, shape, arrayOf, bool } from 'prop-types';

import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import maintenanceIcon from 'assets/images/maintenance.svg';
import concernIcon from 'assets/images/concern.svg';

import Button from 'components/common/Button';
import ServiceSelector from './ServiceSelector';

import styles from './styles.module.scss';

class ServiceSelectionStepInput extends Component {
  state = {
    maintenanceServices: [],
    concernServices: [],
  }

  componentDidMount() {
    this.props.updateStatistics({
      menu_items_shown: true,
    });
  }

  handleChangeMaintenance = services => this.setState({ maintenanceServices: services });
  handleChangeConcern = services => this.setState({ concernServices: services });

  handleSubmit = () => this.props.onSubmit({
    maintenance: this.state.maintenanceServices,
    concern: this.state.concernServices,
  });

  render() {
    const { maintenanceServices, concernServices } = this.state;
    const {
      isAnimationFinished,
      services: { concern, maintenance },
      servicesPriceVisible,
      mobileTechnicianEnabled,
    } = this.props;

    return (
      <AnimationGroup isComplete={isAnimationFinished}>
        <Animation delay={-1000}>
          <div className={styles.container}>
            <div className={styles.services}>
              <ServiceSelector
                icon={maintenanceIcon}
                label="Maintenance"
                availableServices={maintenance}
                selectedServices={maintenanceServices}
                servicesPriceVisible={servicesPriceVisible}
                mobileTechnicianEnabled={mobileTechnicianEnabled}
                onSubmit={this.handleChangeMaintenance}
              />
              <ServiceSelector
                icon={concernIcon}
                label="Concern"
                availableServices={concern}
                selectedServices={concernServices}
                servicesPriceVisible={servicesPriceVisible}
                mobileTechnicianEnabled={mobileTechnicianEnabled}
                onSubmit={this.handleChangeConcern}
              />
            </div>
            {(!!maintenanceServices.length || !!concernServices.length) && (
              <Button
                className={styles.submit}
                caption="Confirm"
                onClick={this.handleSubmit}
                isWide
              />
            )}
          </div>
        </Animation>
      </AnimationGroup>
    );
  }
}

ServiceSelectionStepInput.propTypes = {
  onSubmit: func.isRequired,
  services: shape({
    maintenance: arrayOf(shape({ id: number, name: string })),
    concern: arrayOf(shape({ id: number, name: string })),
  }).isRequired,
  isAnimationFinished: bool,
  servicesPriceVisible: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  updateStatistics: func.isRequired,
};

ServiceSelectionStepInput.defaultProps = {
  isAnimationFinished: false,
};

export default ServiceSelectionStepInput;
