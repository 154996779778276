import React, { Fragment } from 'react';
import { bool, number, string, arrayOf, shape, func } from 'prop-types';
import { connect } from 'react-redux';
import { appDealershipIdSelector } from 'selectors/visit/app-selectors';
import { appServicesPriceVisibleSelector } from 'selectors/booking/app-selectors';
import { chatIsPickUp, chatIsMobileTechnician, chatEmployeeSelector } from 'selectors/booking/chat-selectors';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import BookingSummary from 'components/common/BookingSummary';

const BookingSummaryStep = ({
  isComplete,
  booking,
  isAnimationFinished,
  showSummaryDelayFinished,
  dealershipId,
  isServicesPriceVisible,
  isPickup,
  isMobileTechnician,
  employee,
  updateStatistics,
}) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message="Here's a summary of your booking"
        source={SOURCE_CONCIERGE}
        isClosing
        timecodes={{ from: 150000, to: 154000 }}
      />
    </AnimationGroup>
    {showSummaryDelayFinished && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <Animation>
          <BookingSummary
            booking={booking}
            dealershipId={dealershipId}
            isServicesPriceVisible={isServicesPriceVisible}
            isPickup={isPickup}
            isMobileTechnician={isMobileTechnician}
            employee={employee}
            updateStatistics={updateStatistics}
          />
        </Animation>
      </AnimationGroup>
    )}
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message="Confirm my booking"
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

BookingSummaryStep.propTypes = {
  dealershipId: number.isRequired,
  isComplete: bool,
  showSummaryDelayFinished: bool,
  booking: shape({
    customer: string.isRequired,
    vehicle: string.isRequired,
    services: arrayOf(shape({
      id: number.isRequired,
      name: string.isRequired,
      price: number.isRequired,
      package_items: arrayOf(shape({ id: number, name: string, fee: number })),
    })),
    advisor: shape({
      id: number.isRequired,
      name: string.isRequired,
      photo: shape({
        url: string.isRequired,
      }),
    }).isRequired,
    totalPrice: string.isRequired,
    totalPriceBeforeDiscount: string.isRequired,
    discount: string.isRequired,
    totalTime: string.isRequired,
  }).isRequired,
  isAnimationFinished: bool,
  isServicesPriceVisible: bool.isRequired,
  isPickup: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  employee: shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: shape({
      url: string.isRequired,
    }),
  }),
  updateStatistics: func.isRequired,
};

BookingSummaryStep.defaultProps = {
  isComplete: false,
  showSummaryDelayFinished: false,
  isAnimationFinished: false,
  employee: null,
};

const mapStateToProps = state => ({
  dealershipId: appDealershipIdSelector(state),
  isServicesPriceVisible: appServicesPriceVisibleSelector(state),
  isPickup: chatIsPickUp(state),
  isMobileTechnician: chatIsMobileTechnician(state),
  employee: chatEmployeeSelector(state),
});

const BookingSummaryStepContainer = connect(mapStateToProps)(BookingSummaryStep);

export default BookingSummaryStepContainer;

