import React from 'react';
import { number } from 'prop-types';
import { Lottie } from '@crello/react-lottie';

import { DELAY_500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation, { UNFOLD_RIGHT } from 'components/common/Animation';
import typingAnimation from 'assets/animations/typing.json';

import styles from './styles.scss';

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: typingAnimation,
};

const LoadingState = ({ delay }) => (
  <AnimationGroup>
    <Animation type={UNFOLD_RIGHT} delay={delay}>
      <div className={styles.container}>
        <Lottie config={lottieOptions} />
      </div>
    </Animation>
  </AnimationGroup>
);

LoadingState.propTypes = {
  delay: number,
};

LoadingState.defaultProps = {
  delay: DELAY_500,
};

export default LoadingState;
