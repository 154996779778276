import React, { Fragment } from 'react';
import { func, bool, arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { setNeedsTransport, setTransport } from 'actions/booking/chat-actions';
import { chatAvailableTransportsSelector, chatIsPickUp } from 'selectors/booking/chat-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.scss';

const TransportSelectionStepInput = ({
  isAnimationFinished,
  setTransportNeeded,
  setSelectedTransport,
  availableTransports,
  question,
  isPickUp,
}) => {
  const transportList = () => {
    const transports = availableTransports.map(transport => transport.kind);
    const isLoanerAvailable = transports.includes('loaner');

    if (isPickUp && isLoanerAvailable) {
      return availableTransports.filter(transport => transport.kind === 'loaner');
    }

    return availableTransports;
  };

  return (
    <Fragment>
      {question !== 'whichTransport?' && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <Animation delay={DELAY_1500}>
            <div className={styles.container}>
              <Button
                isSecondary
                caption="No"
                className={styles.no}
                onClick={() => setTransportNeeded(false)}
              />
              <Button
                caption="Yes"
                className={styles.yes}
                onClick={() => setTransportNeeded(true)}
              />
            </div>
          </Animation>
        </AnimationGroup>
      )}
      {question === 'whichTransport?' && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <Animation delay={DELAY_1000}>
            <div className={styles.containerTransports}>
              {transportList().map(transport => (
                <Button
                  isSecondary
                  key={transport.kind}
                  caption={transport.kind}
                  onClick={() => setSelectedTransport(transport.kind)}
                />
              ))}
            </div>
          </Animation>
        </AnimationGroup>
      )}
    </Fragment>
  );
};

TransportSelectionStepInput.propTypes = {
  setTransportNeeded: func.isRequired,
  setSelectedTransport: func.isRequired,
  availableTransports: arrayOf(string).isRequired,
  isAnimationFinished: bool,
  question: string,
  isPickUp: bool.isRequired,
};

TransportSelectionStepInput.defaultProps = {
  isAnimationFinished: false,
  question: 'isWaiting?',
};

const mapStateToProps = state => ({
  availableTransports: chatAvailableTransportsSelector(state),
  isPickUp: chatIsPickUp(state),
});

const actions = {
  setTransportNeeded: setNeedsTransport,
  setSelectedTransport: setTransport,
};

const TransportSelectionStepInputContainer =
  connect(mapStateToProps, actions)(TransportSelectionStepInput);

export default TransportSelectionStepInputContainer;
