import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_2000 } from 'shared/constants/delays';
import { servicesPropType } from 'shared/constants/prop-types';
import { selectRecalls, toggleRecall } from 'actions/booking/chat-actions';
import {
  chatSelectedRecallsSelector,
  chatLoadingSelector,
  chatAvailableRecallsSelector,
} from 'selectors/booking/chat-selectors';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

const AvailableRecallsStepInput = ({
  selectedServices,
  onAccept,
  onDecline,
  isComplete,
}) => (
  !isComplete && (
    <div className={styles.container}>
      <Button
        isSecondary
        className={styles.firstButton}
        caption="No, thanks"
        onClick={onDecline}
      />
      <Button
        className={selectedServices.length ? '' : styles.disabled}
        disabled={!selectedServices.length}
        caption="Confirm"
        onClick={() => onAccept(selectedServices)}
      />
    </div>
  )
);

AvailableRecallsStepInput.propTypes = {
  selectedServices: servicesPropType,
  onAccept: func.isRequired,
  onDecline: func.isRequired,
  isComplete: bool,
  onToggle: func.isRequired,
  availableRecalls: servicesPropType.isRequired,
};

AvailableRecallsStepInput.defaultProps = {
  selectedServices: [],
  isComplete: false,
};

const mapStateToProps = state => ({
  selectedServices: chatSelectedRecallsSelector(state),
  isComplete: chatLoadingSelector(state),
  availableRecalls: chatAvailableRecallsSelector(state),
});

const actions = {
  onAccept: selectRecalls,
  onDecline: () => selectRecalls(null),
  onToggle: toggleRecall,
};

const AvailableRecallsStepInputContainer =
  connect(mapStateToProps, actions)(AvailableRecallsStepInput);

export default animationWrapper(AvailableRecallsStepInputContainer, DELAY_2000);
