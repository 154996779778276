import { connect } from 'react-redux';

import {
  submitCustomerIdentification,
  initializeCustomerCreationStep,
} from 'actions/booking/chat-actions';
import { appDealershipDmsTypeSelector } from 'selectors/booking/app-selectors';

import CustomerIdentificationStepInput from './CustomerIdentificationStepInput';

const mapStateToProps = state => ({
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onSubmit: submitCustomerIdentification,
  onCreateAccount: initializeCustomerCreationStep,
};

const CustomerIdentificationStepInputContainer =
  connect(mapStateToProps, actions)(CustomerIdentificationStepInput);

export default CustomerIdentificationStepInputContainer;
