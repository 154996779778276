import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CustomerCreationStep = ({
  name,
  isComplete,
  isAnimationFinished,
  companyName,
}) => (
  <Fragment>
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message="Please fill in the form with a few details."
        source={SOURCE_CONCIERGE}
        isOpening
        timecodes={{ from: 38000, to: 43000 }}
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={`Nice to meet you, ${name || companyName}!`}
          source={SOURCE_CONCIERGE}
          isClosing
          timecodes={{ from: 43000, to: 46000 }}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CustomerCreationStep.propTypes = {
  name: string,
  companyName: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

CustomerCreationStep.defaultProps = {
  name: '',
  companyName: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default CustomerCreationStep;
