export const appCableChannelKeySelector = state => state.app.actionCableChannelKey;
export const appVisitIdSelector = state => state.app.visitId;
export const appDealershipIdSelector = state => state.app.dealership.id;
export const appDealershipAddressSelector = state => state.app.dealership.address;
export const appDealershipNameSelector = state => state.app.dealership.name;
export const appDealershipLocationSelector = state => ({
  lat: state.app.dealership.latitude,
  lng: state.app.dealership.longitude,
});
export const appDealershipTaxRateSelector = state => state.app.dealership.taxRate;
export const appDealershipWelcomeScreenSelector = state => state.app.dealership.welcomeScreen;
export const appDealershipDmsTypeSelector = state => state.app.dealership.dmsType;
export const appDealershipTimeZoneSelector = state => state.app.dealership.timeZone;
export const appPlateDetailsSelector = state => state.app.plateDetails;
export const appPlateNumberSelector = state => state.app.plateDetails.plateNumber;
export const appValidPlateSelector = state => state.app.plateDetails.valid;
export const appAppointmentListSelector = state => state.app.appointmentList || [];
export const appAppointmentListFetchedSelector = state => state.app.isAppointmentListFetched;
export const appBookingFetchedByQrCodeSelector = state => state.app.isBookingFetchedByQrCode;
export const appServicesPriceVisibleSelector = state => state.app.dealership.servicesPriceVisible;

