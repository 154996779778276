import React, { useEffect, useRef, useState } from 'react';
import { func, string, bool, arrayOf, number, shape, date, oneOfType } from 'prop-types';
import Calendar from 'react-calendar';
import InlineSVG from 'react-inlinesvg';

import { DEFAULT_TIME_SLOTS } from 'shared/timeSlotUtils';
import { appointmentSlotPropType } from 'shared/constants/prop-types';
import * as moment from 'moment';
import 'moment-timezone';

import scrollIntoViewSmoothly from 'utils/smoothScrollIntoView';
import leftIcon from 'assets/images/left.svg';
import rightIcon from 'assets/images/right.svg';
import Button from 'components/common/Button';
import TimePicker from './TimePicker';

import styles from './styles.module.scss';
import './customCalendar.scss';

const DateSelection = ({
  advisors,
  advisorIds,
  availableDates,
  selectedDateTime,
  drivers,
  isPickup,
  isMobileTechnician,
  loadingState,
  onSubmit,
  phantomAdvisorAvailable,
  phantomAdvisorIds,
  technicians,
  timeZone,
  tryAgain,
  updateCalendarView,
  isProactive,
  isWaiting,
  setWaiting,
}) => {
  const calendarRef = useRef();
  const [containerEnd, setContainerEnd] = useState(null);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHoursSlot, setSelectedHoursSlot] = useState(null);
  const [selectedQuarterSlot, setSelectedQuarterSlot] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [firstInit, setFirstInit] = useState(true);
  const [timePickerHeight, setTimePickerHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerWaiting, setIsCustomerWaiting] = useState(isWaiting);

  useEffect(() => {
    let chosenDate = null;
    let chosenQuarterSlot = null;
    if (selectedDateTime) {
      const dateItems = selectedDateTime.match(/\d+/g);
      chosenDate = new Date(`${dateItems[0]}-${dateItems[1]}-${dateItems[2]}T00:00`);
      chosenQuarterSlot = `${dateItems[3]}:${dateItems[4]}`;
    } else {
      const slotsByDates = {};

      // eslint-disable-next-line array-callback-return
      Object.values(availableDates).map((item) => {
        const entries = Object.entries(item.appointmentSlots);
        // eslint-disable-next-line no-unused-vars
        slotsByDates[item.fullDate] = entries.filter(([key, value]) => (
          value.length > 0
        ));

        const minDate = new Date();

        if (isPickup || isMobileTechnician) {
          minDate.setDate(minDate.getDate() + 1);
        }

        if (isProactive) {
          minDate.setFullYear(minDate.getFullYear() + 1);
        }

        const momentDate = moment(minDate);
        const fullDateFormat = momentDate.format('YYYY-MM-DD');

        if (
          slotsByDates[item.fullDate].length > 0
            && chosenDate === null
            && (
              (!isPickup && !isMobileTechnician && !isProactive) ||
              new Date(item.fullDate) >= new Date(fullDateFormat)
            )
        ) {
          chosenDate = new Date(`${item.fullDate}T00:00`);
          // eslint-disable-next-line prefer-destructuring
          chosenQuarterSlot = slotsByDates[item.fullDate][0][0];
        }
      });
    }

    if (chosenDate && chosenQuarterSlot) {
      setSelectedDate(chosenDate);
      setSelectedQuarterSlot(chosenQuarterSlot);
      setSelectedMonth(new Date(chosenDate).getMonth());
    } else if (firstInit) {
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();

      if (month === 13) {
        month = 1;
        year += 1;
      }

      if (!isProactive) {
        updateCalendarView(
          advisorIds,
          false,
          false,
          year,
          month + 1,
        );
      }

      setFirstInit(false);
    } else {
      setSelectedDate(null);
    }
  }, [availableDates]);

  useEffect(() => {
    if (!loadingState && isLoading) {
      setIsLoading(false);
    }
    if (loadingState && !isLoading) {
      setIsLoading(true);
    }
  }, [loadingState]);

  useEffect(() => {
    if (timePickerHeight === 0) {
      setTimePickerHeight(calendarRef.current.clientHeight);
    }
  }, [timePickerHeight]);

  useEffect(() => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
  }, [scrollTimeout]);

  const scrollToBottom = () => {
    if (containerEnd) {
      setScrollTimeout(setTimeout(() => {
        if (containerEnd) {
          scrollIntoViewSmoothly(containerEnd, {
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 50));
    }
  };

  useEffect(() => {
    if (selectedDate || selectedQuarterSlot) {
      scrollToBottom();
      setTimePickerHeight(calendarRef.current.clientHeight);
    }
  }, [selectedDate, selectedQuarterSlot]);

  const onActiveStartDateChange = ({ activeStartDate }) => {
    const month = new Date(activeStartDate).getMonth();
    const year = new Date(activeStartDate).getFullYear();

    if (isProactive) {
      updateCalendarView(
        advisorIds,
        year,
        month + 1,
      );
    } else {
      updateCalendarView(
        advisorIds,
        false,
        false,
        year,
        month + 1,
      );
    }

    setSelectedQuarterSlot(null);
    setSelectedMonth(month);
    setSelectedYear(year);
    setTimePickerHeight(0);
    setIsLoading(true);
  };

  const getDayByDate = (activeStartDate, value, view) => {
    if (view === 'month') {
      const momentDate = moment(value);
      const dayOfMonth = momentDate.date();
      const fullDateFormat = momentDate.format('YYYY-MM-DD');

      const currentItem = availableDates[dayOfMonth - 1];

      if (fullDateFormat === currentItem?.fullDate) {
        const availableTimeSlots = Object.entries(currentItem.appointmentSlots).filter(entry => (
          entry[1].length > 0
        ));

        if (availableTimeSlots.length > 0) {
          return {
            id: +momentDate,
            shortDayOfTheWeek: momentDate.format('ddd').toLocaleLowerCase(),
            dayOfTheWeek: momentDate.format('dddd'),
            dayOfTheMonth: momentDate.format('MMM, D'),
            fullDate: fullDateFormat,
            appointmentSlots: DEFAULT_TIME_SLOTS,
          };
        }
      }

      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const selectedDay = getDayByDate(null, selectedDate, 'month');

    const [hours, minutes] = selectedQuarterSlot.split(':');
    const chosenDateTime =
      moment.default(selectedDate.setHours(hours, minutes, 0));
    const timeZoneDateTime = chosenDateTime.tz(timeZone, true).format();
    const selectedJobDateTime =
      moment.default(selectedDate.setHours(hours, 0, 0));
    const timeZoneJobDateTime = selectedJobDateTime.tz(timeZone, true).format();

    const momentDate = moment(selectedDate);
    const dayOfMonth = momentDate.date();
    const currentItem = availableDates[dayOfMonth - 1];
    const slots = Object.entries(currentItem.appointmentSlots);
    const slotsForEmployees = Object.entries(currentItem?.employeeJobSlots || {});

    const slotAdvisors = slots.reduce((result, entry) => {
      if (entry[0] === selectedQuarterSlot) {
        return entry[1];
      }

      return result;
    }, null);

    const employees = slotsForEmployees.reduce((result, entry) => {
      if (entry[0] === selectedQuarterSlot) {
        return entry[1];
      }

      return result;
    }, null);


    let validAdvisors = [];

    if (phantomAdvisorAvailable) {
      validAdvisors = slotAdvisors.filter(id => phantomAdvisorIds.indexOf(id) !== -1);
    }

    if (validAdvisors.length === 0) {
      validAdvisors = slotAdvisors;
    }

    const index = Math.floor(Math.random() * validAdvisors.length);

    const advisor = advisors.reduce((result, item) => {
      if (item.id === validAdvisors[index]) {
        return item;
      }

      return result;
    }, null);

    let employee = null;

    if (isPickup) {
      const driverIndex = Math.floor(Math.random() * employees.length);

      employee = drivers.reduce((result, item) => {
        if (item.id === employees[driverIndex]) {
          return item;
        }

        return result;
      }, null);
    }

    if (isMobileTechnician) {
      const technicianIndex = Math.floor(Math.random() * employees.length);

      employee = technicians.reduce((result, item) => {
        if (item.id === employees[technicianIndex]) {
          return item;
        }

        return result;
      }, null);
    }

    if (isProactive) {
      onSubmit(timeZoneDateTime, advisor);
    } else {
      onSubmit(
        selectedDay,
        selectedQuarterSlot,
        timeZoneDateTime,
        timeZoneJobDateTime,
        tryAgain,
        advisor,
        employee,
      );
    }
  };

  const availableTimeSlots = (chosenDate) => {
    const momentDate = moment(chosenDate);
    const dayOfMonth = momentDate.date();
    const currentItem = availableDates[dayOfMonth - 1];
    const slots = Object.entries(currentItem?.appointmentSlots || {});
    const availableTimeOfDaySlots = slots.reduce((result, entry) => {
      if (entry[1].length > 0) {
        return result.concat(entry[0]);
      }

      return result;
    }, []);

    return availableTimeOfDaySlots;
  };

  const timeSlots = (chosenDate) => {
    const momentDate = moment(chosenDate);
    const fullDateFormat = momentDate.format('YYYY-MM-DD');
    let result = [];

    if (chosenDate) {
      const day = availableDates.find(el => el.fullDate === fullDateFormat);
      result = day ? Object.keys(day.appointmentSlots) : [];
    }

    if (isPickup) {
      result = result.filter((slot) => {
        const [hours, minutes] = slot.split(':');
        const timeInMinutes = (Number(hours) * 60) + Number(minutes);
        return timeInMinutes <= 780;
      });
    }

    return result;
  };

  const selectDate = (chosenDate) => {
    const availableTimeOfDaySlots = availableTimeSlots(chosenDate);
    const availableHourSlots = availableTimeOfDaySlots.reduce((hourSlots, timeOfDay) =>
      hourSlots.concat(timeOfDay.hourSlots), []);
    const matchingHoursSlot = selectedHoursSlot && availableHourSlots.find(hourSlot =>
      hourSlot.value === selectedHoursSlot.value);
    const matchingQuarterSlot = matchingHoursSlot && selectedQuarterSlot &&
        matchingHoursSlot.quarterSlots.find(quarterSlot =>
          quarterSlot.value === selectedQuarterSlot.value);
    setSelectedDate(chosenDate);
    setSelectedHoursSlot(matchingHoursSlot || null);
    setSelectedQuarterSlot(matchingQuarterSlot || null);
  };

  const minDate = new Date();
  const maxDate = new Date(minDate.getFullYear() + 3, minDate.getMonth(), minDate.getDate());
  if (isProactive) {
    minDate.setFullYear(minDate.getFullYear() + 1);
  }

  if (isPickup || isMobileTechnician) {
    minDate.setDate(minDate.getDate() + 1);
  }

  const handleWaiting = () => {
    setWaiting(!isCustomerWaiting);
    updateCalendarView(
      advisorIds,
      false,
      false,
      selectedYear,
      selectedMonth + 1,
    );

    setIsCustomerWaiting(!isCustomerWaiting);
  };


  return (
    <div className={styles.container} ref={el => setContainerEnd(el)}>
      <div className={styles.calendar}>
        <div className={styles.wrapper}>
          {!isPickup && !isMobileTechnician && !isProactive && (
          <div className={styles.checkboxContainer}>
            <div
              className={styles.checkbox}
              onClick={handleWaiting}
              role="button"
              tabIndex={0}
            >
              <div className={styles.circle}>
                {!isCustomerWaiting && (
                  <div className={styles.dot} />
                )}
              </div>
              <span>Drop-off my vehicle</span>
            </div>
            <div
              className={styles.checkbox}
              onClick={handleWaiting}
              role="button"
              tabIndex={0}
            >
              <div className={styles.circle}>
                {isCustomerWaiting && (
                  <div className={styles.dot} />
                )}
              </div>
              <span>Wait for my vehicle</span>
            </div>
          </div>
        )}
          <div className={styles.pickerWrapper}>
            <Calendar
              inputRef={calendarRef}
              onChange={selectDate}
              onActiveStartDateChange={onActiveStartDateChange}
              value={selectedDate}
              minDate={minDate}
              maxDate={maxDate}
              locale="en-US"
              calendarType="US"
              className="customCalendar"
              tileClassName="notranslate"
              defaultValue={selectedDate}
              minDetail="year"
              prevLabel={<InlineSVG src={leftIcon} />}
              nextLabel={<InlineSVG src={rightIcon} />}
              formatShortWeekday={(locale, d) => moment(d).format('dd')}
              formatMonthYear={(locale, d) => moment(d).format('MMMM, YYYY')}
              // eslint-disable-next-line no-shadow
              tileDisabled={({ activeStartDate, date, view }) => (
                !getDayByDate(activeStartDate, date, view)
              )}
            />
            {selectedDate &&
            <TimePicker
              slots={timeSlots(selectedDate)}
              time={selectedQuarterSlot}
              timeArr={availableTimeSlots(selectedDate)}
              setTime={slot => setSelectedQuarterSlot(slot)}
              height={timePickerHeight}
            />
          }
            {isLoading && (
            <div className={styles.loadingContainer}>
              <span>
                Loading...
              </span>
            </div>
          )}
          </div>
          <div className={styles.button}>
            <Button
              className={styles.submit}
              caption="Confirm"
              onClick={(handleSubmit)}
              disabled={!selectedDate || !selectedQuarterSlot}
              isWide
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DateSelection.propTypes = {
  onSubmit: func.isRequired,
  setWaiting: func.isRequired,
  updateCalendarView: func.isRequired,
  advisorIds: arrayOf[number],
  advisors: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  })).isRequired,
  drivers: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  })),
  technicians: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    photo: {
      url: string,
    },
  })),
  timeZone: string.isRequired,
  tryAgain: bool,
  availableDates: arrayOf(appointmentSlotPropType).isRequired,
  isPickup: bool,
  isMobileTechnician: bool,
  isProactive: bool,
  selectedDateTime: date,
  phantomAdvisorAvailable: bool.isRequired,
  phantomAdvisorIds: arrayOf(oneOfType([number, string])),
  loadingState: bool.isRequired,
  isWaiting: bool.isRequired,
};

DateSelection.defaultProps = {
  advisorIds: [],
  tryAgain: false,
  selectedDateTime: null,
  phantomAdvisorIds: [],
  drivers: [],
  technicians: [],
  isPickup: false,
  isMobileTechnician: false,
  isProactive: false,
};

export default DateSelection;
