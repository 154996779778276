import React, { Fragment } from 'react';
import { shape, string, func, arrayOf, any, bool } from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';

import styles from './styles.module.scss';

const StyledSelect = ({
  value,
  className,
  onChange,
  options,
  error,
  menuPlacement,
  disabled,
  isOptionDisabled,
  hideError,
  isSearchable,
}) => (
  <Fragment>
    <Select
      isSearchable={isSearchable}
      className={cx(styles.styledSelect, className)}
      classNamePrefix="styledSelect"
      value={value}
      menuPlacement={menuPlacement || 'auto'}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
    />
    {!hideError &&
      <p className={styles.error}>{error}</p>
    }
  </Fragment>
);

StyledSelect.propTypes = {
  value: shape({ label: string, value: any }),
  className: string,
  onChange: func,
  options: arrayOf(shape({ label: string, value: any })),
  error: string,
  menuPlacement: string,
  disabled: bool,
  isOptionDisabled: func,
  hideError: bool,
  isSearchable: bool,
};

StyledSelect.defaultProps = {
  value: null,
  className: '',
  onChange: null,
  options: [],
  error: null,
  menuPlacement: '',
  disabled: false,
  isOptionDisabled: () => {},
  hideError: false,
  isSearchable: false,
};

export default StyledSelect;
