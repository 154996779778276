import React from 'react';
import { func, element } from 'prop-types';
import { stopSpeaking } from 'actions/visit/voice-actions';

const withStopSpeaking = (Component) => {
  const WrappedComponent = (props) => {
    const { onClick, onKeyDown } = props;

    const stopSpeakingAndCall = (handler, event) => {
      stopSpeaking();
      handler(event);
    };

    const onClickHandler = event => stopSpeakingAndCall(onClick, event);
    const onKeyDownHandler = event => stopSpeakingAndCall(onKeyDown, event);

    return (
      <Component
        {...props}
        onClick={onClickHandler}
        onKeyDown={onKeyDownHandler}
      />
    );
  };

  WrappedComponent.propTypes = {
    onClick: func,
    onKeyDown: func,
  };
  WrappedComponent.defaultProps = {
    onClick: () => {},
    onKeyDown: () => {},
  };

  return WrappedComponent;
};

withStopSpeaking.propTypes = {
  Component: element.isRequired,
};

export default withStopSpeaking;
